import { Dialog, Transition } from '@headlessui/react';
import {
  CashIcon,
  ChartSquareBarIcon,
  CheckCircleIcon,
  CollectionIcon,
  DocumentTextIcon,
  GlobeAltIcon,
  LockOpenIcon,
  PlusIcon,
  XIcon,
} from '@heroicons/react/solid';
import { ChartBarIcon, LightningBoltIcon } from '@heroicons/react/solid';
import { Fragment, useEffect, useState } from 'react';
import Button from '../../Button';

import 'firebase/auth';
import firebase from 'firebase/app';
import 'firebase/database';

import { useRouter } from 'next/router';
import LogInModal from '../log-in';
import {
  PremierFeatures,
  PricingPlans,
  ProFeatures,
} from '../../../pages/plan';
import TSSimple from '../../Icons/ts-simple';
import Checkout from '../../Checkout';
import TemplatePacks from '../../TemplatesPage/TemplatePacks';
import UpgradePlanModal from '../../Plans/UpgradePlanModal';
import TemplatePackSelector from '../../TemplatesSelector';

export default function UpgradeSuccessPremier({
  isOpen,
  setIsOpen,
  STRIPE_PK,
}) {
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <Transition show={isOpen} as={Fragment}>
        <Dialog
          isOpen={isOpen}
          className="fixed inset-0 z-30 overflow-y-auto"
          onClose={closeModal}
        >
          <UpgradePlanModal
            isOpen={upgradeModalOpen}
            setIsOpen={setUpgradeModalOpen}
            STRIPE_PK={STRIPE_PK}
          />

          <div className="min-h-screen text-center sm:px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300 transform"
              enterFrom="opacity-0 scale-90 translate-y-96"
              enterTo="opacity-100 scale-100 translate-y-0"
              leave="ease-in duration-300 transform"
              leaveFrom="opacity-100 scale-100 translate-y-0"
              leaveTo="opacity-0 translate-y-96 scale-90"
            >
              <div
                className={` ${
                  upgradeModalOpen && '-translate-y-24 scale-95'
                } relative inline-block w-full max-w-md transform rounded-2xl bg-black text-left align-middle text-white antialiased shadow-xl transition-all`}
              >
                <div className="sticky top-1 z-10 w-full">
                  <button
                    onClick={() => closeModal()}
                    className="absolute top-2 right-2 rounded-full bg-gray-800 p-2 text-gray-500 duration-200 hover:bg-gray-700 focus:outline-none"
                  >
                    <XIcon className="h-5 w-5" />
                  </button>
                </div>

                <div className="flex flex-col items-start justify-start space-y-8 p-8">
                  <div className="flex flex-col items-start space-y-4">
                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gradient-to-br from-blue-500 to-indigo-500 text-white">
                      <LightningBoltIcon className="h-5 w-5" />
                    </div>
                    <div className="flex flex-col items-start space-y-2">
                      <h1 className="text-3xl font-semibold leading-7 tracking-tight">
                        You're now a Premier member!
                      </h1>
                    </div>
                  </div>

                  <PremierFeatures />

                  <div className="h-0.5 w-full rounded-full bg-gradient-to-l from-blue-500" />

                  <div className="flex w-full justify-end">
                    <Button
                      click={() => closeModal()}
                      size={'small'}
                      variant={'primary'}
                      width={'full'}
                    >
                      Continue to Typeshare
                    </Button>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
