import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import Button from '../Button';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { useRouter } from 'next/router';
import { cio } from '../../utils/customer-io';

export default function DeleteAccountModal({ isOpen, setIsOpen, userID }) {
  const [deleteText, setDeleteText] = useState('');
  const [errorMSG, setErrorMSG] = useState('');
  const [currentUserID, setCurrentUserID] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const router = useRouter();

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    if (userID) {
      setCurrentUserID(userID);
    }
  }, [userID]);

  const deleteConfirmed = () => {
    setIsLoading(true);

    if (firebase.auth().currentUser.uid === currentUserID) {
      const user = firebase.auth().currentUser;
      const provider = user.providerData[0].providerId;

      console.log(provider, 'provider');

      firebase
        .auth()
        .currentUser.reauthenticateWithPopup(
          provider === 'google.com'
            ? new firebase.auth.GoogleAuthProvider()
            : new firebase.auth.TwitterAuthProvider(),
        )
        .then(function (userCredential) {
          // You can now delete the user:

          try {
            const userRef = firebase.database().ref(`users/${currentUserID}`);
            userRef
              .child('gallery')
              .once('value', (snapshot) => {
                const galleryName = snapshot.val().username;
                const galleryRef = firebase
                  .database()
                  .ref(`gallery/${galleryName}`)
                  .child(currentUserID);
                galleryRef.remove();
              })
              .then(() => {
                //remove from groups
                userRef
                  .child('meta/connectedGroups')
                  .once('value', (snapshot) => {
                    if (snapshot.exists()) {
                      //for each group
                      snapshot.forEach((childSnapshot) => {
                        const groupName = childSnapshot.key;
                        const groupRef = firebase
                          .database()
                          .ref(`groups/${groupName}`);
                        groupRef.child(currentUserID).remove();
                      });
                    }
                  })
                  .then(() => {
                    userRef
                      .child('affiliate')
                      .once('value', (snapshot) => {
                        if (snapshot.exists()) {
                          var offerID = snapshot.val().offerID;
                          firebase
                            .database()
                            .ref(`affiliateOffers/${offerID}`)
                            .child(currentUserID)
                            .remove();
                        }
                      })
                      .then(() => {
                        //remove from /paths/blogs /paths/posts /paths/collections
                        const pathsRef = firebase.database().ref('paths');

                        pathsRef
                          .child('blogs')
                          .child(currentUserID)
                          .remove()
                          .then(() => {
                            pathsRef
                              .child('posts')
                              .child(currentUserID)
                              .remove()
                              .then(() => {
                                pathsRef
                                  .child('collections')
                                  .child(currentUserID)
                                  .remove()
                                  .then(async () => {
                                    try {
                                      await userRef.remove();
                                      await cio.deleteCustomer();
                                      await firebase
                                        .auth()
                                        .currentUser.delete();
                                      alert('Account deleted successfully');
                                      router.push('/');
                                    } catch (err) {
                                      alert(
                                        'Something went wrong (ERR-3). Please contact support (@typeshare_co  on Twitter).',
                                      );
                                      setIsLoading(false);
                                    }
                                  });
                              });
                          });
                      });
                  });
              });
            //remove from /users /gallery /groups /paths/blogs /paths/posts /paths/collections
          } catch (error) {
            alert(
              'Something went wrong (ERR-2). Please contact support (@typeshare_co  on Twitter).',
            );
            setIsLoading(false);
          }
        })
        .catch(function (error) {
          // Credential mismatch or some other error.
          console.log(error);
          alert(
            'Wrong account. Please reauthenticate with the account you are trying to delete.',
          );
          setIsLoading(false);
        });
    } else {
      alert(
        'Something went wrong (ERR-1). Please contact support (@typeshare_co  on Twitter).',
      );
      setIsLoading(false);
    }
  };

  const deletePressed = () => {
    deleteText === 'DELETE'
      ? deleteConfirmed()
      : setErrorMSG('Text does not match DELETE');
  };

  useEffect(() => {
    setErrorMSG('');
  }, [deleteText]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-30 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-all" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-xl font-medium leading-6 text-gray-900"
                >
                  Delete Account Permenantly
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    This will permanently delete your account and all of your
                    data.{' '}
                    <span className="font-medium text-red-500">
                      This action cannot be undone.
                    </span>{' '}
                    Are you sure you want to delete your account and everything
                    you've created?
                  </p>
                </div>

                <div className={'mb-6 mt-4 flex w-full flex-col space-y-1'}>
                  <div className="mb-1 w-full">
                    <input
                      value={deleteText}
                      onChange={(event) => setDeleteText(event.target.value)}
                      type="text"
                      placeholder="Type DELETE to confirm"
                      className={
                        'w-full rounded-lg border-2 border-gray-200 bg-white p-3 font-medium transition duration-200 focus:border-gray-900 focus:outline-none focus:ring-0'
                      }
                    />
                  </div>
                  <p className="text-sm text-red-500">{errorMSG}</p>
                </div>

                <div className="mt-4 flex flex-row space-x-2">
                  <Button
                    click={() => setIsOpen(false)}
                    width={'full'}
                    variant={'primary'}
                  >
                    Keep Account
                  </Button>
                  <Button
                    loading={isLoading}
                    disabled={isLoading}
                    click={() => deletePressed()}
                    width={'full'}
                    variant={'error'}
                  >
                    Delete Forever
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
