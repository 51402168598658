export default function TSSimple({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1"
        y1="1"
        x2="0.999999"
        y2="21"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path d="M6 4V6.4H10.24V18H12.88V6.4H17.12V4H6Z" fill="currentColor" />
    </svg>
  );
}
