import { Dialog, Transition } from '@headlessui/react';
import { PlusIcon, XIcon } from '@heroicons/react/solid';
import { Fragment, useState } from 'react';
import Button from '../../Button';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

import TSSimple from '../../Icons/ts-simple';
import { cio } from '../../../utils/customer-io';
import AddIcon from '../../Icons/add';

export default function OnboardingModal({
  isOpen,
  setIsOpen,
  next,
  userID,
  type,
  onboardingModalType = null,
}) {
  const [closeAttempt, setCloseAttempt] = useState(false);

  function closeModal() {
    setCloseAttempt(true);
    setTimeout(() => {
      setCloseAttempt(false);
    }, 100);
  }

  function openModal() {
    setIsOpen(true);
  }

  const close = (type) => {
    setIsOpen(false);
    //update firebase
    if (userID) {
      firebase
        .database()
        .ref(`users/${userID}/modals`)
        .update({
          onboarding: true,
        })
        .then(() => {
          if (type !== 'mobile') {
            cio.trackEvent(userID, {
              name: 'ignore_tour',
            });
          }
        });
    }
  };

  return (
    <>
      <Transition show={isOpen} as={Fragment}>
        <Dialog
          isOpen={isOpen}
          className="fixed inset-0 z-30 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="min-h-screen text-center sm:px-4">
            <Transition.Child
              show={isOpen}
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-50" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              show={isOpen}
              as={Fragment}
              enter="ease-out duration-300 transform"
              enterFrom="opacity-0 scale-90 translate-y-96"
              enterTo="opacity-100 scale-100 translate-y-0"
              leave="ease-in duration-300 transform"
              leaveFrom="opacity-100 scale-100 translate-y-0"
              leaveTo="opacity-0 translate-y-96 scale-90"
            >
              <div
                className={` ${
                  closeAttempt && 'scale-95'
                } relative inline-block w-full max-w-sm transform overflow-hidden rounded-3xl bg-gray-900 text-left align-middle antialiased shadow-xl transition-all`}
              >
                <div className="absolute top-0 h-0.5 w-full rounded-full bg-gradient-to-r from-transparent via-blue-500 to-transparent" />

                <div className="sticky top-1 z-10 hidden w-full md:flex">
                  <button
                    onClick={() => close()}
                    className="absolute top-2 right-2 rounded-full bg-gray-800 p-2 text-gray-500 duration-200 hover:bg-gray-700 focus:outline-none"
                  >
                    <XIcon className="h-5 w-5" />
                  </button>
                </div>

                <div className="sticky top-1 z-10 flex w-full md:hidden">
                  <button
                    onClick={() => close('mobile')}
                    className="absolute top-2 right-2 rounded-full bg-gray-800 p-2 text-gray-500 duration-200 hover:bg-gray-700 focus:outline-none"
                  >
                    <XIcon className="h-5 w-5" />
                  </button>
                </div>

                {onboardingModalType === 'profile' ? (
                  <div className="flex  h-24 w-full flex-row items-center justify-center space-x-2">
                    <div className="flex flex-row items-center justify-center space-x-2">
                      <div className="mt-10 flex h-16 w-16 items-center justify-center rounded-2xl bg-gray-800/80 p-4 text-3xl">
                        🚀
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex  h-40 w-full flex-row items-center justify-center space-x-2">
                    <div className="mt-10 flex items-center justify-center rounded-2xl bg-gray-800/80 p-4">
                      <TSSimple className="h-8 w-8 fill-current text-blue-500" />
                    </div>

                    {type === 'ship30' && (
                      <div className="flex flex-row items-center justify-center space-x-2">
                        <AddIcon className="mt-10 h-8 w-8 fill-current text-gray-600" />

                        <div className="mt-10 flex h-16 w-16 items-center justify-center rounded-2xl bg-gray-800/80 p-4 text-3xl">
                          🚢
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div className="flex flex-col items-start justify-start p-8">
                  <div className="flex flex-col items-start space-y-4">
                    <div className="flex flex-col items-start space-y-1">
                      <h1 className="text-2xl font-bold leading-7 tracking-tightish text-white">
                        {onboardingModalType === 'profile'
                          ? "You've successfully launched your Social Blog!"
                          : 'Welcome to Typeshare'}
                      </h1>
                      {onboardingModalType === 'profile' ? null : (
                        <p className="text-base text-gray-400">
                          Typeshare has everything you need to start writing
                          online.
                        </p>
                      )}
                    </div>
                  </div>

                  {/* <div className='flex flex-col space-y-3  bg-gray-800 p-3 rounded-xl w-full relative mt-8'>


                    <div className='flex flex-row w-full items-center justify-start  space-x-2 relative'>

                      <div className='bg-gray-700/50 h-10 w-10 rounded-lg flex items-center justify-center'>
                        🎁
                      </div>

                      <div className='flex flex-col text-white -space-y-0.5'>
                        <p className='font-medium text-base'>Beginner Template Pack</p>
                        <p className='text-sm text-gray-500'>Reward for completing the tour</p>
                      </div>

                    </div>


                    <svg className={"-bottom-2 left-1/2 -translate-x-1/2 absolute stroke-current fill-current text-gray-800 w-6 rotate-180"} viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.78018 2.4418C9.97046 1.1325 12.0295 1.13249 13.2198 2.4418L21 11H1L8.78018 2.4418Z" fill="var(--bg0)"></path><path d="M21 11L13.2198 2.4418C12.0295 1.13249 9.97046 1.1325 8.78018 2.4418L1 11"></path></svg>

                  </div> */}

                  <div className="group relative mt-6 w-full">
                    <div className="relative z-20 hidden w-full md:flex">
                      <Button
                        click={() => next()}
                        size={'mini'}
                        variant={'primary'}
                        width={'full'}
                      >
                        {onboardingModalType === 'profile'
                          ? 'Continue the Tour'
                          : 'Take a quick tour'}
                      </Button>
                    </div>
                    <div className="relative z-20 flex w-full md:hidden">
                      <Button
                        click={() => close('mobile')}
                        size={'mini'}
                        variant={'primary'}
                        width={'full'}
                      >
                        Go to Typeshare
                      </Button>
                    </div>
                    <div className="absolute top-0 z-0 h-full w-full animate-pulse rounded-full ring-4 ring-blue-500/50 duration-200 group-hover:scale-x-[1.04] group-hover:scale-y-[1.10] group-active:scale-x-[1.02] group-active:scale-y-[1.05]" />
                    <div className="absolute top-0 z-0 h-full w-full animate-pulse rounded-full ring-8 ring-blue-500/20 duration-200 group-hover:scale-x-[1.04] group-hover:scale-y-[1.10] group-active:scale-x-[1.02] group-active:scale-y-[1.05]" />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
