import {
  DocumentTextIcon,
  GlobeAltIcon,
  LightBulbIcon,
} from '@heroicons/react/solid';

export const addons = [
  {
    //SAME ID AS USED IN STRIPE METADATA NAME FIELD (METADATA.NAME)
    id: 'templates-plus',
    type: 'feature',
    cols: 'col-span-2',
    meta: {
      name: 'Templates+',
      description:
        'Get new premier templates every month and immediate access to the bonus pack!',
      gradient: 'bg-gradient-to-br from-yellow-400 to-green-400',
      icon: DocumentTextIcon,
      cta: 'View Templates',
      /*  logo: "https://d47mokwr4vy2h.cloudfront.net/ew5vjqbxdz1qccewfsm9xxuguy85", */
      creator: {
        name: 'Typeshare',
        img: 'https://pbs.twimg.com/profile_images/1517549946106437633/HmxlCL2z_400x400.jpg',
      },
    },
    price: {
      type: 'recurring',
      initial: {
        amount: 0,
        id: null,
      },
      month: {
        amount: 2000,
        id: 'price_1KtyB5IzYbcXDXFICZNc95dX',
      },
      year: {
        amount: 20000,
        id: 'price_1KtyBOIzYbcXDXFIHHFQCrM8',
      },
    },
  },
  {
    id: 'test-template-pack',
    type: 'feature',
    cols: 'col-span-1',
    meta: {
      name: 'Test Template Pack',
      description:
        'Get new premier templates every month and immediate access to the bonus pack!',
      gradient: 'bg-gradient-to-br from-red-400 to-yellow-400',
      icon: LightBulbIcon,
      cta: 'View Templates',
      logo: null,
      creator: {
        name: 'Typeshare',
        img: 'https://pbs.twimg.com/profile_images/1517549946106437633/HmxlCL2z_400x400.jpg',
      },
    },
    price: {
      type: 'one-time',
      initial: {
        amount: 8500,
        id: 'price_1KtuzpIzYbcXDXFIP2JlzgMh',
      },
      month: {
        amount: 0,
        id: null,
      },
      year: {
        amount: 0,
        id: null,
      },
    },
  },
  {
    id: 'custom-domain',
    type: 'feature',
    cols: 'col-span-1',
    meta: {
      name: 'Custom Domain',
      description: 'Connect a custom domain to your Social Blog',
      gradient: 'bg-gradient-to-br from-blue-400 to-indigo-600',
      icon: GlobeAltIcon,
      cta: 'Connect your Domain',
      logo: null,
      creator: {
        name: 'Typeshare',
        img: 'https://pbs.twimg.com/profile_images/1517549946106437633/HmxlCL2z_400x400.jpg',
      },
    },
    price: {
      type: 'recurring',
      initial: {
        amount: 0,
        id: null,
      },
      month: {
        amount: 500,
        id: 'price_1KuFlPIzYbcXDXFIaBdkmHYK',
      },
      year: {
        amount: 5000,
        id: 'price_1KuFlPIzYbcXDXFIBXJi9EEG',
      },
    },
  },
];
