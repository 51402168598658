import { DocumentIcon, DocumentTextIcon } from '@heroicons/react/outline';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import {
  EssayIconColor,
  EssayIconSecondary,
  LinkedInIconColor,
  LinkedInIconSecondary,
  SubatomicIconColor,
  SubatomicIconSecondary,
  ThreadIconColor,
  ThreadIconSecondary,
  TweetIconColor,
  TweetIconSecondary,
} from './Icons';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

export default function CategoriesTabs({
  modal = false,
  activeTab = null,
  click = null,
}) {
  const router = useRouter();
  const { type } = router.query;

  const baseRef = firebase.database().ref('templates2');

  useEffect(() => {
    getCounts();
  }, []);

  const [types, setTypes] = useState([
    {
      name: 'Atomic Essay',
      slug: 'atomic-essay',
      color: 'bg-orange-500',
      count: 71,
    },
    {
      name: 'Standalone Tweet',
      slug: 'tweet',
      color: 'bg-fuchsia-500',
      count: 2,
    },
    {
      name: 'Twitter Threads',
      slug: 'thread',
      color: 'bg-blue-500',
      count: 59,
    },
    {
      name: 'LinkedIn Post',
      slug: 'linkedin-post',
      color: 'bg-indigo-500',
      count: 43,
    },
    {
      name: 'Subatomic Essay',
      slug: 'subatomic-essay',
      color: 'bg-yellow-500',
      count: 18,
    },
  ]);

  const getCounts = () => {
    //get the count for each type
    types.forEach(async (type, index) => {
      const ref = baseRef.child('types').child(type.slug);
      await ref.once('value', function (snapshot) {
        if (snapshot.hasChild('templates')) {
          var length = Object.keys(snapshot.val().templates).length;
          //update the types array with the count for this type
          setTypes((prevState) => {
            return prevState.map((item, index) => {
              if (item.slug === type.slug) {
                return {
                  ...item,
                  count: length,
                };
              }
              return item;
            });
          });
        }
      });
    });
  };
  const SingleTab = ({
    title,
    active,
    onClick,
    icon,
    href,
    count,
    color,
    slug,
  }) => {
    return !click ? (
      <Link href={href}>
        <a
          className={`group flex cursor-pointer flex-col items-center justify-start space-y-2 rounded-lg border py-4 px-1 duration-100  ${
            active
              ? `${color} bg-opacity-10`
              : ' border-gray-100 bg-white hover:border-gray-200 hover:bg-gray-50'
          } ${modal && 'w-full'} transform duration-200 active:scale-[.98]`}
        >
          {icon}
          <div className="flex flex-col items-center justify-center -space-y-0.5">
            <p className="whitespace-nowrap font-sans text-sm font-semibold tracking-tightish">
              {title}
            </p>
            <p style={{ fontWeight: 430 }} className="text-xs text-gray-600">
              {count} Templates
            </p>
          </div>
        </a>
      </Link>
    ) : (
      <button
        onClick={click ? () => click(slug) : null}
        className=" flex w-full items-center justify-start text-left"
      >
        <a
          className={`group flex cursor-pointer flex-row items-center justify-start space-x-2 rounded-xl p-2 pr-4 pl-2.5 duration-100 ${
            active
              ? 'border-gray-200 border-opacity-50 bg-white'
              : 'border-transparent text-gray-900 hover:bg-gray-200 hover:bg-opacity-50'
          } border ${
            modal && 'w-full'
          } transform duration-200 active:scale-[.98]`}
        >
          {icon}
          <div className="flex flex-col -space-y-0.5">
            <p className="whitespace-nowrap font-sans text-sm font-medium tracking-tightish">
              {title}
            </p>
            <p className="text-xs text-gray-500">{count} Templates</p>
          </div>
        </a>
      </button>
    );
  };

  return (
    <div
      className={` ${
        modal
          ? 'flex flex-row items-start space-x-1 overflow-y-auto px-4 pb-2 no-scrollbar sm:flex-col sm:space-y-0.5 sm:space-x-0 sm:px-0 sm:pb-0 sm:show-scrollbar'
          : 'grid grid-cols-2 gap-2 sm:grid-cols-3 lg:grid-cols-5'
      }  w-full`}
    >
      {types.map((category, index) => (
        <SingleTab
          key={index}
          slug={category.slug}
          title={category.name}
          active={
            activeTab ? activeTab === category.slug : type === category.slug
          }
          color={category.color}
          href={
            type === category.slug
              ? '/templates'
              : `/templates/type/${category.slug}`
          }
          click={click ? click : false}
          icon={
            category.slug === 'atomic-essay' ? (
              <EssayIconColor
                className={modal && 'h-7 w-7'}
                active={type === category.slug}
              />
            ) : category.slug === 'tweet' ? (
              <TweetIconColor
                className={modal && 'h-7 w-7'}
                active={type === category.slug}
              />
            ) : category.slug === 'thread' ? (
              <ThreadIconColor
                className={modal && 'h-7 w-7'}
                active={type === category.slug}
              />
            ) : category.slug === 'linkedin-post' ? (
              <LinkedInIconColor
                className={modal && 'h-7 w-7'}
                active={type === category.slug}
              />
            ) : category.slug === 'subatomic-essay' ? (
              <SubatomicIconColor
                className={modal && 'h-7 w-7'}
                active={type === category.slug}
              />
            ) : (
              <div />
            )
          }
          count={category.count}
        />
      ))}
    </div>
  );
}
