import { InformationCircleIcon } from '@heroicons/react/outline';
import { CheckCircleIcon, ExclamationIcon } from '@heroicons/react/solid';
import Button from '../Button';

export default function AlertBanner({
  type,
  title = null,
  msg1 = '',
  msg2 = '',
  layout,
  icon = null,
  cta = null,
  className = null,
}) {
  return (
    <div
      className={`rounded-lg ${
        type === 'warn'
          ? 'bg-yellow-100 text-yellow-700'
          : type === 'error'
          ? 'bg-red-50 text-red-600'
          : type === 'success'
          ? 'bg-green-50 text-green-600'
          : 'bg-blue-50 text-blue-600'
      } w-full p-3 font-sans ${className}`}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          {type === 'warn' ? (
            <ExclamationIcon
              className="h-5 w-5 text-yellow-500"
              aria-hidden="true"
            />
          ) : null}
          {type === 'error' ? (
            <ExclamationIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          ) : null}
          {type === 'note' ? (
            <InformationCircleIcon
              className="h-5 w-5 text-blue-500"
              aria-hidden="true"
            />
          ) : null}
          {type === 'success' ? (
            <CheckCircleIcon
              className="h-5 w-5 text-green-600"
              aria-hidden="true"
            />
          ) : null}
        </div>
        <div className="ml-3">
          <h3
            className={`text-sm font-medium  ${
              type === 'warn'
                ? 'text-yellow-800'
                : type === 'error'
                ? 'text-red-600'
                : type === 'success'
                ? 'text-green-600'
                : 'text-blue-600'
            }`}
          >
            {title}
          </h3>
          <div
            className={`text-sm ${
              type === 'warn'
                ? 'text-yellow-700'
                : type === 'error'
                ? 'text-red-500'
                : type === 'success'
                ? 'text-green-600'
                : 'text-blue-500'
            } ${msg1 ? 'mt-2' : null}`}
          >
            {layout === 'list' ? (
              <ul role="list" className="list-disc space-y-1 pl-5">
                <li>{msg1}</li>
                {msg2 && <li>{msg2}</li>}
              </ul>
            ) : (
              <p>{msg1}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
