import { Fragment, useEffect, useRef, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import axios from 'axios';
import Button from '../Button';
import toast, { Toaster } from 'react-hot-toast';
import { MenuIcon, PlusIcon } from '@heroicons/react/solid';
import { ArrowLeftIcon } from '@heroicons/react/outline';
import { cio } from '../../utils/customer-io';
import { client } from '../../utils/http';
import { useFlags } from '../../utils/feature-flag.client';
import { getBillingPeriod } from '../../utils/helpers';
import { GlobalHotKeys } from 'react-hotkeys';
import { Transition, Dialog } from '@headlessui/react';
import { useRouter } from 'next/router';
import CommandPalette from '../CommandPalette';
import ConfirmUnlinkTwitter from '../Modal/confirm-unlink-twitter';
import CreateNewModal from '../NewModals/create-new';
import EditProfile from '../NewModals/edit-profile';
import HelpModal from '../NewModals/HelpModal';
import IconButton from '../Button/IconButton';
import NavItem from './nav-item';
import OnboardingModal from '../NewModals/OnboardingModal';
import RefProgram from '../NewModals/RefferralProgram';
import Sidebar from './sidebar';
import TemplatePackSelectorModal from '../NewModals/TemplatePackSelector';
import TSSimple from '../Icons/ts-simple';
import UpgradePlanModal from '../Plans/UpgradePlanModal';
import UpgradeSuccessPremier from '../NewModals/UpgradeSuccessPremier';
import UpgradeSuccessPro from '../NewModals/UpgradeSuccessPro';

const totalNotifications = 5;

const paidPlans = ['Hobby', 'Expert', 'Pro', 'pro', 'Expert Lifetime'];

export default function NavContainerSidebar({
  children,
  ready = true,
  isShip30,
  STRIPE_PK,
}) {
  const router = useRouter();

  const { custom_domains } = useFlags();
  const [galleryName, setGalleryName] = useState('');
  const [isOnboarding, setOnboarding] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [photoUrl, setPhotoUrl] = useState('');
  const [customerID, setCustomerID] = useState('');
  const [productName, setProductName] = useState(null);
  const [uid, setUID] = useState('');
  const [user, setUser] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [helpModalOpen, setHelpModalOpen] = useState(false);

  const [tour, setTour] = useState(false);

  const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);

  const [templatePackSelectorOpen, setTemplatePackSelectorOpen] =
    useState(false);

  const [status, setStatus] = useState(null);

  const [pastCustomer, setPastCustomer] = useState(false);

  const [hasTwitter, setHasTwitter] = useState(false);
  const [hideTwitterDisconnect, setHideTwitterDisconnect] = useState(true);

  const [isUnlinkModalOpen, setUnlinkModalOpen] = useState(false);

  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const [isCommandPaletteOpen, setIsCommandPaletteOpen] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);

  const [affiliateModal, setAffiliateModal] = useState(false);

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  const [planModalOpen, setPlanModalOpen] = useState(false);

  const [proSuccess, setProSuccess] = useState(false);
  const [premierSuccess, setPremierSuccess] = useState(false);

  const [isPopoverOpenInside, setIsPopoverOpenInside] = useState(false);

  const [tourType, setTourType] = useState(null);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    setCreateModalOpen(false);
    setSidebarOpen(false);
  }, [router.asPath]);

  useEffect(() => {
    if (router.query.upgrade === 'pro') {
      setProSuccess(true);
    }

    if (router.query.upgrade === 'premier') {
      setPremierSuccess(true);
    }
  }, [router.query]);

  useEffect(() => {
    if (ready) {
      firebase.auth().onAuthStateChanged(function (userInfo) {
        if (userInfo) {
          getUserGallery(firebase.auth().currentUser.uid);
          getActiveProduct(firebase.auth().currentUser.uid);
          setUID(firebase.auth().currentUser.uid);
          setUser(firebase.auth().currentUser);
          getNotificationCount();
          //setPhotoUrl(firebase.auth().currentUser.photoURL);
          setOnboarding(false);
        } else {
          setOnboarding(true);
        }
      });
      return function cleanup() {
        if (firebase.auth().currentUser) {
          var ref = firebase
            .database()
            .ref('users/' + firebase.auth().currentUser.uid + '/gallery/')
            .child('username');
          var ref2 = firebase
            .database()
            .ref('users/' + firebase.auth().currentUser.uid + '/plan');
          ref.off('value');
          ref2.off('value');
        }
      };
    }
  }, [ready]);

  const successToast = (text) =>
    toast.success(text, {
      id: 'success',
      /* icon: '🪄', */
      style: {
        borderRadius: '100px',
        background: '#0F172A',
        color: '#fff',
      },
    });

  const problemToast = (text) =>
    toast.error(text, {
      id: 'error',
      /* icon: '🪄', */
      style: {
        borderRadius: '100px',
        background: '#0F172A',
        color: '#fff',
      },
    });

  useEffect(() => {
    if (!router.query.pop && !router.query.upgrade && !router.query.tour) {
      uid && productName && getSchedule(uid);
    }
  }, [uid, productName, router.query]);

  useEffect(() => {
    if (!router.query.pop && !router.query.upgrade && !router.query.tour) {
      uid && productName && getPlanUpgrade(uid);
    }
  }, [uid, productName, router.query]);

  async function getActiveProduct(userID) {
    var ref = firebase.database().ref('users/' + userID + '/plan');
    ref.on(
      'value',
      async function (snapshot) {
        if (snapshot.hasChild('stripe/customerID')) {
          setPastCustomer(true);
        }

        if (snapshot.hasChild('stripe')) {
          setCustomerID(snapshot.val().stripe.customerID);
          var hasLinkedID = snapshot.hasChild('stripe/linkedID');
          if (hasLinkedID === false) {
            const link = await linkCustomerID(
              userID,
              snapshot.val().stripe.customerID,
            );
            if (link.success === true) {
              //update DB
              var updateRef = firebase
                .database()
                .ref('users/' + userID + '/plan/stripe');
              updateRef.update({
                linkedID: true,
              });
            }
          }
        }

        if (snapshot.hasChild('status')) {
          setStatus(snapshot.val().status);
        }

        if (snapshot.hasChild('productName')) {
          var c = snapshot.hasChild('confirmed');

          if (
            snapshot.val().productName.includes('Pro') ||
            snapshot.val().productName.includes('pro') ||
            snapshot.val().productName.includes('Expert')
          ) {
            //check the period
            if (snapshot.val().productName === 'Expert Lifetime') {
              setProductName('Premier');
            } else {
              if (snapshot.hasChild('stripe/subscriptionID')) {
                const name = await getBillingPeriod(
                  snapshot.val().stripe.subscriptionID,
                );
                setProductName(name);
              } else {
                setProductName(snapshot.val().productName);
              }
            }
          } else {
            setProductName(snapshot.val().productName);
          }
        } else {
          setProductName('Free Plan');
        }
      },
      function (errorObject) {},
    );
  }

  const getSchedule = async (userID) => {
    var ref = firebase
      .database()
      .ref('users/' + userID)
      .child('templatePackSchedule')
      .child('edition-1');
    ref.once('value', async function (snapshot) {
      //check if the number of children is 12
      const hasSchedule = snapshot.exists();
      var hasDates = false;
      //check if each child has a date value asyncronously
      if (hasSchedule) {
        const datePromise = await Promise.all(
          Object.keys(snapshot.val()).map(async (key) => {
            const date = snapshot.child(key).child('date').exists();
            return date;
          }),
        );
        hasDates = datePromise.every(Boolean);
      }
      if (hasSchedule && hasDates) {
        //already has a schedule so don't do anything
      } else {
        if (productName.toLocaleLowerCase().includes('free')) {
          //do nothing
          //for free users, we only want to show this modal once, so check /modals/templatePackSchedule
          /*    var ref = firebase.database().ref("users/" + userID).child("modals").child("templatePackSchedule")
                    ref.once("value", function (snapshot) {
                        if (snapshot.val()) {
                            //already seen modal
                        } else {
                            setTemplatePackSelectorOpen(true)
                        }
                    }) */
        } else {
          //pop up the schedule modal - no schedule and a pro user
          setTemplatePackSelectorOpen(true);
        }
      }
    });
  };

  const getPlanUpgrade = async (userID) => {
    var ref = firebase
      .database()
      .ref('users/' + userID)
      .child('modals')
      .child('planUpgrade');
    ref.once('value', function (snapshot) {
      if (snapshot.val()) {
        if (snapshot.val().show) {
          snapshot.val().product === 'Pro'
            ? setProSuccess(true)
            : snapshot.val().product === 'Premier'
            ? setPremierSuccess(true)
            : null;
          ref.update({
            show: false,
          });
        }
      }
    });
  };

  function getGalleryUser(slug, uid) {
    var ref = firebase
      .database()
      .ref('gallery/' + slug.toLocaleLowerCase() + '/' + uid)
      .child('meta');
    ref.once('value', function (querySnapshot) {
      if (querySnapshot.hasChild('displayName')) {
        //user has new meta DB structure
        const newImage = querySnapshot.val().image;
        if (querySnapshot.val().image.includes('googleusercontent')) {
          setPhotoUrl(newImage.replace('=s96-c', ''));
        } else if (querySnapshot.val().image.includes('twimg')) {
          setPhotoUrl(newImage.replace('_normal', ''));
        } else {
          setPhotoUrl(querySnapshot.val().image);
        }
        setName(querySnapshot.val().displayName);
      } else {
        //not migrated yet
        var ref2 = firebase
          .database()
          .ref('gallery/' + slug.toLocaleLowerCase() + '/' + uid);
        ref2.once('value', function (snap) {
          if (snap.exists()) {
            const newImage = snap.val().image;
            if (snap.val().image.includes('googleusercontent')) {
              setPhotoUrl(newImage.replace('=s96-c', ''));
            } else if (snap.val().image.includes('twimg')) {
              setPhotoUrl(newImage.replace('_normal', ''));
            } else {
              setPhotoUrl(snap.val().image);
            }
            setName(snap.val().displayName);
          } else {
            setPhotoUrl(firebase.auth().currentUser.photoURL);
            setName(firebase.auth().currentUser.displayName);
          }
        });
      }
    });
  }

  function getUserGallery(uid) {
    var ref = firebase
      .database()
      .ref('users/' + uid + '/gallery/')
      .child('username');
    ref.on('value', (querySnapshot) => {
      if (querySnapshot.exists()) {
        getGalleryUser(querySnapshot.val(), uid);
        setGalleryName(querySnapshot.val());
      } else {
        setPhotoUrl(firebase.auth().currentUser.photoURL);
      }
    });
  }

  async function linkCustomerID(userID, customerID) {
    try {
      const { data } = await client.post('/api/stripe-customer-metadata', {
        userID,
        customerID,
      });
      return data;
    } catch (err) {
      console.error(err);
      if (axios.isAxiosError(err)) {
        return err.response.data;
      }
      return {};
    }
  }

  async function billingPortalFetch() {
    try {
      const { data } = await client.post(
        '/api/create-customer-portal-session',
        { customerID: customerID, returnLink: router.pathname },
      );
      if (data.success) {
        router.push(data.url);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const signUserOut = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
        router.reload();
      })
      .catch((error) => {
        // An error happened
      });
  };

  const unLink = () => {
    firebase
      .auth()
      .currentUser.unlink('twitter.com')
      .then(() => {
        // Auth provider unlinked from account
        // ...

        var ref = firebase
          .database()
          .ref('users/' + firebase.auth().currentUser.uid + '/meta/twitter');
        ref.remove().then(() => {
          successToast('Twitter account disconnected');
          setHasTwitter(false);
          setUnlinkModalOpen(false);
        });
      })
      .catch((error) => {
        // An error happened
        // ...
      });
  };

  const getNotificationCount = () => {
    var ref = firebase
      .database()
      .ref('users/' + firebase.auth().currentUser.uid + '/notifications');
    ref.once('value', (snapshot) => {
      if (snapshot.exists()) {
        if (snapshot.val().count === 0) {
          //seen the medium notification but nothing else
          setNotificationCount(totalNotifications - 1);
        } else {
          totalNotifications === snapshot.val().count
            ? setNotificationCount(0)
            : setNotificationCount(totalNotifications - snapshot.val().count);
        }
      } else {
        setNotificationCount(totalNotifications);
      }
    });
  };

  const checkIfUserHasTwitter = () => {
    var ref = firebase
      .database()
      .ref('users/' + firebase.auth().currentUser.uid + '/meta/twitter');
    ref.once('value', function (snapshot) {
      if (snapshot.exists()) {
        if (snapshot.hasChild('handle')) {
          setHasTwitter(true);
        } else {
          setHasTwitter(false);
        }
      }
    });
  };

  const getProviders = () => {
    var hasGoogleTemp = false;
    firebase.auth().currentUser.providerData.forEach(function (profile) {
      if (profile.providerId === 'twitter.com') {
        //has twitter
        //dont show twitter buttons
      }
      if (profile.providerId === 'google.com') {
        hasGoogleTemp = true;
        //has twitter
        //show connect / disconnct twitter
      }
    });
    if (hasGoogleTemp) {
      setHideTwitterDisconnect(false);
    } else {
      setHideTwitterDisconnect(true);
    }
  };

  var twitterProvider = new firebase.auth.TwitterAuthProvider();

  const connectTwitter = () => {
    const db = firebase.database();
    firebase
      .auth()
      .currentUser.linkWithPopup(twitterProvider)
      .then((result) => {
        // Accounts successfully linked.
        var credential = result.credential;
        var user = result.user;

        user.providerData.forEach((provider) => {
          if (provider.providerId === 'twitter.com') {
            var token = credential.accessToken;
            var secret = credential.secret;

            db.ref('users/' + firebase.auth().currentUser.uid + '/meta')
              .child('twitter')
              .set({
                token: token,
                secret: secret,
                photo: provider.photoURL,
                displayName: provider.displayName,
                handle: result.additionalUserInfo.username,
              })
              .then(() => {
                db.ref(
                  'gallery/' +
                    galleryName +
                    '/' +
                    firebase.auth().currentUser.uid +
                    '/meta',
                )
                  .update({
                    twitterURL: result.additionalUserInfo.username,
                  })
                  .then(() => {
                    successToast('Twitter account connected!');
                    setHasTwitter(true);
                  });
              })
              .catch((error) => {});
          }
        });

        // ...
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  const pressNotifications = () => {
    firebase
      .database()
      .ref('users/' + firebase.auth().currentUser.uid + '/notifications')
      .update({
        count: totalNotifications,
      })
      .then(() => {
        setNotificationCount(0);
      });
    router.push('/notifications');
  };
  const openPalette = () => {
    setIsCommandPaletteOpen(!isCommandPaletteOpen);
  };

  const createNew = () => {
    setCreateModalOpen(!createModalOpen);
  };
  const socialBlog = () => {
    router.push(`/${encodeURIComponent(galleryName)}`);
  };

  const goProStore = () => {
    router.push('/pro-store');
  };

  const goLibrary = () => {
    router.push('/library');
  };

  const goCollections = () => {
    router.push(`/${encodeURIComponent(galleryName)}/collections`);
  };

  const goTemplates = () => {
    router.push('/templates');
  };

  const goAnalytics = () => {
    router.push('/analytics');
  };

  const goSettings = () => {
    router.push('/settings');
  };

  const keyMap = {
    //OPEN_K: "command+k",
    //OPEN_SLASH: "/",
    CREATE_NEW: 'n',
    //SOCIAL_BLOG: "1",
    //LIBRARY: "2",
    //COLLECTIONS: "3",
    //TEMPLATES: "4",
    //ANALYTICS: "5",
    //PRO_STORE: "6",
    //SETTINGS: "6",
  };

  const handlers = {
    //OPEN_K: openPalette,
    //OPEN_SLASH: openPalette,
    CREATE_NEW: createNew,
    //SOCIAL_BLOG: socialBlog,
    //PRO_STORE: goProStore,
    //LIBRARY: goLibrary,
    //COLLECTIONS: goCollections,
    //TEMPLATES: goTemplates,
    //ANALYTICS: goAnalytics,
    //SETTINGS: goSettings,
  };

  const sidebarAffiliate = () => {
    setSidebarOpen(false);
    setAffiliateModal(true);
  };

  useEffect(() => {
    helpModalOpen && setSidebarOpen(false);
  }, [helpModalOpen]);

  const openCreateModal = () => {
    setIsPopoverOpen(false);
    setTimeout(() => {
      setCreateModalOpen(true);
    }, 100);
  };

  const popoverButtonRef = useRef();

  const [profilePopover, setProfilePopover] = useState(false);
  const onboardingNext = () => {
    cio.trackEvent(uid, {
      name: 'start_tour',
    });
    setTour(true);
    setOnboardingModalOpen(false);
    setProfileModalOpen(true);
    setProfilePopover(true);
  };

  const onboardingCreate = () => {
    setTour(true);
    setOnboardingModalOpen(false);
    setIsPopoverOpen(true);
  };

  useEffect(() => {
    if (router.query.tour === 'onboarding' && uid && !router.query.upgrade) {
      checkIfSeenTour(uid);
    }
  }, [router.query, uid]);

  const checkIfSeenTour = (uid) => {
    var ref = firebase.database().ref('users/' + uid + '/modals/onboarding');
    ref.once('value', function (snapshot) {
      if (snapshot.exists()) {
        if (snapshot.val() === true) {
          //do nothing
        } else {
          router.query?.tourType && setTourType(router.query?.tourType);
          setOnboardingModalOpen(true);
        }
      } else {
        router.query?.tourType && setTourType(router.query?.tourType);
        setOnboardingModalOpen(true);
      }
    });
  };

  const closeTour = () => {
    setProfilePopover(false);
    setTimeout(() => {
      setOnboardingModalOpen(false);
      setProfileModalOpen(false);
      setTour(false);
    }, 200);
  };

  const [onboardingModalType, setOnboardingModalType] = useState(null);

  const tourProfileDone = () => {
    setProfileModalOpen(false);
    setOnboardingModalType('profile');
    setOnboardingModalOpen(true);
  };

  return (
    <GlobalHotKeys handlers={handlers} keyMap={keyMap}>
      <div className="flex max-h-screen min-h-screen w-screen flex-col antialiased md:overflow-hidden">
        {/*  <Header /> */}

        <OnboardingModal
          onboardingModalType={onboardingModalType}
          isOpen={onboardingModalOpen}
          setIsOpen={setOnboardingModalOpen}
          next={
            onboardingModalType === 'profile'
              ? onboardingCreate
              : onboardingNext
          }
          userID={uid}
          type={tourType}
        />
        <EditProfile
          tourDone={() => tourProfileDone()}
          closeTour={() => closeTour()}
          isPopoverOpen={profilePopover}
          setIsPopoverOpen={setProfilePopover}
          tour={tour}
          isOpen={profileModalOpen}
          setIsOpen={setProfileModalOpen}
          gallerySlug={galleryName}
          user={user}
          setIsReady={() => null}
        />
        <CreateNewModal
          open={createModalOpen}
          setOpen={setCreateModalOpen}
          STRIPE_PK={STRIPE_PK}
          tour={tour}
          setTour={setTour}
          tourType={tourType}
        />
        <ConfirmUnlinkTwitter
          open={isUnlinkModalOpen}
          setOpen={setUnlinkModalOpen}
          deleteConfirmed={() => unLink()}
          keep={() => setUnlinkModalOpen(false)}
        />
        <CommandPalette
          open={isCommandPaletteOpen}
          setOpen={setIsCommandPaletteOpen}
        />
        <RefProgram
          isOpen={affiliateModal}
          setIsOpen={setAffiliateModal}
          gallerySlug={galleryName}
        />
        <HelpModal
          isOpen={helpModalOpen}
          setIsOpen={setHelpModalOpen}
          userID={uid}
        />

        <UpgradeSuccessPro
          isOpen={proSuccess}
          setIsOpen={setProSuccess}
          setTemplateSelectorOpen={setTemplatePackSelectorOpen}
        />
        <UpgradeSuccessPremier
          isOpen={premierSuccess}
          setIsOpen={setPremierSuccess}
        />

        <TemplatePackSelectorModal
          isOpen={templatePackSelectorOpen}
          setIsOpen={setTemplatePackSelectorOpen}
          STRIPE_PK={STRIPE_PK}
        />

        <UpgradePlanModal
          STRIPE_PK={STRIPE_PK}
          isOpen={planModalOpen}
          setIsOpen={setPlanModalOpen}
        />

        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            open={sidebarOpen}
            className="fixed inset-0 z-40 flex lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex w-full max-w-xs flex-1 flex-col overflow-y-scroll bg-white p-4 antialiased focus:outline-none">
                {router.pathname.includes('/settings') ? (
                  <div className="flex h-full w-full flex-col space-y-8">
                    <div className="flex w-full flex-row items-center justify-start space-x-2">
                      <button
                        className="group rounded-[10px] bg-opacity-50 p-2 duration-200 hover:bg-gray-200 hover:bg-opacity-100"
                        onClick={() => router.push('/library')}
                      >
                        <ArrowLeftIcon
                          className="h-5 w-5 text-gray-500 group-hover:text-gray-900"
                          aria-hidden="true"
                        />
                      </button>
                      <h1 className="text-base font-medium">Settings</h1>
                    </div>
                    <div className=" flex w-full flex-1 flex-col items-start justify-between">
                      <div className="flex w-full flex-col space-y-0.5">
                        <NavItem
                          key={'profile'}
                          href={'/settings/profile'}
                          icon={
                            <svg
                              className="h-[16px] w-[16px]"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 496 512"
                            >
                              <path
                                fill="currentColor"
                                d="M248 104c-53 0-96 43-96 96s43 96 96 96 96-43 96-96-43-96-96-96zm0 144c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm0-240C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-49.7 0-95.1-18.3-130.1-48.4 14.9-23 40.4-38.6 69.6-39.5 20.8 6.4 40.6 9.6 60.5 9.6s39.7-3.1 60.5-9.6c29.2 1 54.7 16.5 69.6 39.5-35 30.1-80.4 48.4-130.1 48.4zm162.7-84.1c-24.4-31.4-62.1-51.9-105.1-51.9-10.2 0-26 9.6-57.6 9.6-31.5 0-47.4-9.6-57.6-9.6-42.9 0-80.6 20.5-105.1 51.9C61.9 339.2 48 299.2 48 256c0-110.3 89.7-200 200-200s200 89.7 200 200c0 43.2-13.9 83.2-37.3 115.9z"
                              ></path>
                            </svg>
                          }
                          track="Clicked Profile"
                        >
                          Profile
                        </NavItem>
                        <NavItem
                          key={'account'}
                          href={'/settings/account'}
                          icon={
                            <svg
                              className="h-[16px] w-[16px]"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M464 128h-80V80c0-26.51-21.49-48-48-48H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V176c0-26.51-21.49-48-48-48zM176 80h160v48H176V80zM54 176h404c3.31 0 6 2.69 6 6v74H48v-74c0-3.31 2.69-6 6-6zm404 256H54c-3.31 0-6-2.69-6-6V304h144v24c0 13.25 10.75 24 24 24h80c13.25 0 24-10.75 24-24v-24h144v122c0 3.31-2.69 6-6 6z"
                              ></path>
                            </svg>
                          }
                          track="Clicked Account"
                        >
                          Account
                        </NavItem>
                        <NavItem
                          key={'integrations'}
                          href={'/settings/integrations'}
                          icon={
                            <svg
                              className="h-[16px] w-[16px]"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M144 343.78a48 48 0 1 0 48 48 48 48 0 0 0-48-48zM101.74 213a37 37 0 0 0 52.36 0l78.56-78.44A79.06 79.06 0 0 0 227 17.49c-28.08-23.13-69.54-22.82-99-.86-29.45-22-71-22.3-99.05.89a79.11 79.11 0 0 0-5.77 117.08zM59.42 54.53A29.54 29.54 0 0 1 78.35 48 35.08 35.08 0 0 1 103 58.32l25 24.89 24.93-24.89c12.25-12.15 31.43-13.83 43.58-3.82a31.09 31.09 0 0 1 2.31 46.15l-70.85 70.71-70.87-70.69a31.13 31.13 0 0 1 2.32-46.14zm337.93 305.24l32.27-69.89a24 24 0 1 0-43.54-20.12l-63.7 138h109.27l-36.92 68.58A24 24 0 1 0 437 499.05l75-139.28zm-141.44-72h-27.42l-7.09-14.17a27.36 27.36 0 0 0-25.64-17.76H92.08a27.39 27.39 0 0 0-25.65 17.76l-7 14.21H32a32 32 0 0 0-32 32V480a32 32 0 0 0 32 32h223.91a32 32 0 0 0 32-32V319.79a32 32 0 0 0-32-31.98zm-16 176.23H48V335.79h41.22l13.21-26.73 2.57-5.26h77.83l2.69 5.4 13.24 26.59h41.13zm112-256V68.24L463.83 51v78.58a84 84 0 0 0-16-1.69c-35.34 0-64 21.47-64 48s28.64 48 64 48 64-21.48 64-48V32c0-17.9-13.54-32-29.64-32a28.08 28.08 0 0 0-4.26.33L329.39 23.17c-14.63 2.25-25.5 15.74-25.5 31.66V161.6a83.25 83.25 0 0 0-16-1.7c-35.33 0-64 21.55-64 48.13s28.64 48.13 64 48.13 63.98-21.55 63.98-48.16z"
                              ></path>
                            </svg>
                          }
                          track="Clicked Integrations"
                        >
                          Integrations
                        </NavItem>
                        {custom_domains && (
                          <NavItem
                            key={'domains'}
                            href={'/settings/domains'}
                            icon={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={2}
                                stroke="currentColor"
                                className="h-[16px] w-[16px]"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                                />
                              </svg>
                            }
                            track="Clicked Domains"
                          >
                            Domains
                          </NavItem>
                        )}
                        <NavItem
                          key={'affiliate'}
                          href={'/settings/affiliate'}
                          icon={
                            <svg
                              className="h-[16px] w-[16px]"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                            >
                              <path
                                fill="currentColor"
                                d="M464 144h-26.1c6.2-12.1 10.1-25.5 10.1-40 0-48.5-39.5-88-88-88-41.6 0-68.5 21.3-103 68.3-34.5-47-61.4-68.3-103-68.3-48.5 0-88 39.5-88 88 0 14.5 3.8 27.9 10.1 40H48c-26.5 0-48 21.5-48 48v128c0 8.8 7.2 16 16 16h16v107.4c0 29 23.6 52.6 52.6 52.6h342.8c29 0 52.6-23.6 52.6-52.6V336h16c8.8 0 16-7.2 16-16V192c0-26.5-21.5-48-48-48zM232 448H84.6c-2.5 0-4.6-2-4.6-4.6V336h112v-48H48v-96h184v256zm-78.1-304c-22.1 0-40-17.9-40-40s17.9-40 40-40c22 0 37.5 7.6 84.1 77l2 3h-86.1zm122-3C322.5 71.6 338 64 360 64c22.1 0 40 17.9 40 40s-17.9 40-40 40h-86.1l2-3zM464 288H320v48h112v107.4c0 2.5-2 4.6-4.6 4.6H280V192h184v96z"
                              ></path>
                            </svg>
                          }
                          track="Clicked Affiliate"
                        >
                          Affiliate
                        </NavItem>
                      </div>
                      <NavItem
                        click={() => signUserOut()}
                        variant={'small-alt'}
                        icon={
                          <svg
                            className="-mr-1 h-[16px] w-[16px]"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="currentColor"
                              d="M96 64h84c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12H96c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h84c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12H96c-53 0-96-43-96-96V160c0-53 43-96 96-96zm231.1 19.5l-19.6 19.6c-4.8 4.8-4.7 12.5.2 17.1L420.8 230H172c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h248.8L307.7 391.7c-4.8 4.7-4.9 12.4-.2 17.1l19.6 19.6c4.7 4.7 12.3 4.7 17 0l164.4-164c4.7-4.7 4.7-12.3 0-17l-164.4-164c-4.7-4.6-12.3-4.6-17 .1z"
                            ></path>
                          </svg>
                        }
                        track="Logged Out"
                      >
                        Sign out
                      </NavItem>
                    </div>
                  </div>
                ) : (
                  <Sidebar
                    tour={tour}
                    setShowTour={setTour}
                    galleryName={galleryName}
                    popoverButtonRef={popoverButtonRef}
                    photoUrl={photoUrl}
                    openCreateModal={() => openCreateModal()}
                    setHelpModalOpen={setHelpModalOpen}
                    setIsPopoverOpen={setIsPopoverOpen}
                    isPopoverOpen={isPopoverOpen}
                  />
                )}
              </div>
            </Transition.Child>
            <div className="w-14 flex-shrink-0" aria-hidden="true">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {isOnboarding ? (
          (router.pathname === '/[slug]' &&
            router.query.slug !== galleryName) ||
          (router.pathname === '/[slug]/posts/[post]' &&
            router.query.slug !== galleryName) ? null : (
            <div
              className={
                'flex w-full flex-col items-center justify-between border-b-2 border-gray-100 bg-white'
              }
            >
              <div className=" flex w-full items-center justify-between p-3">
                <div className="flex flex-row items-center justify-start">
                  <IconButton
                    click={() => router.push('/')}
                    color={'group group-hover:bg-gray-100'}
                    customText={'text-gray-800'}
                    prefix={<TSSimple className={'h-6 w-6'} />}
                  ></IconButton>
                </div>

                <div className="flex flex-row items-center justify-center space-x-4">
                  <button
                    onClick={() => router.push('/')}
                    className={
                      'font-primary font-medium text-gray-500 duration-200 hover:text-blue-500'
                    }
                  >
                    <p>Login</p>
                  </button>
                  <Button click={() => router.push('/')} variant={'primary'}>
                    Join Typeshare
                  </Button>
                </div>
              </div>
            </div>
          )
        ) : router.pathname === '/[slug]' &&
          router.query.slug !== galleryName ? null : (
          <div
            className={`${
              (router.pathname === '/[slug]' &&
                router.query.slug !== galleryName) ||
              (router.pathname === '/[slug]/collections' &&
                router.query.slug !== galleryName) ||
              (router.pathname === '/[slug]/threads' &&
                router.query.slug !== galleryName) ||
              (router.pathname === '/[slug]/collections/[collection]' &&
                router.query.slug !== galleryName) ||
              (router.pathname === '/[slug]/posts/[post]' &&
                router.query.slug !== galleryName) ||
              (router.pathname === '/[slug]/essays' &&
                router.query.slug !== galleryName)
                ? 'border-gray-200'
                : 'border-gray-200'
            } z-20 flex w-full flex-col items-center justify-between border-0 border-gray-100 bg-white`}
          >
            <div
              onClick={() =>
                status === 'past_due'
                  ? router.push('/settings/account')
                  : paidPlans.includes(productName) || pastCustomer
                  ? setPlanModalOpen(true)
                  : setPlanModalOpen(true)
              }
              className={` cursor-pointer text-center ${
                status === 'past_due' ? 'bg-red-500' : 'bg-blue-500'
              } w-full items-center justify-center px-4 py-2.5 font-sans ${
                status === 'past_due'
                  ? 'flex'
                  : !productName
                  ? 'hidden'
                  : productName.includes('Pro') ||
                    productName.includes('Premier') ||
                    productName.includes('premier') ||
                    productName.includes('Expert')
                  ? 'hidden'
                  : 'flex'
              }`}
            >
              <p className="flex w-full flex-row items-center justify-between font-medium text-white sm:justify-center">
                <span className="md:hidden">
                  {status === 'past_due'
                    ? 'Payment failed.'
                    : 'Join Typeshare Pro'}
                </span>
                <span className="hidden md:inline">
                  {status === 'past_due'
                    ? 'Your last payment has failed. Please update your payment method to continue using Typeshare Pro'
                    : isShip30 && !customerID
                    ? 'As a Ship 30 member, you have FREE access to Typeshare Pro throughout the duration of the course!'
                    : 'Upgrade your account to unlock templates, analytics, and remove limits.'}
                </span>
                <span className="block sm:ml-4 sm:inline-block">
                  <Button
                    click={() =>
                      status === 'past_due'
                        ? router.push('/settings/account')
                        : paidPlans.includes(productName) || pastCustomer
                        ? setPlanModalOpen(true)
                        : setPlanModalOpen(true)
                    }
                    size={'mini'}
                  >
                    {status === 'past_due'
                      ? 'Update Payment Method'
                      : isShip30 && !customerID
                      ? 'Claim Free Month'
                      : customerID
                      ? 'Upgrade Now'
                      : 'Get a Free 30 Day Trial'}{' '}
                  </Button>
                </span>
              </p>
            </div>
          </div>
        )}

        {router.pathname === '/[slug]' ||
        router.pathname === '/theme' ||
        (router.pathname === '/[slug]/collections' &&
          router.query.slug !== galleryName) ||
        (router.pathname === '/[slug]/collections/[collection]' &&
          router.query.slug !== galleryName) ? null : (
          <div className="relative z-10 -mb-8 flex w-full flex-col items-center justify-between space-y-2 bg-gray-50 px-4 pb-6 pt-4 md:hidden md:px-8">
            <div className="fixed top-0 z-10 h-8 w-full bg-gradient-to-b from-gray-50"></div>

            <div className="flex w-full flex-row items-center justify-between">
              <button
                className={`${!uid && 'hidden'}`}
                onClick={() => setSidebarOpen(true)}
              >
                <MenuIcon
                  className="h-6 w-6 text-gray-900"
                  aria-hidden="true"
                />
              </button>
              <h1 className=" text-center text-3xl font-extrabold tracking-tight text-gray-800">
                {router.pathname === '/library' && 'Published'}
                {router.pathname === '/library/drafts' && 'Drafts'}
                {router.pathname === '/library/queue' && 'Queue'}
                {router.pathname.includes('/[slug]/collections') &&
                  'Collections'}
                {router.pathname.includes('/pro-store') && 'Pro Store'}
                {router.pathname.includes('/analytics') && 'Analytics'}
                {router.pathname.includes('/templates') && 'Templates'}
                {router.pathname.includes('/settings') && 'Settings'}
              </h1>
              <button
                onClick={() => setCreateModalOpen(true)}
                className={`${
                  !uid && 'hidden'
                } transform duration-200 hover:scale-95 active:scale-100`}
              >
                <PlusIcon
                  className="h-6 w-6 text-gray-900"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        )}

        <div className={'flex flex-1 flex-row bg-gray-100 md:overflow-hidden'}>
          <div
            className={`${
              isOnboarding ||
              router.pathname === '/[slug]' ||
              router.pathname === '/theme' ||
              router.pathname === '/[slug]/threads/[thread]' ||
              router.pathname === '/[slug]/posts/[post]' ||
              (router.pathname === '/[slug]/collections' &&
                router.query.slug !== galleryName) ||
              (router.pathname === '/[slug]/threads' &&
                router.query.slug !== galleryName) ||
              (router.pathname === '/[slug]/collections/[collection]' &&
                router.query.slug !== galleryName) ||
              (router.pathname === '/[slug]/essays' &&
                router.query.slug !== galleryName)
                ? 'hidden'
                : 'hidden md:flex'
            } bg-white p-5 ${!tour && 'overflow-y-auto'} ${
              sidebarCollapsed
                ? 'w-[200px] min-w-[200px]'
                : 'w-[226px] min-w-[226px]'
            } relative z-30 flex-col items-start justify-between`}
          >
            {router.pathname.includes('/settings') ? (
              <div className="flex h-full w-full flex-col space-y-8">
                <div className="flex w-full flex-row items-center justify-start space-x-2">
                  <button
                    className="group rounded-[10px] bg-opacity-50 p-2 duration-200 hover:bg-gray-200 hover:bg-opacity-100"
                    onClick={() => router.push('/library')}
                  >
                    <ArrowLeftIcon
                      className="h-5 w-5 text-gray-500 group-hover:text-gray-900"
                      aria-hidden="true"
                    />
                  </button>
                  <h1 className="text-base font-medium">Settings</h1>
                </div>
                <div className=" flex w-full flex-1 flex-col items-start justify-between">
                  <div className="flex w-full flex-col space-y-0.5">
                    <NavItem
                      href={'/settings/profile'}
                      icon={
                        <svg
                          className="h-[16px] w-[16px]"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 496 512"
                        >
                          <path
                            fill="currentColor"
                            d="M248 104c-53 0-96 43-96 96s43 96 96 96 96-43 96-96-43-96-96-96zm0 144c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm0-240C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-49.7 0-95.1-18.3-130.1-48.4 14.9-23 40.4-38.6 69.6-39.5 20.8 6.4 40.6 9.6 60.5 9.6s39.7-3.1 60.5-9.6c29.2 1 54.7 16.5 69.6 39.5-35 30.1-80.4 48.4-130.1 48.4zm162.7-84.1c-24.4-31.4-62.1-51.9-105.1-51.9-10.2 0-26 9.6-57.6 9.6-31.5 0-47.4-9.6-57.6-9.6-42.9 0-80.6 20.5-105.1 51.9C61.9 339.2 48 299.2 48 256c0-110.3 89.7-200 200-200s200 89.7 200 200c0 43.2-13.9 83.2-37.3 115.9z"
                          ></path>
                        </svg>
                      }
                      track="Clicked Profile"
                    >
                      Profile
                    </NavItem>
                    <NavItem
                      href={'/settings/account'}
                      icon={
                        <svg
                          className="h-[16px] w-[16px]"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M464 128h-80V80c0-26.51-21.49-48-48-48H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V176c0-26.51-21.49-48-48-48zM176 80h160v48H176V80zM54 176h404c3.31 0 6 2.69 6 6v74H48v-74c0-3.31 2.69-6 6-6zm404 256H54c-3.31 0-6-2.69-6-6V304h144v24c0 13.25 10.75 24 24 24h80c13.25 0 24-10.75 24-24v-24h144v122c0 3.31-2.69 6-6 6z"
                          ></path>
                        </svg>
                      }
                      track="Clicked Account"
                    >
                      Account
                    </NavItem>
                    <NavItem
                      href={'/settings/integrations'}
                      icon={
                        <svg
                          className="h-[16px] w-[16px]"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M144 343.78a48 48 0 1 0 48 48 48 48 0 0 0-48-48zM101.74 213a37 37 0 0 0 52.36 0l78.56-78.44A79.06 79.06 0 0 0 227 17.49c-28.08-23.13-69.54-22.82-99-.86-29.45-22-71-22.3-99.05.89a79.11 79.11 0 0 0-5.77 117.08zM59.42 54.53A29.54 29.54 0 0 1 78.35 48 35.08 35.08 0 0 1 103 58.32l25 24.89 24.93-24.89c12.25-12.15 31.43-13.83 43.58-3.82a31.09 31.09 0 0 1 2.31 46.15l-70.85 70.71-70.87-70.69a31.13 31.13 0 0 1 2.32-46.14zm337.93 305.24l32.27-69.89a24 24 0 1 0-43.54-20.12l-63.7 138h109.27l-36.92 68.58A24 24 0 1 0 437 499.05l75-139.28zm-141.44-72h-27.42l-7.09-14.17a27.36 27.36 0 0 0-25.64-17.76H92.08a27.39 27.39 0 0 0-25.65 17.76l-7 14.21H32a32 32 0 0 0-32 32V480a32 32 0 0 0 32 32h223.91a32 32 0 0 0 32-32V319.79a32 32 0 0 0-32-31.98zm-16 176.23H48V335.79h41.22l13.21-26.73 2.57-5.26h77.83l2.69 5.4 13.24 26.59h41.13zm112-256V68.24L463.83 51v78.58a84 84 0 0 0-16-1.69c-35.34 0-64 21.47-64 48s28.64 48 64 48 64-21.48 64-48V32c0-17.9-13.54-32-29.64-32a28.08 28.08 0 0 0-4.26.33L329.39 23.17c-14.63 2.25-25.5 15.74-25.5 31.66V161.6a83.25 83.25 0 0 0-16-1.7c-35.33 0-64 21.55-64 48.13s28.64 48.13 64 48.13 63.98-21.55 63.98-48.16z"
                          ></path>
                        </svg>
                      }
                      track="Clicked Integrations"
                    >
                      Integrations
                    </NavItem>
                    {custom_domains && (
                      <NavItem
                        key={'domains'}
                        href={'/settings/domains'}
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={2}
                            stroke="currentColor"
                            className="h-[16px] w-[16px]"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
                            />
                          </svg>
                        }
                        track="Clicked Domains"
                      >
                        Domains
                      </NavItem>
                    )}
                    <NavItem
                      href={'/settings/affiliate'}
                      icon={
                        <svg
                          className="h-[16px] w-[16px]"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            fill="currentColor"
                            d="M464 144h-26.1c6.2-12.1 10.1-25.5 10.1-40 0-48.5-39.5-88-88-88-41.6 0-68.5 21.3-103 68.3-34.5-47-61.4-68.3-103-68.3-48.5 0-88 39.5-88 88 0 14.5 3.8 27.9 10.1 40H48c-26.5 0-48 21.5-48 48v128c0 8.8 7.2 16 16 16h16v107.4c0 29 23.6 52.6 52.6 52.6h342.8c29 0 52.6-23.6 52.6-52.6V336h16c8.8 0 16-7.2 16-16V192c0-26.5-21.5-48-48-48zM232 448H84.6c-2.5 0-4.6-2-4.6-4.6V336h112v-48H48v-96h184v256zm-78.1-304c-22.1 0-40-17.9-40-40s17.9-40 40-40c22 0 37.5 7.6 84.1 77l2 3h-86.1zm122-3C322.5 71.6 338 64 360 64c22.1 0 40 17.9 40 40s-17.9 40-40 40h-86.1l2-3zM464 288H320v48h112v107.4c0 2.5-2 4.6-4.6 4.6H280V192h184v96z"
                          ></path>
                        </svg>
                      }
                      track="Clicked Affiliate"
                    >
                      Affiliate
                    </NavItem>
                  </div>
                  <NavItem
                    click={() => signUserOut()}
                    variant={'small-alt'}
                    icon={
                      <svg
                        className="-mr-1 h-[16px] w-[16px]"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          d="M96 64h84c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12H96c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h84c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12H96c-53 0-96-43-96-96V160c0-53 43-96 96-96zm231.1 19.5l-19.6 19.6c-4.8 4.8-4.7 12.5.2 17.1L420.8 230H172c-6.6 0-12 5.4-12 12v28c0 6.6 5.4 12 12 12h248.8L307.7 391.7c-4.8 4.7-4.9 12.4-.2 17.1l19.6 19.6c4.7 4.7 12.3 4.7 17 0l164.4-164c4.7-4.7 4.7-12.3 0-17l-164.4-164c-4.7-4.6-12.3-4.6-17 .1z"
                        ></path>
                      </svg>
                    }
                    track="Logged Out"
                  >
                    Sign out
                  </NavItem>
                </div>
              </div>
            ) : (
              <Sidebar
                tour={tour}
                setShowTour={setTour}
                galleryName={galleryName}
                popoverButtonRef={popoverButtonRef}
                photoUrl={photoUrl}
                openCreateModal={() => openCreateModal()}
                setHelpModalOpen={setHelpModalOpen}
                setIsPopoverOpen={setIsPopoverOpen}
                isPopoverOpen={isPopoverOpen}
              />
            )}
          </div>

          {
            <div className={'w-full bg-gray-50 md:overflow-y-scroll'}>
              {children}
            </div>
          }
        </div>

        <div className={'font-sans font-medium'}>
          <Toaster position="bottom-center" reverseOrder={false} />
        </div>
      </div>
    </GlobalHotKeys>
  );
}
