import { Fragment, useEffect, useState } from 'react';
import { Combobox, Dialog, Transition } from '@headlessui/react';
import {
  ArrowNarrowRightIcon,
  DatabaseIcon,
  PlusCircleIcon,
  SearchIcon,
} from '@heroicons/react/outline';
import {
  ArrowRightIcon,
  DocumentAddIcon,
  DuplicateIcon,
  FolderAddIcon,
  FolderIcon,
  HashtagIcon,
  TagIcon,
} from '@heroicons/react/solid';
import TwitterBirdIcon from '../Icons/twitter';
import DraftIcon from '../Icons/draft';
import ScheduledPostIcon from '../Icons/scheduled-icon';
import PublishedPostIcon from '../Icons/published-icon';
import TSSimple from '../Icons/ts-simple';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import { useRef } from 'react';

const posts = [
  {
    id: 1,
    name: 'What it takes to write online',
    url: '#',
    type: 'draft',
    item: 'post',
  },
  {
    id: 2,
    name: 'Starting an online business in 30 days',
    url: '#',
    type: 'scheduled',
    item: 'post',
  },
  {
    id: 3,
    name: 'How I got my first customers',
    url: '#',
    type: 'published',
    item: 'post',
  },
  {
    id: 4,
    name: 'How to write a blog post',
    url: '#',
    type: 'draft',
    item: 'post',
  },
  {
    id: 5,
    name: 'The day I quit my job',
    url: '#',
    type: 'scheduled',
    item: 'post',
  },
  {
    id: 6,
    name: 'The best way to get started with React',
    url: '#',
    type: 'published',
    item: 'post',
  },
  // More projects...
];
const recent = [posts[0], posts[1], posts[2]];

const quickActions = [
  {
    name: 'Create a new post...',
    icon: PlusCircleIcon,
    shortcut: 'N',
    url: '#',
  },
  {
    name: 'Create a new collection...',
    icon: DatabaseIcon,
    shortcut: 'N',
    url: '#',
  },
];

const navigationActions = [
  {
    name: 'Go to Library',
    icon: ArrowNarrowRightIcon,
    shortcut: 'L',
    url: '#',
  },
  { name: 'Go to Drafts', icon: ArrowNarrowRightIcon, shortcut: 'D', url: '#' },
  { name: 'Go to Queue', icon: ArrowNarrowRightIcon, shortcut: 'Q', url: '#' },
  {
    name: 'Go to Collections',
    icon: ArrowNarrowRightIcon,
    shortcut: 'C',
    url: '#',
  },
  {
    name: 'Go to Templates',
    icon: ArrowNarrowRightIcon,
    shortcut: 'T',
    url: '#',
  },
  {
    name: 'Go to Analytics',
    icon: ArrowNarrowRightIcon,
    shortcut: 'A',
    url: '#',
  },
];

const postActions = [
  {
    name: 'Edit',
    icon: DocumentAddIcon,
    shortcut: 'E',
    url: '#',
    showFor: ['drafts', 'scheduled', 'published'],
  },
  {
    name: 'Duplicate',
    icon: DuplicateIcon,
    shortcut: 'D',
    url: '#',
    showFor: ['drafts', 'scheduled', 'published'],
  },
  {
    name: 'View on Typeshare',
    icon: ArrowRightIcon,
    shortcut: 'V',
    url: '#',
    showFor: ['published'],
  },
  {
    name: 'View on Twitter',
    icon: TwitterBirdIcon,
    shortcut: 'T',
    url: '#',
    showFor: ['published'],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Example({ open, setOpen }) {
  const [query, setQuery] = useState('');
  const [actionsQuery, setActionsQuery] = useState('');

  const [activePost, setActivePost] = useState(null);

  const comboInput = useRef();

  const filteredProjects =
    query === ''
      ? []
      : posts.filter((project) => {
          return project.name.toLowerCase().includes(query.toLowerCase());
        });

  const filteredActions =
    actionsQuery === ''
      ? []
      : postActions.filter((action) => {
          return action.name.toLowerCase().includes(actionsQuery.toLowerCase());
        });

  const filteredNavigationActions =
    query === ''
      ? []
      : navigationActions.filter((action) => {
          return action.name.toLowerCase().includes(query.toLowerCase());
        });

  const updateSelected = (item) => {
    if (item.item === 'post') {
      setActivePost(item);
    }
  };

  useEffect(() => {
    console.log(query);
  }, [query]);

  const goBack = () => {
    setActivePost(null);
    comboInput.current.focus();
    //set comboInput value to query
    comboInput.current.value = query;
  };

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')}>
      <Dialog
        as="div"
        className="fixed inset-0 z-30 overflow-y-auto p-4 antialiased sm:p-6 md:p-24 md:pt-36"
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Combobox
            as="div"
            className="mx-auto max-w-2xl transform divide-y divide-gray-500 divide-opacity-10 overflow-hidden rounded-xl  bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
            onChange={(item) => updateSelected(item)}
          >
            <div className="flex flex-row items-center justify-center p-4">
              {activePost ? (
                <button
                  onClick={() => goBack(null)}
                  className="flex items-center justify-center rounded-md bg-gray-100 p-1 text-gray-500 duration-150 hover:bg-gray-200 hover:text-gray-600"
                >
                  <ChevronLeftIcon className="h-5 w-5" />
                </button>
              ) : (
                <div className="flex items-center justify-center p-1">
                  <SearchIcon
                    className="pointer-events-none h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              )}
              <Combobox.Input
                ref={comboInput}
                className="font-regular w-full border-0 bg-transparent text-lg text-gray-900 placeholder-gray-400 focus:ring-0"
                placeholder={
                  activePost ? 'Search actions...' : 'Search Typeshare...'
                }
                value={activePost ? actionsQuery : query}
                onChange={(event) =>
                  activePost
                    ? setActionsQuery(event.target.value)
                    : setQuery(event.target.value)
                }
                autoFocus
              />
            </div>

            {(query === '' ||
              filteredProjects.length > 0 ||
              filteredNavigationActions.length > 0) &&
              !activePost && (
                <Combobox.Options
                  static
                  className="max-h-80 scroll-py-2 overflow-y-auto"
                >
                  <li className="">
                    <h2 className="bg-gray-50 px-3 py-1.5 text-xs font-normal text-gray-500">
                      {query === '' ? 'Recent' : `Searching for "${query}"`}
                    </h2>

                    <ul className="p-2 text-sm text-gray-700">
                      {(query === '' ? recent : filteredProjects).map(
                        (post) => (
                          <Combobox.Option
                            key={post.id}
                            value={post}
                            className={({ active }) =>
                              classNames(
                                'flex cursor-pointer select-none items-center rounded-md px-3 py-2',
                                active && 'bg-gray-100 text-gray-900',
                              )
                            }
                          >
                            {({ active }) => (
                              <>
                                {post.type === 'draft' ? (
                                  <DraftIcon
                                    className={
                                      'h-[14px] w-[14px] text-gray-300'
                                    }
                                  />
                                ) : post.type === 'scheduled' ? (
                                  <ScheduledPostIcon
                                    className={
                                      'h-[14px] w-[14px] text-yellow-400'
                                    }
                                  />
                                ) : (
                                  <PublishedPostIcon
                                    className={
                                      'h-[14px] w-[14px] text-green-700'
                                    }
                                  />
                                )}

                                <span className="ml-3 flex-auto truncate">
                                  {post.name}
                                </span>
                                {active && (
                                  <span className="ml-3 flex-none text-gray-500">
                                    Jump to...
                                  </span>
                                )}
                              </>
                            )}
                          </Combobox.Option>
                        ),
                      )}
                    </ul>
                  </li>
                  {query === '' && (
                    <li className="">
                      <h2 className="bg-gray-50 px-3 py-1.5 text-xs font-normal text-gray-500">
                        Quick actions
                      </h2>
                      <ul className="p-2 text-sm text-gray-700">
                        {quickActions.map((action) => (
                          <Combobox.Option
                            key={action.shortcut}
                            value={action}
                            className={({ active }) =>
                              classNames(
                                'flex cursor-default select-none items-center rounded-md px-3 py-2',
                                active &&
                                  'bg-gray-900 bg-opacity-5 text-gray-900',
                              )
                            }
                          >
                            {({ active }) => (
                              <>
                                <action.icon
                                  className={classNames(
                                    'h-4 w-4 flex-none text-gray-900 text-opacity-40',
                                    active && 'text-opacity-100',
                                  )}
                                  aria-hidden="true"
                                />
                                <span className="ml-3 flex-auto truncate">
                                  {action.name}
                                </span>
                                <span className="ml-3 flex-none text-xs font-semibold text-gray-500">
                                  <kbd className="font-sans">⌘</kbd>
                                  <kbd className="font-sans">
                                    {action.shortcut}
                                  </kbd>
                                </span>
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                      </ul>
                    </li>
                  )}
                  {(query === '' || filteredNavigationActions.length > 0) &&
                    !activePost && (
                      <li className="">
                        <h2 className="bg-gray-50 px-3 py-1.5 text-xs font-normal text-gray-500">
                          Navigation
                        </h2>
                        <ul className="p-2 text-sm text-gray-700">
                          {(query === ''
                            ? navigationActions
                            : filteredNavigationActions
                          ).map((action) => (
                            <Combobox.Option
                              key={action.shortcut}
                              value={action}
                              className={({ active }) =>
                                classNames(
                                  'flex cursor-default select-none items-center rounded-md px-3 py-2',
                                  active &&
                                    'bg-gray-900 bg-opacity-5 text-gray-900',
                                )
                              }
                            >
                              {({ active }) => (
                                <>
                                  <action.icon
                                    className={classNames(
                                      'h-4 w-4 flex-none text-gray-900 text-opacity-40',
                                      active && 'text-opacity-100',
                                    )}
                                    aria-hidden="true"
                                  />
                                  <span className="ml-3 flex-auto truncate">
                                    {action.name}
                                  </span>
                                  <span className="ml-3 flex-none text-xs font-semibold text-gray-500">
                                    <kbd className="font-sans">⌘</kbd>
                                    <kbd className="font-sans">
                                      {action.shortcut}
                                    </kbd>
                                  </span>
                                </>
                              )}
                            </Combobox.Option>
                          ))}
                        </ul>
                      </li>
                    )}
                </Combobox.Options>
              )}

            {query !== '' &&
              filteredProjects.length === 0 &&
              filteredNavigationActions.length === 0 &&
              !activePost && (
                <div className="py-14 px-6 text-center sm:px-14">
                  <FolderIcon
                    className="mx-auto h-6 w-6 text-gray-900 text-opacity-40"
                    aria-hidden="true"
                  />
                  <p className="mt-4 text-sm text-gray-900">
                    We couldn't find anything with that term. Please try again.
                  </p>
                </div>
              )}

            {activePost && (
              <div className="grid h-96 w-full grid-cols-2 divide-x divide-gray-100">
                <Combobox.Options
                  static
                  className="max-h-80 scroll-py-2 overflow-y-auto"
                >
                  <h2 className="bg-gray-50 px-3 py-1.5 text-xs font-normal text-gray-500">
                    {actionsQuery === ''
                      ? 'Actions'
                      : `Searching for "${actionsQuery}"`}
                  </h2>
                  <li className="p-2">
                    <ul className="text-sm text-gray-700">
                      {actionsQuery &&
                        filteredActions.length > 0 &&
                        filteredActions.map((action) => (
                          <Combobox.Option
                            disabled={
                              action.showFor.includes(activePost.type)
                                ? false
                                : true
                            }
                            key={action.shortcut}
                            value={action}
                            className={({ active }) =>
                              classNames(
                                'flex cursor-default select-none items-center rounded-md px-3 py-2',
                                active &&
                                  'bg-gray-900 bg-opacity-5 text-gray-900',
                                action.showFor.includes(activePost.type)
                                  ? ''
                                  : 'opacity-50',
                              )
                            }
                          >
                            {({ active }) => (
                              <>
                                <action.icon
                                  className={classNames(
                                    'h-[14px] w-[14px] flex-none text-gray-900 text-opacity-40',
                                    active && 'text-opacity-100',
                                  )}
                                  aria-hidden="true"
                                />
                                <span className="ml-3 flex-auto truncate">
                                  {action.name}
                                </span>
                                <span className="ml-3 flex-none text-xs font-semibold text-gray-500">
                                  <kbd className="font-sans">⌘</kbd>
                                  <kbd className="font-sans">
                                    {action.shortcut}
                                  </kbd>
                                </span>
                              </>
                            )}
                          </Combobox.Option>
                        ))}

                      {actionsQuery && filteredActions.length === 0 && (
                        <div className="flex flex-col items-center justify-center py-14 px-6 text-center sm:px-14">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            className="h-5 w-5"
                          >
                            <path d="M11.983 1.907a.75.75 0 00-1.292-.657l-8.5 9.5A.75.75 0 002.75 12h6.572l-1.305 6.093a.75.75 0 001.292.657l8.5-9.5A.75.75 0 0017.25 8h-6.572l1.305-6.093z" />
                          </svg>

                          <p className="mt-4 text-sm text-gray-600">
                            We couldn't find any actions with that term. Please
                            try again.
                          </p>
                        </div>
                      )}
                    </ul>

                    {actionsQuery === '' && (
                      <ul className="text-sm text-gray-700">
                        {postActions.map((action) => (
                          //(action.showFor).includes(activePost.type) &&
                          <Combobox.Option
                            disabled={
                              action.showFor.includes(activePost.type)
                                ? false
                                : true
                            }
                            key={action.shortcut}
                            value={action}
                            className={({ active }) =>
                              classNames(
                                'flex cursor-default select-none items-center rounded-md px-3 py-2 disabled:opacity-50',
                                active &&
                                  'bg-gray-900 bg-opacity-5 text-gray-900',
                                action.showFor.includes(activePost.type)
                                  ? ''
                                  : 'opacity-50',
                              )
                            }
                          >
                            {({ active }) => (
                              <>
                                <action.icon
                                  className={classNames(
                                    'h-[14px] w-[14px] flex-none text-gray-900 text-opacity-40',
                                    active && 'text-opacity-100',
                                  )}
                                  aria-hidden="true"
                                />
                                <span className="ml-3 flex-auto truncate">
                                  {action.name}
                                </span>
                                <span className="ml-3 flex-none text-xs font-semibold text-gray-500">
                                  {/*  <kbd className="font-sans">⌘</kbd> */}
                                  <kbd className="font-sans">
                                    {action.shortcut}
                                  </kbd>
                                </span>
                              </>
                            )}
                          </Combobox.Option>
                        ))}
                      </ul>
                    )}
                  </li>
                </Combobox.Options>
                <div>{activePost.name}</div>
              </div>
            )}

            <div className="flex w-full flex-row items-center justify-between border-t border-gray-100 p-3">
              <TSSimple className={'h-4 w-4 text-gray-400'} />

              <div className="flex flex-row items-center justify-end">
                <button className="group flex flex-row items-center justify-center space-x-2 rounded-lg p-1 pl-2 duration-150 hover:bg-gray-100">
                  <p className="text-xs font-medium text-gray-900">Open Post</p>
                  <kbd className="group-hover:bg-text-gray-600 flex h-5 w-5 items-center justify-center rounded-md bg-gray-100 pb-1 text-base text-gray-500 duration-150 group-hover:bg-gray-200">
                    ↵
                  </kbd>
                </button>

                <div className="ml-3 mr-2 h-4 w-[1px] rounded-full bg-gray-200" />

                <button className="group flex flex-row items-center justify-center space-x-2 rounded-lg p-1 pl-2 duration-150 hover:bg-gray-100">
                  <p className="text-xs font-medium text-gray-600">Actions</p>
                  <div className="flex flex-row items-center justify-center space-x-0.5">
                    <kbd className="group-hover:bg-text-gray-600 flex h-5 w-5 items-center justify-center rounded-md bg-gray-100 text-base text-gray-500 duration-150 group-hover:bg-gray-200">
                      ⌘
                    </kbd>
                    <kbd className="group-hover:bg-text-gray-600 flex h-5 w-5 items-center justify-center rounded-md bg-gray-100 text-sm font-medium text-gray-500 duration-150 group-hover:bg-gray-200">
                      K
                    </kbd>
                  </div>
                </button>
              </div>
            </div>
          </Combobox>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}
