import { useRouter as useNextRouter } from 'next/router';
import { UrlObject } from 'url';
import { ParsedUrlQueryInput } from 'node:querystring';
import { stringify } from 'querystring';
import React, { createContext, PropsWithChildren, useContext } from 'react';

type Context = {
  isCustomDomain: boolean;
  hostname: string;
  slug: string;
};

const SocialBlogContext = createContext<Context>({
  isCustomDomain: false,
  hostname: '',
  slug: '',
});

export const SocialBlogContextProvider = ({
  children,
  ...ctx
}: PropsWithChildren<Context>): React.ReactElement => {
  return (
    <SocialBlogContext.Provider value={ctx}>
      {children}
    </SocialBlogContext.Provider>
  );
};

export const useSocialBlogContext = (): Context =>
  useContext<Context>(SocialBlogContext);

type Url = UrlObject | string;
interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
  unstable_skipClientCache?: boolean;
}

/**
 * Wraps the `next/router` and exposes a few context aware routing
 * methods that are safe to use in the context of custom domains
 * and standard /[slug]/ style paths.
 */
export const useRouter = () => {
  const { isCustomDomain, hostname, slug } = useContext(SocialBlogContext);
  const router = useNextRouter();

  if (!isCustomDomain) {
    return router;
  }

  const removeSlugFromPath = (path: string = ''): string => {
    path = path.replace(`/[slug]`, '').replace(`/${hostname}`, '');
    if (slug && slug.startsWith(`/${slug}`)) {
      path = path.replace(`/${slug}`, '');
    }
    return path.length ? path : '/';
  };

  const removeSlugFromQuery = (query: string | ParsedUrlQueryInput) => {
    if (!query) {
      return query;
    }
    if (typeof query === 'string') {
      return query;
    }
    delete query.slug;
    return query;
  };

  const sanitizeUrl = (url?: Url): Url | undefined => {
    if (!url) {
      return;
    }
    if (typeof url === 'string') {
      return removeSlugFromPath(url);
    }

    return {
      ...url,
      query: removeSlugFromQuery(url.query),
      pathname: removeSlugFromPath(url.pathname),
    };
  };

  const standarizeUrl = (url: Url): UrlObject => {
    return typeof url === 'string' ? { pathname: url, query: '' } : url;
  };

  const redirectToApp = (url: UrlObject) => {
    window.location.href = `http://${process.env.NEXT_PUBLIC_VERCEL_URL}${
      url.pathname
    }?${typeof url.query === 'string' ? url.query : stringify(url.query)}`;
  };

  return {
    back() {
      return router.back();
    },
    push(url: Url, as?: Url, options?: TransitionOptions): Promise<boolean> {
      url = standarizeUrl(url);
      if (url.pathname === '/') {
        redirectToApp(url);
        return;
      }
      return router.push(sanitizeUrl(url), sanitizeUrl(as), options);
    },
    replace(url: Url, as?: Url, options?: TransitionOptions): Promise<boolean> {
      url = standarizeUrl(url);
      if (url.pathname === '/') {
        redirectToApp(url);
        return;
      }
      return router.replace(sanitizeUrl(url), sanitizeUrl(as), options);
    },
  };
};
