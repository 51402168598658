import { Dialog, Transition } from '@headlessui/react';
import {
  ChartSquareBarIcon,
  CollectionIcon,
  DocumentTextIcon,
  GlobeAltIcon,
  LinkIcon,
  PlusIcon,
  XIcon,
} from '@heroicons/react/solid';
import { ChartBarIcon, LightningBoltIcon } from '@heroicons/react/solid';
import { Fragment, useEffect, useState } from 'react';
import Button from '../../Button';

import 'firebase/auth';
import firebase from 'firebase/app';
import 'firebase/database';

import { useRouter } from 'next/router';
import LogInModal from '../log-in';

import toast, { Toaster } from 'react-hot-toast';

export default function RefProgram({
  isOpen,
  setIsOpen,
  STRIPE_PK,
  PRO_PRICE,
  gallerySlug,
  fromPop = false,
}) {
  const [userID, setUserID] = useState(null);
  const [status, setStatus] = useState(null);
  const [email, setEmail] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [rewardfulName, setRewardfulName] = useState(null);
  const [isOnboarding, setOnboarding] = useState(false);
  const [showStep3, setShowStep3] = useState(false);
  const [TMPRewardfulName, setTMPRewardfulName] = useState(null);
  const [offerID, setOfferID] = useState(null);

  const [loading, setLoading] = useState(true);

  const router = useRouter();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        setUserID(user.uid);
        setOnboarding(false);
      } else {
        setOnboarding(true);
        setUserID(null);
      }
    });
  }, []);

  function closeModal() {
    setIsOpen(false);
    fromPop ? router.replace('/library', undefined, { shallow: true }) : null;
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    if (userID && gallerySlug) {
      getRewardfulName();
    }
  }, [userID, gallerySlug]);

  const getRewardfulName = () => {
    if (userID && gallerySlug) {
      firebase
        .database()
        .ref('gallery')
        .child(gallerySlug)
        .child(userID)
        .child('meta/affiliate')
        .once('value', function (snapshot) {
          if (snapshot.exists()) {
            const name = snapshot.val().name;
            setRewardfulName(name);
            setTMPRewardfulName(name);
            setOfferID(snapshot.val().offerID);
          }
        })
        .then(() => {
          setLoading(false);
        });
    }
  };

  const changeName = (name) => {
    setTMPRewardfulName(name);
  };
  async function addAffiliatetoConvertKit() {
    if (userID) {
      const subscriberID = await getSubscriberID(userID);
      changeAffiliateField('active', subscriberID);
    } else {
      console.log('no user id');
    }
  }

  async function removeAffiliatetoConvertKit() {
    if (userID) {
      const subscriberID = await getSubscriberID(userID);
      changeAffiliateField('', subscriberID);
    } else {
      console.log('no user id');
    }
  }

  async function addToConvertKit(email, uid) {
    //use this to get ther subscriberID if it's not in the DB
    return await fetch(
      'https://api.convertkit.com/v3/forms/2605946/subscribe',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
        },
        body: JSON.stringify({ api_key: 'RDof1mhbPJEfOe1cTkPQJA', email }),
      },
    ).then(async function (result) {
      //get returned ID from ConvertKit and store it in the database
      const response = await result.json();
      const subscriptionID = response.subscription
        ? response.subscription.subscriber.id
        : null;

      const dbRef = firebase
        .database()
        .ref('users')
        .child(uid)
        .child('convertKit');
      dbRef.update({
        email: email,
        id: subscriptionID,
      });

      return subscriptionID;
    });
  }

  async function getSubscriberID(uid) {
    const dbRef = firebase
      .database()
      .ref('users')
      .child(uid)
      .child('convertKit');
    return dbRef.once('value').then(async function (snapshot) {
      if (snapshot.hasChild('id')) {
        const data = snapshot.val();
        return data.id;
      } else {
        //get email from DB - first meta
        const emailRef = await firebase
          .database()
          .ref('users')
          .child(uid)
          .child('meta')
          .child('email')
          .once('value');
        if (emailRef.val()) {
          const email = emailRef.val();
          const id = await addToConvertKit(email, uid);
          return id;
        } else {
          const emailRef2 = await firebase
            .database()
            .ref('users')
            .child(uid)
            .child('email')
            .once('value');
          if (emailRef2.val()) {
            const email = emailRef2.val();
            const id = await addToConvertKit(email, uid);
            return id;
          } else {
            //no email in DB anywhere
          }
        }
      }
    });
  }

  async function changeAffiliateField(status, id) {
    return fetch(`https://api.convertkit.com/v3/subscribers/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        api_secret: '53Fr-MW3AlrdV_vkQ7vynIoRSNME0r5lSbtV_6fD43Y',
        fields: { referral_status: status },
      }),
    }).then(async function (result) {
      const data = await result.json();
      return data;
    });
  }

  const saveName = () => {
    //generate a 6 digit string with numbers and letters

    if (userID && gallerySlug) {
      const randomString = Math.random().toString(36).substring(2, 8);
      const code = offerID ? offerID : randomString.toLowerCase();
      setRewardfulName(TMPRewardfulName);

      firebase
        .database()
        .ref('users')
        .child(userID)
        .child('affiliate')
        .update({
          active: TMPRewardfulName.length === 0 ? false : true,
          name: TMPRewardfulName,
          offerID: code,
        })
        .then(() => {
          firebase
            .database()
            .ref('gallery')
            .child(gallerySlug)
            .child(userID)
            .child('meta/affiliate')
            .update({
              active: TMPRewardfulName.length === 0 ? false : true,
              name: TMPRewardfulName,
              offerID: code,
            })
            .then(() => {
              //create offer if none exists
              firebase
                .database()
                .ref('affiliateOffers')
                .child(code)
                .child(userID)
                .update({
                  offerID: code,
                  offer: 'affiliate-10-for-3-months',
                  active: TMPRewardfulName.length === 0 ? false : true,
                })
                .then(() => {
                  setOfferID(code);
                });

              TMPRewardfulName.length === 0
                ? removeAffiliatetoConvertKit()
                : addAffiliatetoConvertKit();
              TMPRewardfulName.length === 0
                ? successToast('Removed Affiliate Name!')
                : successToast('Saved!');
            });
        });
    } else {
      alert('Please log in...');
    }
  };
  const successToast = (text) =>
    toast.success(text, {
      id: 'success',
      /* icon: '🪄', */
      style: {
        borderRadius: '100px',
        background: '#0F172A',
        color: '#fff',
      },
    });

  const problemToast = (text) =>
    toast.error(text, {
      id: 'error',
      /* icon: '🪄', */
      style: {
        borderRadius: '100px',
        background: '#0F172A',
        color: '#fff',
      },
    });

  const copyLink = () => {
    navigator.clipboard.writeText(
      `https://typeshare.co?via=${rewardfulName}&offer=${offerID}`,
    );
    successToast('Copied!');
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-30 overflow-y-auto"
          onClose={closeModal}
        >
          <LogInModal isOpen={isOnboarding} />
          <div className="min-h-screen text-center sm:px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-300 transform"
              leaveFrom="opacity-100 scale-100 translate-y-0"
              leaveTo="opacity-0 scale-95 translate-y-full"
            >
              <div className="relative my-8 inline-block w-full max-w-3xl transform rounded-3xl bg-white text-left align-middle antialiased shadow-xl transition-all">
                <div className="sticky top-1 z-10 w-full">
                  <button
                    onClick={() => closeModal()}
                    className="absolute top-2 right-2 rounded-full bg-gray-100 p-2 text-gray-500 duration-200 hover:bg-gray-200 focus:outline-none"
                  >
                    <XIcon className="h-5 w-5" />
                  </button>
                </div>

                <Dialog.Title
                  as="h3"
                  className="mx-auto mt-12 px-6 font-primary text-2xl font-bold tracking-tight text-gray-900 sm:px-12"
                >
                  Earn effortless, passive income with Typeshare! 💰
                </Dialog.Title>
                <p className="mt-4 px-6 font-sans text-gray-800 sm:px-12 ">
                  Get a{' '}
                  <span className="font-bold text-gray-900">
                    30% recurring, monthly commision
                  </span>{' '}
                  made by users who sign up with your referral link.
                </p>
                <p className="mt-4 px-6 font-sans text-gray-800 sm:px-12 ">
                  The best part?{' '}
                  <span className="font-bold text-gray-900">
                    We’ll embed your referral link automatically at the bottom
                    of your posts.
                  </span>{' '}
                  That way, you get credit for anyone who starts writing on
                  Typeshare after reading your writing.
                </p>

                <p className="mt-4 px-6 font-sans text-gray-800 sm:px-12 ">
                  Easy enough, right? Let’s get you set up to start earning
                  effortlessly. 👇
                </p>

                <div
                  className={
                    'relative col-span-6 my-8 flex w-full items-center justify-center text-gray-600'
                  }
                >
                  <div
                    className={
                      'absolute top-1/2 h-[2px] w-full -translate-y-1/2 transform bg-gradient-to-r from-white via-gray-200 to-white'
                    }
                  />
                  <div
                    className={
                      'z-10 flex flex-row items-center justify-center bg-white px-4 font-sans font-semibold uppercase text-gray-500'
                    }
                  >
                    Step 1
                  </div>
                </div>

                <p className="px-6 font-sans text-gray-800 sm:px-12 ">
                  Join the affiliate program on our partner site Rewardful. Make
                  sure you{' '}
                  <span className="font-bold text-gray-900">
                    confirm your email and add your PayPal email address
                  </span>{' '}
                  (that's how you'll get paid).
                </p>

                <div className="mt-4 flex w-full items-center justify-center">
                  <a
                    href={'https://typeshare1.getrewardful.com/signup'}
                    target={'_blank'}
                    rel="noreferrer noopener"
                  >
                    <Button
                      disabled={loading}
                      loading={loading}
                      variant={'primary'}
                    >
                      Join Affiliate Program
                    </Button>
                  </a>
                </div>

                <div
                  className={
                    'relative col-span-6 my-8 flex w-full items-center justify-center text-gray-600'
                  }
                >
                  <div
                    className={
                      'absolute top-1/2 h-[2px] w-full -translate-y-1/2 transform bg-gradient-to-r from-white via-gray-200 to-white'
                    }
                  />
                  <div
                    className={
                      'z-10 flex flex-row items-center justify-center bg-white px-4 font-sans font-semibold uppercase text-gray-500'
                    }
                  >
                    Step 2
                  </div>
                </div>

                <div
                  className={`flex w-full flex-col space-y-2 ${
                    rewardfulName ? 'mb-6' : 'mb-12'
                  } px-6 sm:px-12`}
                >
                  <p className={'font-sans font-bold text-gray-800 '}>
                    Enter your Rewardful affilate name here
                  </p>

                  <div className="flex flex-col space-y-2 sm:flex-row sm:space-y-0 sm:space-x-2">
                    <div
                      className={
                        'flex w-full flex-row  rounded-lg border-2 border-gray-200 text-sm transition duration-200 focus-within:border-gray-900 focus-within:outline-none focus-within:ring-0'
                      }
                    >
                      <span className="inline-flex items-center whitespace-nowrap rounded-l-md border-r border-gray-200 bg-gray-100 px-3 font-primary font-medium text-gray-600">
                        typeshare.co/?via=
                      </span>
                      <input
                        onChange={(e) => changeName(e.target.value)}
                        value={TMPRewardfulName}
                        type="text"
                        placeholder="sam"
                        name="name"
                        id="name"
                        className={
                          'w-full overflow-hidden rounded-r-lg border-none p-3 font-medium outline-none ring-0 focus:outline-none focus:ring-0'
                        }
                      />
                    </div>
                    <Button
                      disabled={loading}
                      loading={loading}
                      click={() => saveName()}
                      variant={'primary'}
                    >
                      Save
                    </Button>
                  </div>
                  <p className="text-sm text-gray-600 ">
                    This is needed to count users who join from anywhere on your
                    Social Blog.{' '}
                    <a
                      target={'_blank'}
                      href={'/affiliate-demo.png'}
                      rel="noreferrer noopener"
                      className="font-bold text-blue-500 underline"
                    >
                      Click here{' '}
                    </a>{' '}
                    to learn how to find your Rewardful affiliate name.
                  </p>
                </div>

                {rewardfulName ? (
                  <>
                    <div
                      className={
                        'relative col-span-6 my-8 flex w-full items-center justify-center text-gray-600'
                      }
                    >
                      <div
                        className={
                          'absolute top-1/2 h-[2px] w-full -translate-y-1/2 transform bg-gradient-to-r from-white via-gray-200 to-white'
                        }
                      />
                      <div
                        className={
                          'z-10 flex flex-row items-center justify-center bg-white px-4 font-sans font-semibold uppercase text-gray-500'
                        }
                      >
                        Step 3
                      </div>
                    </div>

                    <p className="px-6 font-sans text-lg font-medium text-gray-900 sm:px-12 ">
                      You're all set! 🎉
                    </p>

                    <p className="mt-2 px-6 font-sans text-gray-800 sm:px-12 ">
                      And as a special thank you for being an early affiliate,
                      you can also{' '}
                      <span className="font-bold text-gray-900">
                        gift a free 30-day trial AND 50% off for 3 months to
                        everyone you refer
                      </span>{' '}
                      (because who’s going to say no to{' '}
                      <span className=" italic">that </span>?)
                    </p>

                    <p className="mt-2 px-6 font-sans text-gray-800 sm:px-12 ">
                      We’ve added that special gift automatically to your
                      referral link –
                      <span className="font-bold text-gray-900">
                        click below to copy it and start earning!
                      </span>
                    </p>

                    <div
                      className={
                        'relative col-span-6 mb-12 mt-8 flex w-full items-center justify-center text-gray-600'
                      }
                    >
                      <div
                        className={
                          'absolute top-1/2 h-[2px] w-full -translate-y-1/2 transform bg-gradient-to-r from-white via-gray-200 to-white'
                        }
                      />
                      <div
                        className={
                          'z-10 flex flex-row items-center justify-center bg-white px-4 font-sans font-semibold uppercase text-gray-500'
                        }
                      >
                        <Button
                          click={() => copyLink()}
                          prefix={<LinkIcon className={'h-4 w-4'} />}
                          variant={'secondary'}
                          size={'mini'}
                        >
                          {`typeshare.co?via=${rewardfulName}&offer=${offerID}`}
                        </Button>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </Transition.Child>
          </div>
          <Toaster position="bottom-right" reverseOrder={false} />
        </Dialog>
      </Transition>
    </>
  );
}
