import {
  AcademicCapIcon,
  BookmarkIcon,
  CheckCircleIcon,
  EyeIcon,
  FireIcon,
  LightBulbIcon,
  LockClosedIcon,
  LoginIcon,
} from '@heroicons/react/solid';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useState } from 'react';

import Image from 'next/image';

import Button from '../Button';
import { DuplicateIcon } from '@heroicons/react/outline';
import Checkout from '../Checkout';
import { Dialog, Transition } from '@headlessui/react';

import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { getActivePlan } from '../../utils/helpers';

import toast, { Toaster } from 'react-hot-toast';

import { Tooltip } from 'react-tippy';
import { client } from '../../utils/http';
import axios from 'axios';

export const SinglePack = ({
  theme = 'light',
  isLoggedIn = true,
  loading = false,
  STRIPE_PK,
  pack,
  title,
  slug,
  icon,
  description,
  locked,
  creator_photo,
  creator_name,
  uses,
  showView = true,
  unlocksAt,
  select = false,
  setSelected,
  setUpgradeModalOpen,
  showOnFree = false,
  setSelectorModal,
  price = null,
  showBuy = false,
  passSuccess = null,
  openLoginModal,
}) => {
  const router = useRouter();

  const [dateString, setDateString] = useState(null);
  const [checkoutModal, setCheckoutModal] = useState(false);

  const [subscriptionID, setSubscriptionID] = useState(null);
  const [customerID, setCustomerID] = useState(null);
  const [userID, setUserID] = useState(null);
  const [productName, setProductName] = useState(null);
  const [calculating, setCalculating] = useState(false);
  const [checkout, setCheckout] = useState(false);
  const [cartItems, setCartItems] = useState([]);

  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    loading ? setButtonLoading(true) : setButtonLoading(false);
  }, [loading]);

  useEffect(() => {
    pack && setCartItems([pack]);
  }, [pack]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (userInfo) {
      if (userInfo) {
        setUserID(userInfo.uid);
      } else {
      }
    });
  }, []);

  useEffect(() => {
    if (userID) {
      getActivePlan(userID, function (plan) {
        setProductName(plan.name);

        if (plan.customerID) {
          setCustomerID(plan.customerID);
        } else {
          setCustomerID(false);
        }
        setSubscriptionID(plan.subscriptionID);
      });
    }
  }, [userID]);

  useEffect(() => {
    if (!router.query.payment) {
      if (customerID === null) {
        setButtonLoading(true);
      } else if (customerID === false) {
        setButtonLoading(false);
      } else if (customerID) {
        setButtonLoading(false);
      }
    }
  }, [customerID, router.query]);

  useEffect(() => {
    //onvert unlockedAt (YYYY-MM-DD) to format July 10, 2020 using replace

    if (unlocksAt) {
      if (unlocksAt.includes('-')) {
        var day = unlocksAt.split('-')[2];
        //if first character is 0, remove it
        if (day.charAt(0) === '0') {
          day = day.substring(1);
        }

        const monthNum = unlocksAt.split('-')[1];
        const year = unlocksAt.split('-')[0];
        //get month name
        const unlockedAt = new Date(year, monthNum - 1, day);
        const month = unlockedAt.toLocaleString('default', { month: 'long' });

        var dateString2 = `${month} ${day}, ${year}`;

        if (year === `${new Date().getFullYear()}`) {
          dateString2 = `${month} ${day}`;
        }

        //if less than a week from now, show the date as number of days away
        const daysAway = Math.ceil(
          (unlockedAt - new Date()) / (1000 * 60 * 60 * 24),
        );
        if (daysAway < 7) {
          //if one day or less say tomorrow
          if (daysAway === 1) {
            dateString2 = 'tomorrow';
          } else if (daysAway === 0) {
            dateString2 = 'today';
          } else if (daysAway < 0) {
            dateString2 = `${month} ${day}`;
          } else {
            dateString2 = `in ${daysAway} days`;
          }
        }

        setDateString(dateString2);
      }
    }
  }, [unlocksAt]);

  const openPack = () => {
    router.push({ pathname: '/templates/packs/[pack]', query: { pack: slug } });
    setSelectorModal && setSelectorModal(false);
  };

  const checkoutComplete = () => {
    //listen for changes in /users/{userID}/templatePackSchedule/oneTime
    if (userID) {
      firebase
        .database()
        .ref('users')
        .child(userID)
        .child('templatePackSchedule')
        .child('oneTime')
        .child(slug)
        .on('value', function (snapshot) {
          if (snapshot.exists()) {
            const unlocked = snapshot.val().unlocked;
            if (unlocked) {
              firebase
                .database()
                .ref('users')
                .child(userID)
                .child('templatePackSchedule')
                .child('oneTime')
                .child(slug)
                .off();
              setCheckoutModal(false);
              setCheckout(false);
              setCalculating(false);
              successToast('Pack purchased!');
              passSuccess();
            }
          }
        });
    }
    //turn oFF listening for changes
  };

  const successToast = (text) =>
    toast.success(text, {
      id: 'success',
      /* icon: '🪄', */
      iconTheme: {
        primary: '#fff',
        secondary: '#10B981',
      },
      style: {
        borderRadius: '10px',
        background: '#10B981',
        color: '#fff',
      },
    });

  const problemToast = (text) =>
    toast.error(text, {
      id: 'error',
      /* icon: '🪄', */
      iconTheme: {
        primary: '#fff',
        secondary: '#ef4444',
      },
      style: {
        borderRadius: '10px',
        background: '#ef4444',
        color: '#fff',
      },
    });

  const buyPack = (name, priceIDObject) => {
    setButtonLoading(true);

    const priceID =
      process.env.NEXT_PUBLIC_APP_ENV === 'production'
        ? priceIDObject.prod
        : priceIDObject.dev;

    if (userID) {
      const stripe = window.Stripe(STRIPE_PK);

      createPackCheckoutSession(priceID, userID, name)
        .then(function (data) {
          stripe
            .redirectToCheckout({
              sessionId: data.sessionId,
            })
            .then(setButtonLoading(false));
        })
        .catch(function (err) {
          problemToast('Something went wrong.');
          console.log(err);
          setButtonLoading(false);
        });
    }
  };

  async function createPackCheckoutSession(price, uid, name) {
    // const refID = await getClientReferenceId();
    try {
      const { data } = await client.post('/api/stripe/checkout/pack', {
        price,
        uid,
        name,
        customerID: customerID,
        returnURL: router.asPath,
      });
      return data;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        return err.response.data;
      }
      return {};
    }
  }

  return (
    <div
      key={slug}
      className="flex flex-col overflow-hidden rounded-xl border border-gray-100"
    >
      <div
        className={` ${
          theme === 'light' ? 'bg-white' : 'bg-black'
        } relative h-64 w-full overflow-hidden rounded-t-xl px-12 pt-8`}
      >
        <LockClosedIcon
          className={` ${
            locked && !price ? 'flex' : 'hidden'
          } absolute top-1/2  ${
            theme === 'light' ? 'text-black' : 'text-white'
          } left-1/2 z-10 h-6 w-6 -translate-y-1/2 -translate-x-1/2`}
        />

        <img
          src={`/packs/${slug}.png`}
          className={`w-full ${locked && !price && 'blur-sm'}`}
        />

        <div
          className={`absolute bottom-0 left-0 z-20 h-8 w-full bg-gradient-to-t ${
            theme === 'light' ? 'from-gray-100 ' : 'from-black'
          }`}
        ></div>
      </div>
      <div className="flex flex-1 flex-col justify-between space-y-4 border-t border-gray-100 bg-white p-5">
        <div className="flex flex-col space-y-4">
          <div className="flex w-full flex-row items-center justify-between">
            <p className={'text-3xl'}>{icon}</p>
            {price && locked ? (
              price.disabled ? (
                <p className="text-xs font-medium uppercase text-gray-500">
                  Coming Soon
                </p>
              ) : (
                <p className="rounded-full bg-green-500 px-2 py-1 text-sm font-semibold text-white">
                  ${price.amount / 100}
                </p>
              )
            ) : select === slug ? (
              <p className="text-xs font-medium uppercase text-green-600">
                Unlocks Today
              </p>
            ) : locked ? (
              <p className="text-xs font-medium uppercase text-gray-500">
                {productName
                  ? productName.toLocaleLowerCase().includes('free') &&
                    !showOnFree
                    ? null
                    : unlocksAt && `Unlocks ${dateString}`
                  : null}
              </p>
            ) : price ? (
              <p className="text-xs font-medium uppercase text-green-600">
                Owned
              </p>
            ) : (
              <p className="text-xs font-medium uppercase text-green-600">
                Unlocked
              </p>
            )}
          </div>

          <div>
            <h2 className="text-lg font-semibold leading-tight tracking-tight text-gray-900">
              {title}
            </h2>
            <p className="text-sm tracking-tight text-gray-500">
              {description}
            </p>
          </div>
        </div>
        {!isLoggedIn ? (
          <Button
            click={() => {
              openLoginModal();
            }}
            size={'mini'}
            width={'full'}
            prefix={<LoginIcon className="-mr-2 h-4 w-4" />}
          >
            Login to Use
          </Button>
        ) : (
          <div className="flex flex-col space-y-4">
            {showView && price && locked && !showBuy ? (
              <div className="flex flex-row space-x-2">
                <Button
                  click={() => openPack()}
                  size={'mini'}
                  width={'full'}
                  variant={'secondary'}
                  prefix={
                    locked &&
                    !price && <LockClosedIcon className="-mr-2 h-4 w-4" />
                  }
                >
                  <p className="font-sans">View</p>
                </Button>
                {!price.disabled && (
                  <Button
                    loading={buttonLoading}
                    disabled={buttonLoading}
                    click={() => buyPack(slug, price.priceID)}
                    size={'mini'}
                    width={'full'}
                    variant={'primary'}
                    prefix={
                      locked &&
                      !price && <LockClosedIcon className="-mr-2 h-4 w-4" />
                    }
                  >
                    <p className="font-sans">Buy Now</p>
                  </Button>
                )}
              </div>
            ) : select ? (
              <Button
                click={() => {
                  /* select === slug ? setSelected(true) : */ locked
                    ? setSelected(slug, icon, title)
                    : select === slug
                    ? null
                    : openPack();
                }}
                size={'mini'}
                width={'full'}
                color={
                  select === slug ? 'bg-green-600' : !locked && 'bg-blue-500'
                }
                prefix={
                  select === slug && (
                    <CheckCircleIcon className="-mr-1 h-4 w-4" />
                  )
                }
              >
                {select === slug
                  ? 'Selected!'
                  : locked
                  ? 'Select'
                  : 'View Pack'}
              </Button>
            ) : (
              showView && (
                <Button
                  loading={buttonLoading}
                  disabled={
                    buttonLoading || (locked && price ? price.disabled : false)
                  }
                  click={() =>
                    locked
                      ? price
                        ? showBuy
                          ? buyPack(slug, price.priceID)
                          : openPack()
                        : setUpgradeModalOpen(true)
                      : slug && openPack()
                  }
                  size={'mini'}
                  width={'full'}
                  color={price ? 'bg-blue-500' : !locked && 'bg-blue-500'}
                  prefix={
                    locked &&
                    !price && <LockClosedIcon className="-mr-2 h-4 w-4" />
                  }
                >
                  <p className="font-sans">
                    {locked
                      ? price
                        ? showBuy
                          ? price.disabled
                            ? 'Coming soon...'
                            : 'Buy Pack Now'
                          : 'View Pack'
                        : 'Unlock Now'
                      : 'View Pack'}
                  </p>
                </Button>
              )
            )}

            <div className="flex w-full items-center justify-between">
              <div className="flex flex-row items-center justify-start space-x-1">
                <img src={creator_photo} className="h-4 w-4 rounded-full" />
                <p className="text-sm font-semibold tracking-tight text-gray-500">
                  {creator_name}
                </p>
              </div>
              {/*  <Tooltip
                            html={(
                                <div className="text-gray-600 flex flex-row space-x-3 justify-start items-center font-semibold uppercase bg-white font-mono antialiased m-0 text-xs p-0 rounded-md w-full">
                                    <p>{uses > 999 ? (Math.round((uses / 100)) / 10) + "K" : uses} uses</p>
                                </div>
                            )}
                            theme="light"
                            size='small'
                            position="top"
                            arrow={true}
                            effect={'solid'}
                            trigger="mouseenter"
                            offset={2}
                        >
                            <div className="flex flex-row space-x-0.5 items-center justify-center text-gray-500 text-sm font-medium">
                                <DuplicateIcon className="w-4 h-4" />
                                <p>
                                    {uses > 999 ? (Math.round((uses / 100)) / 10) + "K" : uses}
                                </p>
                            </div>
                        </Tooltip> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default function TemplatePacks({
  theme = 'light',
  STRIPE_PK,
  packs,
  modal,
  select = false,
  unlockedPacks,
  setSelected,
  setUpgradeModalOpen,
  showOnFree = false,
  setSelectorModal = null,
  isLoggedIn = true,
  openLoginModal,
}) {
  const [availablePacks, setAvailablePacks] = useState([]);

  useEffect(() => {
    unlockedPacks && setAvailablePacks(unlockedPacks);
  }, [unlockedPacks]);

  return packs && packs.length > 0 ? (
    <div
      className={`grid gap-4 ${
        modal
          ? 'grid-cols-1 xs:grid-cols-2 md:grid-cols-3'
          : 'grid-cols-1 xs:grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'
      }`}
    >
      {packs.map((pack, index) => {
        const isUnlocked = pack
          ? availablePacks
            ? availablePacks[pack.meta.slug]?.unlocked
              ? true
              : false
            : false
          : false;
        const unlocksAt = pack
          ? availablePacks
            ? availablePacks[pack.meta.slug]?.date
            : null
          : null;

        const {
          title,
          slug,
          icon,
          description,
          creator_photo,
          creator_name,
          uses,
        } = pack.meta;

        const price = pack.price;

        const isHidden = isUnlocked
          ? false
          : pack.meta.hide !== undefined
          ? pack.meta.hide
          : false;

        return (
          !isHidden && (
            <SinglePack
              theme={theme}
              openLoginModal={openLoginModal}
              isLoggedIn={isLoggedIn}
              STRIPE_PK={STRIPE_PK}
              setSelectorModal={setSelectorModal}
              select={select}
              price={price}
              setUpgradeModalOpen={setUpgradeModalOpen}
              setSelected={setSelected}
              showOnFree={showOnFree}
              unlocksAt={unlocksAt}
              locked={!isUnlocked}
              title={title}
              slug={slug}
              icon={icon}
              description={description}
              creator_photo={creator_photo}
              creator_name={creator_name}
              uses={uses}
            />
          )
        );
      })}
    </div>
  ) : null;
}
