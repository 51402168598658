/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, XCircleIcon } from '@heroicons/react/outline';
import { ExclamationCircleIcon } from '@heroicons/react/outline';
import { EyeOffIcon, XIcon } from '@heroicons/react/solid';
import Button from '../Button';

export default function ConfirmRemove({
  open,
  setOpen,
  remove,
  keep,
  name,
  id,
}) {
  const cancelButtonRef = useRef();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    setChecked(false);
  }, [open]);

  return (
    <Transition.Root
      initialFocus={cancelButtonRef}
      static
      show={open}
      as={Fragment}
    >
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-40 overflow-y-auto"
        open={open}
        onClose={setOpen}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center font-sans antialiased sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block w-full transform overflow-hidden rounded-xl bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-lg sm:align-middle">
              <div className="flex w-full flex-row items-center justify-between bg-gray-100 pt-2 pb-0 pl-4 pr-2 text-gray-500">
                <p className="font-mono text-xs font-semibold uppercase">
                  Remove Add-on
                </p>

                <button
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                  className={
                    ' transform rounded-full bg-gray-200 p-1.5 text-gray-500 outline-none duration-200 hover:scale-105 hover:text-gray-900 hover:outline-none focus:outline-none active:scale-100'
                  }
                >
                  <XIcon className="h-4 w-4" />
                </button>
              </div>

              <div className="flex items-center justify-center bg-gray-100 p-1.5">
                <div
                  className={
                    'flex w-full flex-col items-start justify-start rounded-xl bg-white p-6'
                  }
                >
                  {/* <p className="text-sm font-sans font-semibold uppercase text-center tracking-tight text-gray-400">
                  New
                </p> */}

                  <div
                    className={
                      'rounded-full bg-gradient-to-br from-red-500 to-red-300 p-3 text-white'
                    }
                  >
                    <div className={'h-5 w-5 rounded-full bg-white'} />
                  </div>

                  <Dialog.Title
                    as="h3"
                    className="mt-4 text-left font-primary text-xl font-bold text-gray-800"
                  >
                    Remove {name} from your account?
                  </Dialog.Title>
                  <p
                    className={
                      'mt-1 w-full text-left font-sans text-sm text-gray-600'
                    }
                  >
                    This will permanently remove {name} from your account at the
                    end of your billing period.
                  </p>
                  <div className="relative mt-4 flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        id="hide-analytics"
                        aria-describedby="candidates-description"
                        name="hide-analytics"
                        type="checkbox"
                        onChange={() => setChecked(!checked)}
                        className="h-4 w-4 cursor-pointer rounded-md border-gray-300 text-gray-900 focus:ring-blue-500"
                      />
                    </div>
                    <div className="ml-2 text-sm">
                      <label
                        htmlFor="hide-analytics"
                        className="cursor-pointer font-medium text-gray-700"
                      >
                        Remove {name} from your account?
                      </label>
                    </div>
                  </div>
                  <div className="mt-8 ml-auto flex flex-col items-end space-y-2 md:flex-row md:space-y-0 md:space-x-2">
                    <Button
                      click={() => remove(checked)}
                      disabled={!checked}
                      variant={'error-secondary'}
                      size={'small'}
                    >
                      {'Remove'}
                    </Button>
                    <Button click={keep} size={'small'}>
                      {'Keep'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
