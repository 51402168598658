export default function HelpIcon({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_30_1237)">
        <path
          d="M10.5 0.601807H1.5C0.672656 0.601807 0 1.27446 0 2.10181V8.85181C0 9.67915 0.672656 10.3518 1.5 10.3518H3.75V12.3206C3.75 12.487 3.88594 12.6018 4.03125 12.6018C4.0875 12.6018 4.14609 12.5854 4.19766 12.5456L7.125 10.3518H10.5C11.3273 10.3518 12 9.67915 12 8.85181V2.10181C12 1.27446 11.3273 0.601807 10.5 0.601807ZM10.875 8.85181C10.875 9.05806 10.7063 9.22681 10.5 9.22681H6.75L6.45 9.45181L4.875 10.6331V9.22681H1.5C1.29375 9.22681 1.125 9.05806 1.125 8.85181V2.10181C1.125 1.89556 1.29375 1.72681 1.5 1.72681H10.5C10.7063 1.72681 10.875 1.89556 10.875 2.10181V8.85181ZM8.625 3.78931H3.375C3.16875 3.78931 3 3.95806 3 4.16431V4.53931C3 4.74556 3.16875 4.91431 3.375 4.91431H8.625C8.83125 4.91431 9 4.74556 9 4.53931V4.16431C9 3.95806 8.83125 3.78931 8.625 3.78931ZM6.375 6.03931H3.375C3.16875 6.03931 3 6.20806 3 6.41431V6.78931C3 6.99556 3.16875 7.16431 3.375 7.16431H6.375C6.58125 7.16431 6.75 6.99556 6.75 6.78931V6.41431C6.75 6.20806 6.58125 6.03931 6.375 6.03931Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_30_1237">
          <rect
            className={className}
            fill="currentColor"
            transform="translate(0 0.601807)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
