import { Dialog, RadioGroup, Transition } from '@headlessui/react';
import {
  CashIcon,
  ChartSquareBarIcon,
  CheckCircleIcon,
  CollectionIcon,
  DocumentTextIcon,
  GlobeAltIcon,
  MailIcon,
  PlusIcon,
  XIcon,
} from '@heroicons/react/solid';
import { ChartBarIcon, LightningBoltIcon } from '@heroicons/react/solid';
import { Fragment, useEffect, useState } from 'react';
import Button from '../../Button';

import 'firebase/auth';
import firebase from 'firebase/app';
import 'firebase/database';

import { useRouter } from 'next/router';
import LogInModal from '../log-in';
import { PricingPlans } from '../../../pages/plan';
import TSSimple from '../../Icons/ts-simple';
import Checkout from '../../Checkout';
import { cio } from '../../../utils/customer-io';
import { toast } from 'react-hot-toast';

const helpOptions = [
  { name: 'General', icon: '👋' },
  { name: 'Bug', icon: '🐞' },
  { name: 'Billing', icon: '💵' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function HelpModal({ isOpen, setIsOpen, userID }) {
  const [helpType, setHelpType] = useState(helpOptions[0]);
  const [text, setText] = useState('');
  const [email, setEmail] = useState('');
  const [CIOEmail, setCIOEmail] = useState('');
  const [customer, setCustomer] = useState(null);
  const [success, setSuccess] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    userID && isOpen ? getCustomer(userID) : null;
  }, [userID, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setText('');
      setHelpType(helpOptions[0]);
      setSuccess(false);
      setLoaded(false);
      setSubmitting(false);
      setCustomer(null);
    }
  }, [isOpen]);

  async function getCustomer(uid) {
    const cust = await cio.fetchCustomer(uid);
    if (cust?.exists) {
      setCustomer(cust?.data?.customer?.attributes);
      setCIOEmail(cust?.data?.customer?.attributes?.email);
      setEmail(cust?.data?.customer?.attributes?.email);
      setLoaded(true);
    } else {
      setLoaded(true);
    }
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    if (success) {
      setSuccess(false);
    }
  }, [text]);

  async function submit() {
    setSubmitting(true);

    if (email.length > 0 && text.length > 0) {
      if (CIOEmail === email) {
        //dont change email
      } else {
        await cio.updateCustomer(userID, { email });
      }

      const data = {
        type: helpType.name,
        text,
      };

      const response = await cio.trackEvent(userID, {
        name: 'help_request',
        data,
      });

      const msg = await cio.submitHelp(email, text, helpType, customer);

      if (response && msg) {
        setSuccess(true);
        setSubmitting(false);
      } else {
        problemToast('Something went wrong. Please try again.');
        setSubmitting(false);
      }
    } else {
      problemToast('Please fill out all fields.');
      setSubmitting(false);
    }
  }

  const successToast = (text) =>
    toast.success(text, {
      id: 'success',
      /* icon: '🪄', */
      style: {
        borderRadius: '100px',
        background: '#0F172A',
        color: '#fff',
      },
    });

  const problemToast = (text) =>
    toast.error(text, {
      id: 'error',
      /* icon: '🪄', */
      style: {
        borderRadius: '100px',
        background: '#0F172A',
        color: '#fff',
      },
    });

  return (
    <>
      <Transition show={isOpen} as={Fragment}>
        <Dialog
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModal}
        >
          <div className="flex h-full items-end justify-center text-center font-sans antialiased sm:block sm:items-center sm:justify-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-50" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300 transform"
              enterFrom="opacity-0 scale-90 translate-y-96"
              enterTo="opacity-100 scale-100 translate-y-0"
              leave="ease-in duration-300 transform"
              leaveFrom="opacity-100 scale-100 translate-y-0"
              leaveTo="opacity-0 translate-y-96 scale-90"
            >
              <div className="relative inline-block w-full max-w-lg transform rounded-t-3xl bg-gray-50 text-left align-middle antialiased shadow-xl transition-all sm:rounded-b-3xl">
                <div className="sticky top-1 z-10 w-full">
                  <button
                    onClick={() => closeModal()}
                    className="absolute top-2 right-2 rounded-full bg-gray-100 p-2 text-gray-500 duration-200 hover:bg-gray-200 focus:outline-none"
                  >
                    <XIcon className="h-5 w-5" />
                  </button>
                </div>

                <div className="flex flex-col space-y-6 p-8">
                  <div className="flex flex-col items-start space-y-1.5">
                    <h1 className="text-3xl font-bold tracking-tight">Help</h1>
                    <p className="w-full rounded-lg bg-gray-100 p-2 text-center text-sm font-medium text-gray-600">
                      Please browse our{' '}
                      <a
                        href={
                          'https://typeshare-co.notion.site/Typeshare-Help-Articles-1428590017cb43eb869e18fe6eeab587'
                        }
                        rel="noreferrer noopener"
                        target={'_blank'}
                        className="text-blue-500 hover:text-blue-600"
                      >
                        Help Articles
                      </a>{' '}
                      before submitting 👏
                    </p>
                  </div>

                  <div className="flex flex-col space-y-4">
                    <div>
                      <div className="mb-2 flex items-center justify-between">
                        <h2 className="text-sm text-gray-900">
                          What kind of help do you need?
                        </h2>
                      </div>

                      <RadioGroup
                        value={helpType}
                        onChange={setHelpType}
                        className="mt-2"
                      >
                        <RadioGroup.Label className="sr-only">
                          Choose a memory option
                        </RadioGroup.Label>
                        <div className="flex flex-row gap-2">
                          {helpOptions.map((option) => (
                            <RadioGroup.Option
                              key={option.name}
                              value={option}
                              className={({ active, checked }) =>
                                classNames(
                                  'cursor-pointer focus:outline-none',
                                  active
                                    ? 'ring-2 ring-blue-400 ring-offset-2'
                                    : '',
                                  checked
                                    ? 'border-transparent bg-blue-500 text-white'
                                    : 'border-gray-200 bg-white text-gray-700 hover:bg-gray-50',
                                  'flex items-center justify-center rounded-full border py-1.5 px-3 text-sm font-medium',
                                )
                              }
                            >
                              <RadioGroup.Label
                                as="span"
                                className={
                                  'flex flex-row items-center justify-center space-x-1'
                                }
                              >
                                <p>{option.icon}</p>
                                <p>{option.name}</p>
                              </RadioGroup.Label>
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>
                    </div>

                    <div>
                      <label
                        htmlFor="email"
                        className="m-0 text-sm text-gray-900"
                      >
                        Where should we send replies?
                      </label>
                      <div className="mt-1">
                        <input
                          disabled={!loaded}
                          type="email"
                          name="email"
                          id="email"
                          className="block w-full rounded-lg border-gray-200 text-sm focus:border-blue-500 focus:ring-blue-500"
                          placeholder="you@example.com"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                    </div>

                    <div>
                      <div className="mb-1 flex items-center justify-between">
                        <h2 className="text-sm text-gray-900">
                          How can we help?
                        </h2>
                      </div>

                      <textarea
                        value={text}
                        className="h-32 w-full rounded-lg border-gray-200 p-3 text-sm focus:border-blue-500 focus:ring-blue-500"
                        placeholder="Tell us more about your problem"
                        onChange={(e) => {
                          setText(e.target.value);
                        }}
                      />
                    </div>

                    <div>
                      {success && (
                        <div className="mb-2 flex w-full flex-row items-center justify-center space-x-1.5 rounded-full bg-green-500 p-2 text-center text-sm font-medium text-white">
                          <CheckCircleIcon className="h-4 w-4" />
                          <p className="text-center">
                            Your message has been sent!
                          </p>
                        </div>
                      )}

                      <Button
                        loading={submitting}
                        click={() => submit()}
                        tip={
                          text.length === 0 || email.length === 0
                            ? 'Please fill out all fields.'
                            : null
                        }
                        disabled={
                          text.length === 0 ||
                          email.length === 0 ||
                          success ||
                          submitting
                            ? true
                            : false
                        }
                        size={'mini'}
                        width={'full'}
                        prefix={<MailIcon className="-mr-1 h-4 w-4" />}
                      >
                        Send
                      </Button>

                      <div className="mt-2 flex flex-row items-center justify-center space-x-2">
                        <img
                          src={'/sams.jpeg'}
                          className={'h-6 w-6 rounded-full'}
                        />
                        <p className="text-center text-sm text-gray-500">
                          We usually reply{' '}
                          <span className="border-b border-dashed border-gray-300">
                            within a day
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
