export default function AnalyticsAltIcon({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.21699 8.09199C3.38789 8.26289 3.66488 8.26289 3.83578 8.09199L5.25 6.67805L7.56574 8.99379C7.73664 9.16469 8.01363 9.16469 8.18453 8.99379L11.1984 5.97969L12.0047 6.78633C12.4182 7.19977 13.125 6.90691 13.125 6.3223V3.5625C13.125 3.32078 12.9292 3.125 12.6875 3.125H9.9277C9.34309 3.125 9.05023 3.83184 9.46367 4.24527L10.2703 5.05164L7.875 7.44695L5.55926 5.13121C5.38836 4.96031 5.11137 4.96031 4.94047 5.13121L2.90773 7.16395C2.73684 7.33484 2.73684 7.61184 2.90773 7.78273L3.21699 8.09199ZM13.5625 11.4375H1.3125V2.6875C1.3125 2.44578 1.11672 2.25 0.875 2.25H0.4375C0.195781 2.25 0 2.44578 0 2.6875V11.875C0 12.3582 0.391836 12.75 0.875 12.75H13.5625C13.8042 12.75 14 12.5542 14 12.3125V11.875C14 11.6333 13.8042 11.4375 13.5625 11.4375Z"
        fill="currentColor"
      />
    </svg>
  );
}
