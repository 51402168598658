import SpinnerIcon from '../Icons/spinner';
import Link from 'next/link';

import { Tooltip } from 'react-tippy';

export default function IconButton({
  href,
  click,
  tip,
  target = '_blank',
  text,
  useDiv = false,
  variant,
  tooltip = null,
  disabled,
  rounded,
  icon,
  mobile,
  width,
  loading,
  size,
  color,
  prefix,
  suffix,
  backgroundStyle,
  textStyle,
  customText,
  active,
  ping,
  ref,
  ...props
}) {
  return (
    <>
      <Tooltip
        // options
        html={
          <div className="bg-white text-sm font-medium text-gray-600 antialiased">
            <p>{tip}</p>
          </div>
        }
        position="top"
        theme="light"
        arrow={true}
        trigger="mouseenter"
        offset={8}
        disabled={tip ? false : true}
        className={`${
          width === 'full'
            ? 'w-full'
            : width === 'full-auto'
            ? 'w-full md:max-w-min'
            : 'max-w-min'
        }`}
      >
        {href ? (
          <a ref={ref} target={target} href={href} disabled={disabled}>
            <div
              className={`group relative cursor-pointer ${
                size === 'small' ? 'px-2 py-2' : 'px-3 py-3'
              }   flex items-center justify-center overflow-visible antialiased ${
                width === 'full'
                  ? 'w-full'
                  : width === 'full-auto'
                  ? 'w-full md:max-w-min'
                  : 'max-w-min'
              } disabled:cursor-not-allowed disabled:text-gray-500 ${
                variant === 'secondary'
                  ? 'text-blue-500'
                  : variant === 'outline'
                  ? 'text-gray-600'
                  : variant === 'error-secondary'
                  ? 'text-red-500'
                  : variant === 'nav'
                  ? active
                    ? 'text-blue-500'
                    : 'text-gray-500'
                  : 'text-white'
              }`}
            >
              <div
                style={backgroundStyle}
                className={`absolute h-full w-full ${
                  size === 'small' ? 'rounded-xl' : 'rounded-2xl'
                } transform transition duration-200 group-hover:scale-x-[1.10] group-hover:scale-y-[1.10] group-active:scale-x-[1.05]  group-active:scale-y-[1.05] group-disabled:bg-gray-300 ${
                  color
                    ? color
                    : variant === 'primary'
                    ? 'bg-blue-500'
                    : variant === 'error'
                    ? 'bg-red-500'
                    : variant === 'error-secondary'
                    ? 'bg-red-500 bg-opacity-10 group-hover:bg-opacity-20'
                    : variant === 'secondary'
                    ? 'bg-blue-500 bg-opacity-10 group-hover:bg-opacity-20'
                    : variant === 'nav'
                    ? active
                      ? 'bg-blue-500 bg-opacity-10 text-blue-500 group-hover:bg-opacity-20'
                      : 'bg-white text-gray-500 group-hover:bg-gray-100'
                    : variant === 'outline'
                    ? 'bg-gray-200'
                    : variant === 'create'
                    ? 'bg-gradient-to-br from-[#C242FF] to-[#FF5D40]'
                    : 'bg-gray-900'
                }`}
              ></div>
              <div
                style={textStyle}
                className={`relative z-10 flex flex-row items-center justify-center space-x-3  ${customText} `}
              >
                {prefix ? prefix : null}
              </div>

              {tooltip ? (
                <div
                  style={{ boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.05)' }}
                  className={
                    ' invisible absolute top-1/2 left-1/2 ml-4 -translate-y-1/2 transform rounded-xl bg-gray-900 py-1 px-2 font-sans tracking-tight text-white opacity-0 transition-all duration-200 group-hover:visible group-hover:translate-x-4 group-hover:opacity-100'
                  }
                >
                  <p className={'whitespace-nowrap font-medium'}>{tooltip}</p>
                </div>
              ) : null}
            </div>
          </a>
        ) : useDiv ? (
          <div
            className={`group relative z-10 cursor-pointer focus:outline-none ${
              size === 'small' ? 'px-2 py-2' : 'px-3 py-3'
            }   flex items-center justify-center overflow-visible antialiased ${
              width === 'full'
                ? 'w-full'
                : width === 'full-auto'
                ? 'w-full md:max-w-min'
                : 'max-w-min'
            } disabled:cursor-not-allowed disabled:text-gray-500 ${
              variant === 'secondary'
                ? 'text-blue-500'
                : variant === 'nav'
                ? active
                  ? 'text-blue-500'
                  : 'text-gray-500'
                : variant === 'outline'
                ? 'text-gray-600'
                : variant === 'error-secondary'
                ? 'text-red-500'
                : 'text-white'
            }`}
          >
            <div
              style={backgroundStyle}
              className={`absolute h-full w-full ${
                size === 'small' ? 'rounded-xl' : 'rounded-2xl'
              } transform transition duration-200 group-hover:scale-x-[1.10] group-hover:scale-y-[1.10] group-active:scale-x-[1.05]  group-active:scale-y-[1.05] group-disabled:bg-gray-300 ${
                color
                  ? color
                  : variant === 'primary'
                  ? 'bg-blue-500'
                  : variant === 'error'
                  ? 'bg-red-500'
                  : variant === 'error-secondary'
                  ? 'bg-red-500 bg-opacity-10 group-hover:bg-opacity-20'
                  : variant === 'secondary'
                  ? 'bg-blue-500 bg-opacity-10 group-hover:bg-opacity-20'
                  : variant === 'outline'
                  ? 'bg-gray-200'
                  : variant === 'nav'
                  ? active
                    ? 'bg-blue-500 bg-opacity-10 group-hover:bg-opacity-20'
                    : 'bg-white group-hover:bg-gray-100'
                  : variant === 'create'
                  ? 'bg-gradient-to-br from-[#C242FF] to-[#FF5D40]'
                  : 'bg-gray-900'
              }`}
            ></div>
            <div
              style={textStyle}
              className={`relative z-10 flex flex-row items-center justify-center space-x-3  ${customText} `}
            >
              {prefix ? prefix : null}
            </div>

            {ping > 0 ? (
              <div
                className={
                  'absolute top-[-4px] left-[-4px] flex h-4 w-4 items-center justify-center rounded-full border-2 border-white bg-red-500 text-xs font-bold text-white transition-all duration-200 group-hover:bg-blue-500 lg:top-[-8px] lg:left-[-8px] lg:h-6 lg:w-6 lg:border-4'
                }
              >
                {/* {ping} */}
              </div>
            ) : null}

            {tooltip ? (
              <div
                style={{ boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.05)' }}
                className={
                  ' invisible absolute top-1/2 left-1/2 ml-4 -translate-y-1/2 transform rounded-xl bg-gray-900 py-1 px-2 font-sans tracking-tight text-white opacity-0 transition-all duration-200 group-hover:visible group-hover:translate-x-4 group-hover:opacity-100'
                }
              >
                <p className={'whitespace-nowrap font-medium'}>{tooltip}</p>
              </div>
            ) : null}
          </div>
        ) : (
          <button
            ref={ref}
            onClick={click}
            disabled={disabled}
            className={`group relative z-10 cursor-pointer focus:outline-none ${
              size === 'small' ? 'px-2 py-2' : 'px-3 py-3'
            }   flex items-center justify-center overflow-visible antialiased ${
              width === 'full'
                ? 'w-full'
                : width === 'full-auto'
                ? 'w-full md:max-w-min'
                : 'max-w-min'
            } disabled:cursor-not-allowed disabled:text-gray-500 ${
              variant === 'secondary'
                ? 'text-blue-500'
                : variant === 'nav'
                ? active
                  ? 'text-blue-500'
                  : 'text-gray-500'
                : variant === 'outline'
                ? 'text-gray-600'
                : variant === 'error-secondary'
                ? 'text-red-500'
                : 'text-white'
            }`}
          >
            <div
              style={backgroundStyle}
              className={`absolute h-full w-full ${
                size === 'small' ? 'rounded-xl' : 'rounded-2xl'
              } transform transition duration-200 group-hover:scale-x-[1.10] group-hover:scale-y-[1.10] group-active:scale-x-[1.05]  group-active:scale-y-[1.05] group-disabled:bg-gray-300 ${
                color
                  ? color
                  : variant === 'primary'
                  ? 'bg-blue-500'
                  : variant === 'error'
                  ? 'bg-red-500'
                  : variant === 'error-secondary'
                  ? 'bg-red-500 bg-opacity-10 group-hover:bg-opacity-20'
                  : variant === 'secondary'
                  ? 'bg-blue-500 bg-opacity-10 group-hover:bg-opacity-20'
                  : variant === 'outline'
                  ? 'bg-gray-200'
                  : variant === 'nav'
                  ? active
                    ? 'bg-blue-500 bg-opacity-10 group-hover:bg-opacity-20'
                    : 'bg-white group-hover:bg-gray-100'
                  : variant === 'create'
                  ? 'bg-gradient-to-br from-[#C242FF] to-[#FF5D40]'
                  : 'bg-gray-900'
              }`}
            ></div>
            <div
              style={textStyle}
              className={`relative z-10 flex flex-row items-center justify-center space-x-3  ${customText} `}
            >
              {prefix ? prefix : null}
            </div>

            {ping > 0 ? (
              <div
                className={
                  'absolute top-[-4px] left-[-4px] flex h-4 w-4 items-center justify-center rounded-full border-2 border-white bg-red-500 text-xs font-bold text-white transition-all duration-200 group-hover:bg-blue-500 lg:top-[-8px] lg:left-[-8px] lg:h-6 lg:w-6 lg:border-4'
                }
              >
                {/* {ping} */}
              </div>
            ) : null}

            {tooltip ? (
              <div
                style={{ boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.05)' }}
                className={
                  ' invisible absolute top-1/2 left-1/2 ml-4 -translate-y-1/2 transform rounded-xl bg-gray-900 py-1 px-2 font-sans tracking-tight text-white opacity-0 transition-all duration-200 group-hover:visible group-hover:translate-x-4 group-hover:opacity-100'
                }
              >
                <p className={'whitespace-nowrap font-medium'}>{tooltip}</p>
              </div>
            ) : null}
          </button>
        )}
      </Tooltip>
    </>
  );
}
