import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

// this client is used for
export const client = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

client.interceptors.request.use(function (config) {
  config.headers = config.headers || {};
  // bypass any more processing if authorization header already set.
  if (config.headers.Authorization) {
    return config;
  }

  const auth = firebase.auth();
  if (auth.currentUser) {
    return auth.currentUser
      .getIdToken()
      .then((token) => {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      })
      .catch((err) => {
        console.error(`failed to fetch ID token`, err);

        // most likely result is the API rejects the request.
        return config;
      });
  }
  return config;
});
