/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, XCircleIcon } from '@heroicons/react/outline';
import { ExclamationCircleIcon } from '@heroicons/react/outline';

export default function ConfirmUnlinkTwitter({
  open,
  setOpen,
  deleteConfirmed,
  keep,
}) {
  const cancelButtonRef = useRef();

  return (
    <Transition.Root
      initialFocus={cancelButtonRef}
      static
      show={open}
      as={Fragment}
    >
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-40 overflow-y-auto"
        open={open}
        onClose={setOpen}
      >
        <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center font-sans antialiased sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block w-full transform overflow-hidden rounded-2xl bg-white p-8 text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-lg sm:align-middle">
              <button
                onClick={() => setOpen(false)}
                ref={cancelButtonRef}
                className={
                  'absolute top-2 right-2 transform rounded-full border border-gray-200 bg-gray-100 p-1.5 text-gray-700 outline-none duration-200 hover:scale-105 hover:text-gray-900 hover:outline-none focus:outline-none active:scale-100'
                }
              >
                <svg className={'h-5 w-5'} fill="none" viewBox="0 0 24 24">
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M17.25 6.75L6.75 17.25"
                  ></path>
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M6.75 6.75L17.25 17.25"
                  ></path>
                </svg>
              </button>
              <div className={'flex flex-col items-center justify-start'}>
                {/* <p className="text-sm font-sans font-semibold uppercase text-center tracking-tight text-gray-400">
                  New
                </p> */}

                <div
                  className={
                    'rounded-full bg-red-500 bg-opacity-20 p-3 text-red-500'
                  }
                >
                  <XCircleIcon className={'h-10 w-10'} />
                </div>

                <Dialog.Title
                  as="h3"
                  className="mx-auto mt-4 max-w-sm text-center font-primary text-2xl font-bold leading-8 text-gray-900"
                >
                  Are you sure you want to disconnect Twitter?
                </Dialog.Title>

                <div className="mt-8 flex w-full flex-row space-x-2">
                  <button
                    onClick={() => keep()}
                    type="button"
                    className="inline-flex w-full transform justify-center rounded-xl border border-transparent bg-indigo-500 py-2 text-base font-semibold text-white shadow-sm duration-200 hover:scale-105 focus:outline-none  focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 active:scale-100 sm:text-base"
                  >
                    {'Stay Connected'}
                  </button>
                  <button
                    onClick={() => deleteConfirmed()}
                    type="button"
                    className="inline-flex w-full transform justify-center rounded-xl border border-transparent bg-red-500 py-2 text-base font-semibold text-white shadow-sm duration-200 hover:scale-105 focus:outline-none  focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 active:scale-100 sm:text-base"
                  >
                    {'Disconnect'}
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
