import { useEffect, useMemo } from 'react';
import mixpanel from 'mixpanel-browser';
import firebase from 'firebase/app';
import 'firebase/auth';
import { getActivePlan } from './helpers';

const enabled = !!process.env.NEXT_PUBLIC_MIXPANEL_TOKEN;

if (enabled) {
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
    debug: process.env.NEXT_PUBLIC_APP_ENV !== 'production',
  });
}

const isDomNode = (ev: EventTarget): ev is HTMLElement =>
  ev instanceof HTMLElement;

export const useAnalytics = () =>
  useMemo(
    () => ({
      /**
       * Track an event
       *
       * Event names should be static to facilitate reporting; all dynamic properties
       * should be places in the attrs.
       */
      trackEvent: (
        eventName: string,
        attrs: Record<string, unknown> = {},
      ): void => {
        enabled && mixpanel.track(eventName, attrs);
      },
      /**
       * Identifies the currently authenticated user.
       *
       * If events were recorded prior to authentication,
       * they will be linked up to the authenticated user
       */
      identify: (uid: string): void => {
        enabled && mixpanel.identify(uid);
      },
      /**
       * Add an attribute to the user.
       */
      setUserAttributes: (
        attrs: Record<
          string,
          string | number | Date | string[] | number[] | Date[]
        >,
      ): void => {
        enabled && mixpanel.people.set(attrs);
      },
      /**
       * Add an attribute to the user only if it doesn't already exist.
       */
      setUserAttributesOnce: (
        attrs: Record<
          string,
          string | number | Date | string[] | number[] | Date[]
        >,
      ): void => {
        enabled && mixpanel.people.set_once(attrs);
      },
      /**
       * For numeric properties, increment the value by the provided value
       */
      incrementUserAttribute: (attrs: Record<string, number>): void => {
        enabled && mixpanel.people.increment(attrs);
      },
      /**
       * For list properties, append values.
       *
       * Note that values are not deduplicated. Use `unionUserAttribute` if values should be unique
       */
      appendUserAttribute: (
        attrs: Record<string, string | number | Date>,
      ): void => {
        enabled && mixpanel.people.append(attrs);
      },
      /**
       * For list properties, insert and deduplicate values.
       */
      unionUserAttribute: (
        attrs: Record<string, string | number | Date>,
      ): void => {
        enabled && mixpanel.people.union(attrs);
      },

      /**
       * For list properties, insert and deduplicate values.
       */
      unsetUserAttributes: (attrs: string | string[]): void => {
        enabled && mixpanel.people.unset(attrs);
      },

      checkUserProfile: (): void => {
        if (enabled) {
          var name = mixpanel.get_property('Username');
          console.log('Name here', name);
        }
      },

      removeUserAttributes: (
        list_name: string,
        value: Record<string, string | number | Date>,
      ): void => {
        enabled && mixpanel.people.remove(list_name, value);
      },
    }),
    [],
  );

export const useInitAnalytics = () => {
  const analytics = useAnalytics();
  useEffect(() => {
    enabled &&
      firebase.auth().onAuthStateChanged(function (userInfo) {
        if (userInfo) {
          analytics.identify(userInfo.uid);
        } else {
          //logged out
          mixpanel.reset();
        }
      });

    const processNodeTree = (element?: HTMLElement) => {
      if (!element || element === document.body) {
        return;
      }

      // the element should be tracked
      const track = element.dataset.track;
      if (track) {
        const attrs = element.dataset.trackAttrs;
        analytics.trackEvent(track, attrs ? JSON.parse(attrs) : {});
        return;
      }

      if (element.parentElement) {
        processNodeTree(element.parentElement);
      }
    };
    const listener = (event: MouseEvent): void => {
      isDomNode(event.target) && processNodeTree(event.target);
    };
    window.addEventListener('click', listener, {
      capture: true, // trigger before other listeners
      passive: true, // will never preventDefault on event
    });

    return () => window.removeEventListener('click', listener);
  }, [analytics]);

  return analytics;
};
