/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  CheckIcon,
  InformationCircleIcon,
  PhotographIcon,
} from '@heroicons/react/outline';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import toast, { Toaster } from 'react-hot-toast';
import { RestrictedUsernames } from '../RestrictedUsernames';
import SpinnerIcon from '../Icons/spinner';
import { useRouter } from 'next/router';
import TwitterBirdIcon from '../Icons/twitter';
import Button from '../Button';
import { cio } from '../../utils/customer-io';
import OnboardingPopover from '../OnboardingPopover';

export default function EditProfile({
  isOpen,
  setIsOpen,
  user,
  setIsReady,
  isGalleryExist,
  gallerySlug,
  match = true,
  tour = false,
  isPopoverOpen = false,
  setIsPopoverOpen = null,
  closeTour = null,
  tourDone = null,
}) {
  const router = useRouter();

  const [errorMsg, setErrorMsg] = useState('');
  const [username, setUsername] = useState('');
  const [newUsername, setNewUsername] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [galleryPhoto, setGalleryPhoto] = useState('');
  const [twitterProfile, setTwitterProfile] = useState('');
  const [newPhotoURL, setNewPhotoURL] = useState('');
  const [newCoverURL, setNewCoverURL] = useState('');
  const [bio, setBio] = useState('Welcome to my Social Blog');

  const [successMSG, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [userHasGallery, setUserHasGallery] = useState(false);
  const [showUsernameInfo, setShowUsernameInfo] = useState(false);

  const [msg, setMSG] = useState('');
  const [msgType, setMSGType] = useState('warning');

  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    setButtonLoading(false);
  }, [isOpen]);

  useEffect(() => {}, [newUsername]);

  useEffect(() => {
    if (gallerySlug === '') {
      setDisplayName('');
      setUsername('');
      setTwitterProfile('');
      setBio('Welcome to my Social Blog');
      setUserHasGallery('');
      setSuccess(false);
      setError(false);
      setErrorMsg('');
      if (user) {
        const newImage = user.photoURL;
        if (user?.photoURL?.includes('googleusercontent')) {
          setNewPhotoURL(newImage.replace('=s96-c', ''));
        } else if (user?.photoURL?.includes('twimg')) {
          setNewPhotoURL(newImage.replace('_normal', ''));
        } else {
          setNewPhotoURL(newImage);
        }
      }
    } else {
      if (user) {
        getPageMeta();
      }
    }
  }, [gallerySlug, user]);

  const success = (text) =>
    toast.success(text, {
      id: 'success',
      /* icon: '🪄', */
      style: {
        borderRadius: '8px',
        background: '#fff',
        color: '#181818',
      },
    });

  useEffect(() => {
    if (username.length > 0) {
      if (username === newUsername) {
        setShowUsernameInfo(false);
      } else {
        setShowUsernameInfo(true);
        setMSGType('warning');
        setMSG('Your old username will not redirect to your new one');
      }
    }
  }, [newUsername]);

  function getPageMeta() {
    var ref = firebase
      .database()
      .ref('gallery/' + gallerySlug.toLocaleLowerCase() + '/' + user.uid)
      .child('meta');
    ref.once(
      'value',
      function (snapshot) {
        //this is display name not .exists() to make sure it is formatted correctly
        if (snapshot.hasChild('displayName')) {
          //user has new meta DB structure
          //meta is formatted properly
          setUserHasGallery(true);
          setDisplayName(snapshot.val().displayName);
          setBio(snapshot.val().bio);
          setTwitterProfile(snapshot.val().twitterURL);

          setUsername(snapshot.val().slug);
          setNewUsername(snapshot.val().slug);

          snapshot.hasChild('coverImage')
            ? setNewCoverURL(snapshot.val().coverImage)
            : null;

          const newImage = snapshot.val().image;

          if (snapshot.val().image.includes('googleusercontent')) {
            setGalleryPhoto(newImage.replace('=s96-c', ''));
            setNewPhotoURL(newImage.replace('=s96-c', ''));
          } else if (snapshot.val().image.includes('twimg')) {
            setGalleryPhoto(newImage.replace('_normal', ''));
            setNewPhotoURL(newImage.replace('_normal', ''));
          } else {
            setGalleryPhoto(snapshot.val().image);
            setNewPhotoURL(newImage.replace('_normal', ''));
          }
        } else {
          var ref2 = firebase
            .database()
            .ref('gallery/' + gallerySlug.toLocaleLowerCase() + '/' + user.uid);
          ref2.once('value', function (snap) {
            if (snap.exists()) {
              setUserHasGallery(true);
              setDisplayName(snap.val().displayName);
              setBio(snap.val().bio);
              setTwitterProfile(snap.val().twitterURL);

              setUsername(snap.val().slug);
              setNewUsername(snap.val().slug);

              snapshot.hasChild('coverImage')
                ? setNewCoverURL(snapshot.val().coverImage)
                : null;

              const newImage = snap.val().image;
              if (snap.val().image.includes('googleusercontent')) {
                setGalleryPhoto(newImage.replace('=s96-c', ''));
                setNewPhotoURL(newImage.replace('=s96-c', ''));
              } else if (snap.val().image.includes('twimg')) {
                setGalleryPhoto(newImage.replace('_normal', ''));
                setNewPhotoURL(newImage.replace('_normal', ''));
              } else {
                setGalleryPhoto(snap.val().image);
                setNewPhotoURL(newImage.replace('_normal', ''));
              }

              ref.update({
                displayName: snap.val().displayName,
                image: snap.val().image,
                slug: snap.val().slug,
                twitterURL: snap.val().twitterURL,
                bio: snap.val().bio,
              });
            } else {
              setUserHasGallery(false);
            }
          });
        }
      },
      function (errorObject) {},
    );

    updatePathsSlug(user.uid, gallerySlug.toLocaleLowerCase());
  }

  var db = firebase.database();

  const cancelButtonRef = useRef();

  const updatePathsSlug = (userID, newSlug) => {
    db.ref('paths/blogs').child(userID).update({
      slug: newSlug,
    });
    db.ref('paths/collections').child(userID).update({
      slug: newSlug,
    });

    db.ref('paths/posts').child(userID).update({
      slug: newSlug,
    });
  };

  const createGallery = () => {
    setButtonLoading(true);

    if (newUsername === '' || displayName === '' || bio === '') {
      setMSGType('error');
      setShowUsernameInfo(true);
      newUsername === '' ? setMSG('Username cannot be blank') : null;
      displayName === '' ? setMSG('Name cannot be blank') : null;
      bio === '' ? setMSG('Bio cannot be blank') : null;
      setButtonLoading(false);
    } else {
      const dbRef = firebase.database().ref();

      if (gallerySlug === '') {
        dbRef
          .child('gallery')
          .child(newUsername.toLocaleLowerCase().replace(/[^0-9a-z]/gi, ''))
          .once('value')
          .then((snapshot) => {
            if (snapshot.exists()) {
              setMSG('Username is taken');
              setMSGType('error');
              setErrorMsg('Username already taken');
              setError(true);
              setSuccess(false);
              setButtonLoading(false);
            } else {
              db.ref(
                'gallery/' +
                  newUsername.toLocaleLowerCase().replace(/[^0-9a-z]/gi, '') +
                  '/' +
                  user.uid,
              )
                .child('meta')
                .update({
                  displayName: displayName,
                  image: newPhotoURL,
                  coverImage: newCoverURL,
                  twitterURL: twitterProfile,
                  bio: bio,
                  slug: newUsername
                    .toLocaleLowerCase()
                    .replace(/[^0-9a-z]/gi, ''),
                })
                .then(() => {
                  db.ref('users/' + user.uid + '/gallery')
                    .set({
                      enabled: true,
                      username: newUsername
                        .toLocaleLowerCase()
                        .replace(/[^0-9a-z]/gi, ''),
                    })
                    .then(() => {
                      updatePathsSlug(user.uid, newUsername);
                      setIsReady(true);
                      tour && tourDone();
                      success('Gallery Created');
                      setError(false);
                      setErrorMsg('Gallery Created!');
                      setUserHasGallery(true);
                      setButtonLoading(false);
                    });
                })
                .catch((error) => {});
            }
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        if (username === newUsername) {
          dbRef
            .child('gallery')
            .child(username.toLocaleLowerCase())
            .once('value')
            .then((snapshot) => {
              db.ref('gallery/' + username.toLocaleLowerCase() + '/' + user.uid)
                .child('meta')
                .update({
                  displayName: displayName,
                  image: newPhotoURL,
                  coverImage: newCoverURL,
                  twitterURL: twitterProfile,
                  bio: bio,
                  slug: username.toLocaleLowerCase(),
                })
                .then(() => {
                  updateCustomerIO(user.uid, displayName);

                  updatePathsSlug(user.uid, newUsername);
                  tour && tourDone();
                  success('Profile updated');
                  setIsReady(true);
                  setButtonLoading(false);

                  setError(false);
                  setShowUsernameInfo(false);
                })
                .catch((error) => {});
            });
        } else {
          //Check if username exists
          //Get all user gallery info
          //Create a new gallery with new username
          //Insert old data into new gallery
          //delete old gallery

          //check if restricted

          if (
            RestrictedUsernames.includes(
              newUsername.toLocaleLowerCase().replace(/[^0-9a-z]/gi, ''),
            )
          ) {
            setMSG('That username is not allowed');
            setMSGType('error');
            setButtonLoading(false);
          } else {
            var galleryRef = db
              .ref('gallery')
              .child(
                newUsername.toLocaleLowerCase().replace(/[^0-9a-z]/gi, ''),
              );
            galleryRef.once('value', function (snapshot2) {
              if (snapshot2.exists()) {
                //username is taken
                setMSG('Username is taken');
                setMSGType('error');
                setButtonLoading(false);
              } else {
                //update old gallery with new data before movinf and deleting
                db.ref(
                  'gallery/' + username.toLocaleLowerCase() + '/' + user.uid,
                )
                  .child('meta')
                  .update({
                    displayName: displayName,
                    image: newPhotoURL,
                    coverImage: newCoverURL,
                    twitterURL: twitterProfile,
                    bio: bio,
                    slug: username.toLocaleLowerCase(),
                  })
                  .then(() => {
                    //get old snapshot
                    dbRef
                      .child('gallery')
                      .child(username.toLocaleLowerCase())
                      .child(user.uid)
                      .once('value')
                      .then((snapshot) => {
                        db.ref(
                          'gallery/' +
                            newUsername
                              .toLocaleLowerCase()
                              .replace(/[^0-9a-z]/gi, '') +
                            '/' +
                            user.uid,
                        )
                          .update(snapshot.val())
                          .then(() => {
                            db.ref(
                              'gallery/' +
                                newUsername
                                  .toLocaleLowerCase()
                                  .replace(/[^0-9a-z]/gi, '') +
                                '/' +
                                user.uid,
                            )
                              .child('meta')
                              .update({
                                slug: newUsername
                                  .toLocaleLowerCase()
                                  .replace(/[^0-9a-z]/gi, ''),
                              })
                              .then(() => {
                                //add new user slug to user DB
                                db.ref('users/' + user.uid + '/gallery')
                                  .set({
                                    enabled: true,
                                    username: newUsername
                                      .toLocaleLowerCase()
                                      .replace(/[^0-9a-z]/gi, ''),
                                  })
                                  .then(() => {
                                    const removeRef = db
                                      .ref('gallery')
                                      .child(username.toLocaleLowerCase())
                                      .child(user.uid);
                                    removeRef.remove().then(() => {
                                      tour && tourDone();
                                      updateCustomerIO(
                                        user.uid,
                                        displayName,
                                        newUsername
                                          .toLocaleLowerCase()
                                          .replace(/[^0-9a-z]/gi, ''),
                                      );

                                      setUsername(newUsername);
                                      //remove old gallery
                                      updatePathsSlug(user.uid, newUsername);
                                      success('Profile updated');
                                      setError(false);
                                      setErrorMsg('Gallery Created!');
                                      setUserHasGallery(true);
                                      setButtonLoading(false);
                                      if (router.pathname === '/[slug]') {
                                        router.push({
                                          pathname: '/[slug]',
                                          query: { slug: newUsername },
                                        });
                                      } else if (
                                        router.pathname ===
                                          '/[slug]/collections' ||
                                        router.pathname ===
                                          '/[slug]/collections/[collection]'
                                      ) {
                                        router.push({
                                          pathname: '/[slug]/collections',
                                          query: { slug: newUsername },
                                        });
                                      } else {
                                        setIsReady(true);
                                        //success("Profile updated")
                                      }
                                    });
                                  });
                              });
                          })
                          .catch((error) => {});
                      });
                  });
              }
            });
          }
        }
      }
    }
  };

  const uploadCover = (event) => {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > 2097152) {
        setShowUsernameInfo(true);
        setMSGType('error');
        setMSG('Maximum image size is 2MB');
      } else {
        let img = event.target.files[0];
        document.getElementById('cover-upload').value = '';
        addCoverToUserDB(img);
      }
    }
  };

  const addCoverToUserDB = (image) => {
    var storageRef = firebase.storage().ref();
    var upload = storageRef.child(user.uid).child('cover-photo').put(image);

    upload.then((snapshot) => {
      var storageRef2 = firebase.storage().ref();
      var imgRef = storageRef2.child(user.uid).child('cover-photo');

      imgRef.getDownloadURL().then((url) => {
        setNewCoverURL(url);
      });
    });
  };

  const uploadImage = (event) => {
    if (event.target.files && event.target.files[0]) {
      /*          const name = event.target.files[0].name;
                     const lastDot = name.lastIndexOf('.');
         
                     const fileName = name.substring(0, lastDot).replace(/\s/g, "-");
                     const ext = name.substring(lastDot + 1);
         
                     
         
          */
      if (event.target.files[0].size > 2097152) {
        setShowUsernameInfo(true);
        setMSGType('error');
        setMSG('Maximum image size is 2MB');
      } else {
        let img = event.target.files[0];
        document.getElementById('image-upload').value = '';
        addImageToUserDB(img);
      }
    }
  };

  const addImageToUserDB = (image) => {
    var storageRef = firebase.storage().ref();
    var upload = storageRef.child(user.uid).child('profile-photo').put(image);

    upload.then((snapshot) => {
      var storageRef2 = firebase.storage().ref();
      var imgRef = storageRef2.child(user.uid).child('profile-photo');

      imgRef.getDownloadURL().then((url) => {
        setNewPhotoURL(url);
      });
    });
  };

  const updateCustomerIO = async (
    userID,
    displayName = null,
    newSlug = null,
  ) => {
    const exists = await cio.fetchCustomer(userID);

    if (exists?.exists) {
      if (displayName && newSlug) {
        cio.updateCustomer(userID, {
          name: displayName,
          username: newSlug,
          url: 'https://typeshare.co/' + newSlug,
        });
      } else if (newSlug) {
        cio.updateCustomer(userID, {
          username: newSlug,
          url: 'https://typeshare.co/' + newSlug,
        });
      } else if (displayName) {
        cio.updateCustomer(userID, {
          name: displayName,
        });
      } else {
        //nothing to update
      }
    } else {
      //do nothing
      //let app.js handle this
    }
  };

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          initialFocus={cancelButtonRef}
          className="fixed inset-0 z-50 overflow-y-auto"
          open={isOpen}
          onClose={() => (tour ? null : setIsOpen(false))}
        >
          <div className="flex min-h-screen items-center justify-center px-2 pt-4 pb-20 text-center antialiased sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-900 bg-opacity-50" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-24 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-24 sm:scale-95"
            >
              <div className="relative inline-block w-full transform overflow-hidden rounded-2xl bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:max-w-md sm:align-middle">
                <button
                  onClick={() => setIsOpen(false)}
                  ref={cancelButtonRef}
                  className={
                    'absolute top-2 right-2 transform rounded-full border border-gray-200 bg-gray-100 p-1.5 text-gray-700 outline-none duration-200 hover:scale-105 hover:text-gray-900 hover:outline-none focus:outline-none active:scale-100'
                  }
                >
                  <svg className={'h-5 w-5'} fill="none" viewBox="0 0 24 24">
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M17.25 6.75L6.75 17.25"
                    ></path>
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M6.75 6.75L17.25 17.25"
                    ></path>
                  </svg>
                </button>

                <OnboardingPopover
                  show={tour}
                  step={0}
                  totalSteps={8}
                  title={
                    "Go ahead and make any changes you'd like to make to your Social Blog."
                  }
                  placement={'left'}
                  type={'tip'}
                  isOpen={tour}
                  setIsOpen={setIsPopoverOpen}
                  next={() => createGallery()}
                  nextText={'Save & Continue'}
                  close={() => closeTour()}
                  round={true}
                >
                  <div
                    className={` ${
                      tour && 'rounded-2xl bg-white'
                    } flex flex-col items-start justify-center`}
                  >
                    <div className={'px-8 pt-8 pb-4'}>
                      <Dialog.Title
                        as="h3"
                        className="text-left font-sans text-2xl font-semibold tracking-tight text-gray-900"
                      >
                        {'Edit Social Blog'}
                      </Dialog.Title>
                      <p className="w-full text-left text-xs leading-snug text-gray-600">
                        This information will appear on your public Social Blog.
                        You can change this anytime.
                      </p>
                    </div>

                    <div className={'flex w-full flex-col p-8 pt-0'}>
                      <label
                        htmlFor={'cover-upload'}
                        className={' relative mx-auto h-24 w-full rounded-2xl'}
                      >
                        {newCoverURL ? (
                          <img
                            src={newCoverURL}
                            className={'h-24 w-full rounded-2xl object-cover'}
                          />
                        ) : (
                          <img
                            src={'/default-header.png'}
                            className={'h-40 w-full rounded-2xl object-cover'}
                          />
                        )}

                        <div
                          className={
                            'group absolute top-0 mx-auto flex h-40 w-full items-center justify-center rounded-2xl bg-gray-500 bg-opacity-0 transition duration-200 hover:bg-opacity-75'
                          }
                        >
                          <PhotographIcon
                            className={
                              'hidden h-6 w-6 text-white group-hover:flex'
                            }
                          />
                        </div>

                        <input
                          id={'cover-upload'}
                          name={'cover-upload'}
                          type="file"
                          className="sr-only"
                          accept="image/*"
                          onChange={(event) => uploadCover(event)}
                        />
                      </label>

                      <div className="mr-auto ml-4 mt-8 mb-4">
                        <label
                          htmlFor={'image-upload'}
                          className={' relative h-16 w-16 rounded-full '}
                        >
                          <img
                            className={
                              'h-16 w-16 rounded-full border-4 border-white'
                            }
                            src={newPhotoURL}
                          />
                          <div
                            className={`group flex h-16 w-16 items-center justify-center rounded-full ${
                              tour ? '-top-16' : 'top-0'
                            } absolute mx-auto border-4 border-white bg-gray-500 bg-opacity-0 transition duration-200 hover:bg-opacity-75`}
                          >
                            <PhotographIcon
                              className={
                                'hidden h-4 w-4 text-white group-hover:flex'
                              }
                            />
                          </div>

                          <input
                            id={'image-upload'}
                            name={'image-upload'}
                            type="file"
                            className="sr-only"
                            accept="image/*"
                            onChange={(event) => uploadImage(event)}
                          />
                        </label>
                      </div>

                      <div className={'mb-3 flex w-full flex-col space-y-1 '}>
                        <p
                          className={
                            'font-sans text-sm font-semibold text-gray-900'
                          }
                        >
                          Name
                        </p>
                        <div className="mt-1 w-full">
                          <input
                            value={displayName}
                            onChange={(event) =>
                              setDisplayName(event.target.value)
                            }
                            type="text"
                            placeholder="Jane Doe"
                            name="name"
                            id="name"
                            className={
                              'w-full rounded-lg border border-gray-100 bg-white p-2 text-sm font-medium transition duration-200 focus:border-gray-900 focus:outline-none focus:ring-0'
                            }
                          />
                        </div>
                      </div>
                      <div className={'mb-3 flex w-full flex-col space-y-1 '}>
                        <p
                          className={
                            'font-sans text-sm font-semibold text-gray-900'
                          }
                        >
                          Username
                        </p>
                        <div
                          className={
                            'mt-1 flex w-full  flex-row rounded-lg border border-gray-100 text-sm transition duration-200 focus-within:border-gray-900 focus-within:outline-none focus-within:ring-0'
                          }
                        >
                          <span className="inline-flex items-center rounded-l-md border-r border-gray-200 bg-gray-100 px-2 font-sans text-xs font-medium text-gray-600">
                            typeshare.co/
                          </span>
                          <input
                            value={newUsername}
                            onChange={(event) =>
                              setNewUsername(
                                event.target.value.replace(/[^0-9a-z]/gi, ''),
                              )
                            }
                            type="text"
                            name="company_website"
                            id="company_website"
                            className={
                              'w-full overflow-hidden rounded-r-lg border-none p-2 text-sm font-medium outline-none ring-0 focus:outline-none focus:ring-0'
                            }
                            placeholder="username"
                          />
                        </div>
                      </div>

                      {/* <div className={'flex flex-col space-y-1 w-full mb-6'}>
                                        <p className={"text-lg font-primary text-gray-900 font-semibold"}>Twitter Handle</p>
                                        
                                        {!twitterProfile ? <Button variant={"primary"} width={"full"} prefix={<TwitterBirdIcon className={"w-5 h-5 "} />} >Connect Twitter</Button> :
                                        <div className={"flex flex-row text-sm  mt-1 border-gray-200 border-2 focus-within:outline-none focus-within:ring-0 focus-within:border-gray-900 transition duration-200 rounded-lg w-full"}>
                                            <span className="inline-flex font-primary items-center px-3 rounded-l-md border-r border-gray-200 bg-gray-100 text-gray-600 font-medium">
                                                @
                                            </span>
                                            <input
                                                value={twitterProfile}
                                                onChange={(event) => setTwitterProfile(event.target.value)}
                                                type="text"
                                                disabled={true}
                                                name="company_website"
                                                id="company_website"
                                                className={"p-3 font-medium w-full focus:outline-none outline-none focus:ring-0 ring-0 border-none overflow-hidden rounded-r-lg"}
                                                placeholder="username"
                                            />
                                        </div> }

                                    </div> */}

                      <div className={'flex w-full flex-col space-y-1 '}>
                        <p
                          className={
                            'font-sans text-sm font-semibold text-gray-900'
                          }
                        >
                          Bio
                        </p>
                        <div className="mt-1 w-full">
                          <textarea
                            value={bio}
                            onChange={(event) => setBio(event.target.value)}
                            id="bio"
                            name="bio"
                            rows={3}
                            maxLength={160}
                            className={
                              'w-full resize-none rounded-lg border border-gray-200 bg-white p-2 text-sm font-medium transition duration-200 focus:border-gray-900 focus:outline-none focus:ring-0'
                            }
                          />
                        </div>
                      </div>

                      <div className={'flex w-full flex-col space-y-2'}>
                        <div className="w-full rounded-lg bg-white">
                          <div
                            className={`flex w-full flex-row items-center space-x-2 rounded-lg p-2 ${
                              msgType === 'error'
                                ? 'bg-red-500'
                                : 'bg-yellow-500'
                            }  transform bg-opacity-10 transition-all duration-200 ${
                              showUsernameInfo ? 'flex' : 'hidden '
                            }`}
                          >
                            <InformationCircleIcon
                              className={`${
                                msgType === 'error'
                                  ? 'text-red-500'
                                  : 'text-yellow-500'
                              }  h-6 w-6`}
                            />
                            <p
                              className={`${
                                msgType === 'error'
                                  ? 'text-red-500'
                                  : 'text-yellow-500'
                              }  text-sm font-medium`}
                            >
                              {msg}
                            </p>
                          </div>
                        </div>

                        <Button
                          loading={buttonLoading}
                          disabled={buttonLoading}
                          click={() => createGallery()}
                          size={'mini'}
                          width={'full'}
                        >
                          {userHasGallery
                            ? tour
                              ? 'Save & Continue'
                              : 'Save'
                            : 'Create Social Blog'}
                        </Button>
                      </div>
                    </div>
                  </div>
                </OnboardingPopover>
              </div>

              {/* <div className="inline-block relative align-bottom bg-white rounded-2xl pt-5 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-md sm:w-full">

                            <div className="mt-6 mb-6 sm:mt-5 flex flex-col justify-center items-center space-y-6">
                                <div className=" flex flex-col space-y-4 w-full">

                                    <div className={"flex flex-col"}>



                                        <label
                                            htmlFor={`image-upload`}
                                            style={{ boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.05)" }} className={" relative mx-auto mb-6 rounded-full w-24 h-24 "}
                                        >

                                            <img className={'w-full h-full rounded-full'} src={newPhotoURL} />
                                            <div className={"w-24 h-24 rounded-full flex items-center justify-center group top-0 mx-auto absolute bg-gray-500 hover:bg-opacity-75 bg-opacity-0 transition duration-200"}>
                                                <PhotographIcon className={"w-6 h-6 text-white group-hover:flex hidden"} />

                                            </div>


                                            <input id={`image-upload`} name={`image-upload`} type="file" className="sr-only" accept="image/*" onChange={(event) => uploadImage(event)} />
                                        </label>


                                        <div className={"flex flex-col space-y-4 px-6 mb-6"}>
                                            <div>
                                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                    Username
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                                        typeshare.co/g/
                                                    </span>
                                                    <input
                                                       
                                                        value={newUsername}
                                                        onChange={(event) => setNewUsername(event.target.value.replace(/[^0-9a-z]/gi, ''))}
                                                        type="text"
                                                        name="company_website"
                                                        id="company_website"
                                                        className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                                                        placeholder="username"
                                                    />
                                                </div>
                                            </div>

                                            <div>
                                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                    Name
                                                </label>
                                                <div className="mt-1">
                                                    <input
                                                        value={displayName}
                                                        onChange={(event) => setDisplayName(event.target.value)}
                                                        type="text"
                                                        name="name"
                                                        id="name"
                                                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                                        placeholder="Jane Doe"
                                                    />
                                                </div>
                                            </div>


                                            <div>
                                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                    Twitter Handle
                                                </label>
                                                <div className="mt-1 flex rounded-md shadow-sm">
                                                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                                                        @
                                                    </span>
                                                    <input
                                                        value={twitterProfile}
                                                        onChange={(event) => setTwitterProfile(event.target.value)}
                                                        type="text"
                                                        name="company_website"
                                                        id="company_website"
                                                        className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                                                        placeholder="samjshore"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                                    Bio
                                                </label>
                                                <div className="mt-1">
                                                    <textarea
                                                        value={bio}
                                                        onChange={(event) => setBio(event.target.value)}

                                                        id="bio"
                                                        name="bio"
                                                        rows={3}
                                                        className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
                                                    />
                                                </div>
                                            </div>
                                            <div className={`w-full flex flex-row space-x-2 items-center rounded-lg p-2 ${msgType === "error" ? "bg-red-500" : "bg-yellow-500"}  transform transition-all duration-200 bg-opacity-10 ${showUsernameInfo ? "flex" : "hidden "}`}>
                                                <InformationCircleIcon className={`${msgType === "error" ? "text-red-500" : "text-yellow-500"}  w-6 h-6`} />
                                                <p className={`${msgType === "error" ? "text-red-500" : "text-yellow-500"}  text-sm font-medium`}>{msg}</p>
                                            </div>

                                        </div>
                                        <div className={'w-full px-6'}>
                                            <button onClick={() => createGallery()} className={`mt-2 w-full bg-indigo-500 rounded-full p-3 text-white text-base font-semibold antialiased hover:scale-105 transform duration-200 active:scale-100`}>
                                                {userHasGallery ? "Save" : "Create Gallery"}
                                            </button>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                     */}
            </Transition.Child>
          </div>
          <Toaster position="bottom-right" reverseOrder={false} />
        </Dialog>
      </Transition.Root>
    </>
  );
}
