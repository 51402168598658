import SpinnerIcon from '../Icons/spinner';
import { Switch } from '@headlessui/react';

import clsx from 'clsx';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
import { Tooltip } from 'react-tippy';

export default function Button({
  id = null,
  className = null,
  click = undefined,
  text = undefined,
  tip = undefined,
  tipType = 'default',
  toggleEnabled = undefined,
  tipCTAClick = null,
  tipCTA = null,
  setToggleEnabled = undefined,
  tipSub = undefined,
  variant = undefined,
  disabled = undefined,
  rounded = undefined,
  icon = undefined,
  mobile = undefined,
  width = undefined,
  loading = undefined,
  size = undefined,
  color = undefined,
  prefix = undefined,
  suffix = undefined,
  backgroundStyle = undefined,
  textStyle = undefined,
  customText = undefined,
  track = undefined,
  trackAttrs = undefined,
  ...props
}) {
  return (
    <Tooltip
      // options
      html={
        tipType === 'multi' ? (
          <div className="flex flex-col items-start justify-start bg-white text-sm font-medium text-gray-600 antialiased">
            <p className="text-gray-800">{tip}</p>
            <p className="mt-1 text-xs text-gray-500">{tipSub}</p>
            <Switch.Group as="div" className="mt-3 flex items-center">
              <Switch
                checked={toggleEnabled}
                onChange={setToggleEnabled}
                className={classNames(
                  toggleEnabled ? 'bg-blue-500' : 'bg-gray-200',
                  'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2',
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    toggleEnabled ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                  )}
                />
              </Switch>
              <Switch.Label as="span" className="ml-3">
                <span className="text-sm font-medium text-gray-900">
                  Share to Twitter
                </span>
              </Switch.Label>
            </Switch.Group>
          </div>
        ) : tipType === 'button' ? (
          <div className="flex flex-col items-start justify-start bg-white text-sm font-medium text-gray-600 antialiased">
            <p className="text-gray-800">{tip}</p>
            <p className="mt-1 text-xs text-gray-500">{tipSub}</p>
            <button
              onClick={() => tipCTAClick()}
              className="mt-2 font-medium text-blue-500 underline"
            >
              {tipCTA}
            </button>
          </div>
        ) : (
          <div className="bg-white text-sm font-medium text-gray-600 antialiased">
            <p>{tip}</p>
          </div>
        )
      }
      position="top"
      interactive={tipType === 'multi' || tipType === 'button' ? true : false}
      theme="light"
      arrow={true}
      trigger="mouseenter"
      offset={8}
      disabled={tip ? false : true}
      className={`${
        width === 'full'
          ? 'w-full'
          : width === 'full-auto'
          ? 'w-full md:max-w-min'
          : size === 'image'
          ? 'max-w-max'
          : 'max-w-min'
      }`}
    >
      <button
        id={id}
        onClick={click}
        disabled={disabled}
        data-track={track}
        data-track-attrs={trackAttrs ? JSON.stringify(trackAttrs) : undefined}
        className={clsx(
          className,
          `group relative focus:outline-none ${
            size === 'image'
              ? 'py-2 pr-4 pl-1'
              : size === 'small'
              ? 'px-5 py-2'
              : size === 'mini'
              ? 'px-3 py-1.5'
              : size === 'mini-alt'
              ? 'px-3 py-2'
              : 'px-6 py-3'
          }   flex items-center justify-center overflow-visible antialiased ${
            width === 'full'
              ? 'w-full'
              : width === 'full-auto'
              ? 'w-full md:max-w-min'
              : size === 'image'
              ? 'max-w-max'
              : 'max-w-min'
          } disabled:cursor-not-allowed disabled:text-gray-500 ${
            variant === 'secondary'
              ? 'text-blue-500'
              : variant === 'outline'
              ? 'text-gray-600'
              : variant === 'outline-secondary'
              ? 'text-gray-600'
              : variant === 'error-secondary'
              ? 'text-red-500'
              : 'text-white'
          }`,
        )}
      >
        <div
          style={backgroundStyle}
          className={`absolute h-full w-full ${
            size === 'small'
              ? 'rounded-xl'
              : size === 'mini-alt'
              ? 'rounded-[10px]'
              : 'rounded-2xl'
          } transform transition duration-200 group-hover:scale-x-[1.04] group-hover:scale-y-[1.10] group-active:scale-x-[1.02]  group-active:scale-y-[1.05] group-disabled:bg-gray-300 ${
            color
              ? color
              : variant === 'primary'
              ? 'bg-blue-500'
              : variant === 'error'
              ? 'bg-red-500'
              : variant === 'error-secondary'
              ? 'bg-red-500 bg-opacity-10 group-hover:bg-opacity-20'
              : variant === 'secondary'
              ? 'bg-blue-500 bg-opacity-10 group-hover:bg-opacity-20'
              : variant === 'outline'
              ? 'bg-gray-200'
              : variant === 'outline-secondary'
              ? 'border border-gray-200 bg-white group-hover:bg-gray-50'
              : variant === 'create'
              ? 'bg-gradient-to-br from-[#C242FF] to-[#FF5D40]'
              : 'bg-gray-900'
          }`}
        ></div>
        <div
          className={
            'relative z-10 flex flex-row items-center justify-center space-x-3 '
          }
        >
          {prefix ? prefix : null}
          <p
            style={textStyle}
            className={` ${
              customText ? customText : null
            } whitespace-nowrap font-sans font-medium ${
              size === 'small' || size === 'image'
                ? 'text-base'
                : size === 'mini' || size === 'mini-alt'
                ? 'text-sm'
                : 'text-lg'
            }`}
          >
            {props.children}
          </p>
          {suffix ? suffix : null}
          {loading ? (
            <SpinnerIcon
              className={`${
                size === 'mini' ? 'h-4 w-4' : 'h-6 w-6'
              }  "block" : "hidden"} animate-spin`}
            />
          ) : null}
        </div>
      </button>
    </Tooltip>
  );
}
