import { client } from 'utils/http';

async function updateCustomer(
  userID: string,
  data: {
    email: string;
    name: string;
    username: string;
    url?: string;
    twitterHandle?: string;
  },
): Promise<boolean> {
  try {
    const { data: res } = await client.post<{ success: boolean }>(
      '/api/customer-io/create',
      {
        data: data,
      },
    );
    return res.success || false;
  } catch (error) {
    return false;
  }
}

async function trackPageView(data: { pageName: string }): Promise<boolean> {
  try {
    const exists = await fetchCustomer();
    if (!exists?.exists) {
      return false;
    } else {
      const { data: res } = await client.post<{ success: boolean }>(
        '/api/customer-io/page-view',
        {
          pageName: data.pageName,
        },
      );
      return res.success;
    }
  } catch (error) {
    return false;
  }
}

async function trackEvent(
  userID: string,
  data: { name: string; data: object },
) {
  try {
    const { data: res } = await client.post<{ success: boolean }>(
      '/api/customer-io/event',
      {
        name: data.name,
        data: data.data,
      },
    );
    return res.success;
  } catch (error) {
    console.log(error);
    return false;
  }
}

type CustomerIoCustomerAttributes = {
  customer: {
    id: string;
    identifiers: {
      id: string;
      cio_id: string;
    };
    attributes: {
      cio_id: string;
      id: string;
      status: string; // "free", etc
    };
    timestamps: {
      cio_id: number;
      id: number;
      status: number;
    };
    unsubscribed: boolean;
  };
};

type FetchCustomerResponse =
  | {
      exists: true;
      data: CustomerIoCustomerAttributes;
    }
  | { exists: false };

async function fetchCustomer(): Promise<FetchCustomerResponse> {
  try {
    const { data } = await client.get<{
      success: boolean;
      message: string;
      data: CustomerIoCustomerAttributes;
    }>('/api/customer-io/fetch-customer');
    if (data.success) {
      return {
        exists: true,
        data: data.data,
      };
    } else {
      return {
        exists: false,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      exists: false,
    };
  }
}

async function deleteCustomer(): Promise<boolean> {
  try {
    const { data } = await client.delete('/api/customer-io/delete');
    return data.success;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function submitHelp(
  email: string,
  text: string,
  type: object,
  customer: object,
) {
  try {
    const { data } = await client.post<{ success: boolean }>(
      '/api/customer-io/help-request',
      {
        email: email,
        text: text,
        type: type,
        customer: customer,
      },
    );
    return data.success;
  } catch (error) {
    console.log(error);
    return false;
  }
}

//use namespace so we can call it like cio.TrackPageView(userID, pageName) and cio.updateCustomer(userID, email, name)
export const cio = {
  trackPageView,
  updateCustomer,
  trackEvent,
  fetchCustomer,
  deleteCustomer,
  submitHelp,
};
