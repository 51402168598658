import { Dialog, Transition } from '@headlessui/react';
import {
  CashIcon,
  ChartSquareBarIcon,
  CheckCircleIcon,
  CollectionIcon,
  DocumentTextIcon,
  GlobeAltIcon,
  LockOpenIcon,
  PlusIcon,
  XIcon,
} from '@heroicons/react/solid';
import { ChartBarIcon, LightningBoltIcon } from '@heroicons/react/solid';
import { Fragment, useEffect, useState } from 'react';
import Button from '../../Button';

import 'firebase/auth';
import firebase from 'firebase/app';
import 'firebase/database';

import { useRouter } from 'next/router';
import LogInModal from '../log-in';
import { PricingPlans } from '../../../pages/plan';
import TSSimple from '../../Icons/ts-simple';
import Checkout from '../../Checkout';
import TemplatePacks from '../../TemplatesPage/TemplatePacks';
import UpgradePlanModal from '../../Plans/UpgradePlanModal';
import TemplatePackSelector from '../../TemplatesSelector';

export default function TemplatePackSelectorModal({
  isOpen,
  setIsOpen,
  STRIPE_PK,
}) {
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
  const [userID, setUserID] = useState(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (userInfo) {
      if (userInfo) {
        setUserID(userInfo.uid);
      } else {
      }
    });
  }, []);

  useEffect(() => {
    if (userID && isOpen) {
      setModalRead(userID);
    }
  }, [userID, isOpen]);

  const setModalRead = (userID) => {
    var ref = firebase
      .database()
      .ref('users/' + userID)
      .child('modals')
      .child('templatePackSchedule');
    ref.set(true);
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          className="fixed inset-0 z-30 overflow-y-auto"
          onClose={closeModal}
        >
          <UpgradePlanModal
            isOpen={upgradeModalOpen}
            setIsOpen={setUpgradeModalOpen}
            STRIPE_PK={STRIPE_PK}
          />

          <div className="min-h-screen text-center sm:px-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300 transform"
              enterFrom="opacity-0 scale-90 translate-y-96"
              enterTo="opacity-100 scale-100 translate-y-0"
              leave="ease-in duration-300 transform"
              leaveFrom="opacity-100 scale-100 translate-y-0"
              leaveTo="opacity-0 translate-y-96 scale-90"
            >
              <div
                className={` ${
                  upgradeModalOpen && '-translate-y-24 scale-95'
                } relative mt-12 inline-block w-full max-w-5xl transform rounded-t-3xl bg-gray-50 text-left align-middle antialiased shadow-xl transition-all`}
              >
                <div className="sticky top-1 z-10 w-full">
                  <button
                    onClick={() => closeModal()}
                    className="absolute top-2 right-2 rounded-full bg-gray-100 p-2 text-gray-500 duration-200 hover:bg-gray-200 focus:outline-none"
                  >
                    <XIcon className="h-5 w-5" />
                  </button>
                </div>

                <TemplatePackSelector
                  isModalOpen={isOpen}
                  setModalOpen={setIsOpen}
                  upgradeModalOpen={upgradeModalOpen}
                  setUpgradeModalOpen={setUpgradeModalOpen}
                  STRIPE_PK={STRIPE_PK}
                />
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
