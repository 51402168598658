import { Tooltip } from 'react-tippy';

export default function ButtonTip({ position = 'bottom', text, children }) {
  return (
    <Tooltip
      html={
        <div className="flex flex-col items-start justify-start text-sm font-semibold antialiased">
          <p className="text-xs text-white">{text}</p>
        </div>
      }
      position={position}
      interactive={false}
      animateFill={false}
      theme="dark"
      arrow={true}
      arrowSize="small"
      trigger="mouseenter"
      offset={1}
      disabled={false}
    >
      {children}
    </Tooltip>
  );
}
