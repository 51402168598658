export default function SpinnerIcon({ className }) {
  return (
    <svg
      className={`${className} stroke-current`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="12"
        fill="none"
        r="10"
        strokeWidth="2"
        strokeDasharray="42"
        strokeLinecap="round"
      />
      <circle
        cx="12"
        cy="12"
        fill="none"
        opacity="0.25"
        strokeWidth="2"
        r="10"
        strokeLinecap="round"
      />
    </svg>
  );
}
