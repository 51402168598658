import {
  CheckCircleIcon,
  ChevronLeftIcon,
  XCircleIcon,
} from '@heroicons/react/solid';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Button from '../../components/Button';
import TSSimple from '../../components/Icons/ts-simple';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import AlertBanner from '../../components/Alert';

export const ProFeatures = () => {
  return (
    <div className="flex w-full flex-col space-y-4">
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row items-center space-x-2 rounded-lg border border-gray-100 bg-gray-50 p-2.5 text-sm font-medium tracking-tightish">
          <CheckCircleIcon className="h-5 w-5" />
          <p>Instant access to starter Template Pack</p>
        </div>
        <div className="flex flex-row items-center space-x-2 rounded-lg border border-gray-100 bg-gray-50 p-2.5 text-sm font-medium tracking-tightish">
          <CheckCircleIcon className="h-5 w-5" />
          <p>Unlock a new Template Pack each month</p>
        </div>
      </div>

      <div className="flex flex-col space-y-2">
        <p className="text-sm font-medium tracking-tightish">Includes</p>
        <div className="mb-8 mt-2 flex flex-col space-y-2">
          <div className="flex flex-row items-center space-x-2 text-sm ">
            <CheckCircleIcon className="h-5 w-5" />
            <p className="text-gray-600">24 bonus essay + thread templates</p>
          </div>
          <div className="flex flex-row items-center space-x-2 text-sm ">
            <CheckCircleIcon className="h-5 w-5" />
            <p className="text-gray-600">Analytics built for digital writers</p>
          </div>
          <div className="flex flex-row items-center space-x-2 text-sm ">
            <CheckCircleIcon className="h-5 w-5" />
            <p className="text-gray-600">
              Create a custom theme for your posts
            </p>
          </div>
          <div className="flex flex-row items-center space-x-2 text-sm tracking-tightish ">
            <CheckCircleIcon className="h-5 w-5" />
            <p className="text-gray-600">
              Add unlimited cards to your Social Blog
            </p>
          </div>
          <div className="tracking-tigtightishht flex flex-row items-center space-x-2 text-sm ">
            <CheckCircleIcon className="h-5 w-5" />
            <p className="text-gray-600">Create unlimited collections</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PremierFeatures = () => {
  return (
    <div className="flex w-full flex-col space-y-4">
      <div className="flex flex-col space-y-2">
        <div className="flex flex-row items-center space-x-2 rounded-lg border border-gray-800 bg-gradient-to-r from-blue-500 to-indigo-500 p-2.5 text-sm font-medium tracking-tightish">
          <CheckCircleIcon className="h-5 w-5" />
          <p>Instant access to ALL 1st Edition Template Packs</p>
        </div>

        <div className="flex flex-row items-center space-x-2 rounded-lg border border-gray-800 bg-gray-900 p-2.5 text-sm font-medium tracking-tightish">
          <CheckCircleIcon className="h-5 w-5" />
          <p>Soon: Special Premier only educational content</p>
        </div>
      </div>

      <div className="flex flex-col space-y-2">
        <p className="text-sm font-medium tracking-tightish">Includes</p>
        <div className="mb-8 mt-2 flex flex-col space-y-2">
          <div className="flex flex-row items-center space-x-2 text-sm ">
            <CheckCircleIcon className="h-5 w-5" />
            <p className="text-gray-300">24 bonus essay + thread templates</p>
          </div>
          <div className="flex flex-row items-center space-x-2 text-sm ">
            <CheckCircleIcon className="h-5 w-5" />
            <p className="text-gray-300">Analytics built for digital writers</p>
          </div>
          <div className="flex flex-row items-center space-x-2 text-sm ">
            <CheckCircleIcon className="h-5 w-5" />
            <p className="text-gray-300">
              Create a custom theme for your posts
            </p>
          </div>
          <div className="flex flex-row items-center space-x-2 text-sm tracking-tightish ">
            <CheckCircleIcon className="h-5 w-5" />
            <p className="text-gray-300">
              Add unlimited cards to your Social Blog
            </p>
          </div>
          <div className="tracking-tigtightishht flex flex-row items-center space-x-2 text-sm ">
            <CheckCircleIcon className="h-5 w-5" />
            <p className="text-gray-300">Create unlimited collections</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PricingPlans = ({
  disable,
  showFree = true,
  productName = 'Free Plan',
  pastCustomer,
  proClick,
  premierClick,
  buttonLoading,
  affiliate = false,
  ship30 = false,
}) => {
  const proPrice = 20;
  const premierPrice = 200;
  const affiliateDiscount = 0.5; //for 3 months

  return (
    <div className="grid w-full grid-cols-1 gap-6 pb-4 md:grid-cols-2">
      <div className="col-span-1 flex flex-col space-y-4 rounded-3xl border border-gray-100 bg-white p-6">
        <div className="flex flex-col space-y-1">
          <h1 className="text-2xl font-semibold tracking-tight">Pro</h1>
          <p className="text-sm text-gray-500">
            Get instant access to your selected starter Template pack and new
            pack access monthly.
          </p>
        </div>

        {affiliate ? (
          <div className="flex flex-row items-center justify-start space-x-3">
            <p className="text-lg font-semibold tracking-tighter text-gray-400 line-through">
              ${proPrice}
            </p>
            <p className="text-3xl font-semibold tracking-tighter">
              ${proPrice * affiliateDiscount}
              <span className="text-sm font-medium tracking-normal text-gray-500">
                {' '}
                / month
              </span>
            </p>

            <span className="rounded-md bg-green-500 px-2 py-1 text-xs font-medium tracking-tightish text-white">
              {affiliateDiscount * 100}% off for 3 months
            </span>
          </div>
        ) : (
          <p className="text-3xl font-semibold tracking-tighter">
            ${proPrice}
            <span className="text-sm font-medium tracking-normal text-gray-500">
              {' '}
              / month
            </span>
          </p>
        )}

        <ProFeatures />

        {productName === 'Pro' ? (
          <Button
            size={'small'}
            width={'full'}
            variant={'secondary'}
            prefix={<CheckCircleIcon className="-mr-1 h-4 w-4 text-blue-500" />}
          >
            <p className="tracking-tightish">Your Plan</p>
          </Button>
        ) : (
          <Button
            tip={disable && 'Your subscription needs to be re-activated first'}
            loading={buttonLoading === 'pro'}
            disabled={buttonLoading || disable}
            click={() => proClick()}
            size={'small'}
            width={'full'}
          >
            <p className="tracking-tightish">
              {productName.toLocaleLowerCase().includes('free') && !pastCustomer
                ? `Try Free for ${ship30 ? '35' : '30'} Days`
                : productName.toLocaleLowerCase().includes('free') ||
                  productName.toLocaleLowerCase().includes('hobby')
                ? 'Upgrade to Pro'
                : 'Downgrade to Pro'}
            </p>
          </Button>
        )}
      </div>

      <div className="col-span-1 flex flex-col space-y-4 rounded-3xl border border-gray-800 bg-black p-6 text-white">
        <div className="flex flex-col space-y-1">
          <h1 className="text-2xl font-semibold tracking-tight">Premier</h1>
          <p className="text-sm text-gray-200">
            Pay yearly to get access to ALL template packs instantly and save
            20%.
          </p>
        </div>

        <div className="flex flex-row items-center justify-start space-x-3">
          <p className="text-lg font-semibold tracking-tighter text-gray-400 line-through">
            ${proPrice * 12}
          </p>
          <p className="text-3xl font-semibold tracking-tighter">
            ${premierPrice}
            <span className="text-sm font-medium tracking-normal text-gray-300">
              {' '}
              / year
            </span>
          </p>

          <span className="rounded-md bg-green-500 bg-opacity-30 px-2 py-1 text-xs font-medium tracking-tightish text-green-400">
            Save{' '}
            {
              //absolute value and round to 0 decimal places
              Math.round(
                Math.abs((premierPrice - proPrice * 12) / premierPrice) * 100,
              )
            }
            %
          </span>
        </div>

        <PremierFeatures />

        {productName === 'Premier' ? (
          <Button
            size={'small'}
            width={'full'}
            color={'bg-blue-500 bg-opacity-30'}
            customText={'text-blue-500'}
            prefix={<CheckCircleIcon className="-mr-1 h-4 w-4 text-blue-500" />}
          >
            <p className="tracking-tightish">Your Plan</p>
          </Button>
        ) : (
          <Button
            tip={disable && 'Your subscription needs to be re-activated first'}
            loading={buttonLoading === 'premier'}
            disabled={buttonLoading || disable}
            click={() => premierClick()}
            size={'small'}
            width={'full'}
            color={'bg-white'}
            customText={'text-black'}
          >
            <p className="tracking-tightish">{'Upgrade to Premier'}</p>
          </Button>
        )}
      </div>

      <div
        className={`col-span-2 flex-row items-center justify-between rounded-3xl border border-gray-100 bg-white p-6 text-gray-900 ${
          showFree ? 'flex' : 'hidden'
        }`}
      >
        <div className="flex flex-col items-start space-y-3">
          <h1 className="text-2xl font-semibold tracking-tight">Free</h1>
          <div className="mb-8 mt-2 flex flex-col space-y-2">
            <div className="flex flex-row items-center space-x-2 text-sm ">
              <XCircleIcon className="h-5 w-5 text-red-500" />
              <p className="text-gray-600">
                Analytics built for digital writers
              </p>
            </div>
            <div className="flex flex-row items-center space-x-2 text-sm ">
              <XCircleIcon className="h-5 w-5 text-red-500" />
              <p className="text-gray-600">
                Create a custom theme for your posts
              </p>
            </div>
          </div>
        </div>
        <Button size={'small'} variant={'outline'}>
          <p className="tracking-tightish">Stay on Free Plan</p>
        </Button>
      </div>
    </div>
  );
};

export default function PlanPage() {
  const router = useRouter();

  return (
    <div className="relative flex h-full min-h-screen w-full justify-center bg-gray-50 pt-12 antialiased">
      <div className="fixed top-0 flex w-full items-center justify-between p-6">
        <TSSimple className="h-6 w-6" />

        <img src={'/sams.jpeg'} className="h-10 w-10 rounded-full" />
      </div>

      <div className="flex w-full max-w-3xl flex-col space-y-8">
        <div className="flex flex-col space-y-2">
          <div className="flex flex-row items-center justify-start space-x-3">
            <button
              onClick={() => router.push('/library')}
              className="relative z-10 rounded-full bg-gray-200 p-1 text-gray-600 duration-150 hover:scale-105 hover:bg-gray-300 hover:text-gray-700 active:scale-100"
            >
              <ChevronLeftIcon className="h-5 w-5" />
            </button>
            <h1 className="text-2xl font-semibold tracking-tight">
              Choose a Plan
            </h1>
          </div>

          <p className="text-sm text-gray-500">
            Choose the plan that meets your needs. Change plans or cancel
            anytime.
          </p>
        </div>

        <PricingPlans />
      </div>
    </div>
  );
}
