import {
  useLDClient,
  useFlags as useLDFlags,
} from 'launchdarkly-react-client-sdk';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useCallback, useEffect } from 'react';
import { getUserProfile } from './helpers';

export type FeatureFlags = {
  custom_domains: boolean;
};

export const featureFlagInit: FeatureFlags = {
  custom_domains: false,
};

export const useFlags = () => useLDFlags<FeatureFlags>();

/**
 * Use this when important attributes may have changed.
 */
export const useReidentifyFeatureFlagUser = () => {
  const client = useLDClient();
  return useCallback(
    async (uid?: string) => {
      if (!client) {
        console.warn('No LaunchDarkly client');
        return;
      }
      if (!uid) {
        client.identify({
          anonymous: true,
        });
        return;
      }
      const profile = await getUserProfile(uid);
      if (!profile) {
        client.identify({
          anonymous: true,
        });
        return;
      }
      client.identify(
        {
          key: uid,
          email: profile.email,
          name: profile.name,
          custom: {
            plan: profile.plan,
            planStatus: profile.status,
            connectedPlatforms: [
              profile.connectedPlatforms.linkedin ? 'linkedin' : null,
              profile.connectedPlatforms.medium ? 'medium' : null,
              profile.connectedPlatforms.twitter ? 'twitter' : null,
            ].filter(Boolean),
          },
        },
        null, // hash can be omitted in insecure mode
        function () {
          console.log("User's flags available");
        },
      );
    },
    [client],
  );
};

export const useInitFeatureFlags = (): void => {
  const client = useLDClient();
  const reidentifyUser = useReidentifyFeatureFlagUser();
  useEffect(() => {
    client &&
      firebase.auth().onAuthStateChanged(async function (userInfo) {
        reidentifyUser(userInfo && userInfo.uid);
      });
  }, [client, reidentifyUser]);
};
