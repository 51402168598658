export default function TemplatesIconAlt({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.3125 9.5625H6.125V10.875H8.3125C8.42853 10.875 8.53981 10.8289 8.62186 10.7469C8.70391 10.6648 8.75 10.5535 8.75 10.4375V10C8.75 9.88397 8.70391 9.77269 8.62186 9.69064C8.53981 9.60859 8.42853 9.5625 8.3125 9.5625ZM2.625 10V10.4375C2.625 10.5535 2.67109 10.6648 2.75314 10.7469C2.83519 10.8289 2.94647 10.875 3.0625 10.875H5.25V9.5625H3.0625C2.94647 9.5625 2.83519 9.60859 2.75314 9.69064C2.67109 9.77269 2.625 9.88397 2.625 10ZM10.7188 5.625H3.28125C3.1072 5.625 2.94028 5.69414 2.81721 5.81721C2.69414 5.94028 2.625 6.1072 2.625 6.28125V7.59375C2.625 7.7678 2.69414 7.93472 2.81721 8.05779C2.94028 8.18086 3.1072 8.25 3.28125 8.25H10.7188C10.8928 8.25 11.0597 8.18086 11.1828 8.05779C11.3059 7.93472 11.375 7.7678 11.375 7.59375V6.28125C11.375 6.1072 11.3059 5.94028 11.1828 5.81721C11.0597 5.69414 10.8928 5.625 10.7188 5.625ZM12.25 3H1.75C1.28587 3 0.840752 3.18437 0.512563 3.51256C0.184375 3.84075 0 4.28587 0 4.75L0 11.75C0 12.2141 0.184375 12.6592 0.512563 12.9874C0.840752 13.3156 1.28587 13.5 1.75 13.5H12.25C12.7141 13.5 13.1592 13.3156 13.4874 12.9874C13.8156 12.6592 14 12.2141 14 11.75V4.75C14 4.28587 13.8156 3.84075 13.4874 3.51256C13.1592 3.18437 12.7141 3 12.25 3ZM12.6875 11.75C12.6875 11.866 12.6414 11.9773 12.5594 12.0594C12.4773 12.1414 12.366 12.1875 12.25 12.1875H1.75C1.63397 12.1875 1.52269 12.1414 1.44064 12.0594C1.35859 11.9773 1.3125 11.866 1.3125 11.75V4.75C1.3125 4.63397 1.35859 4.52269 1.44064 4.44064C1.52269 4.35859 1.63397 4.3125 1.75 4.3125H12.25C12.366 4.3125 12.4773 4.35859 12.5594 4.44064C12.6414 4.52269 12.6875 4.63397 12.6875 4.75V11.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
