export default function TwitterBirdIcon({ className }) {
  return (
    <svg
      className={className}
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15 3.84839C14.4847 4.06701 13.9318 4.21528 13.3509 4.28147C13.9441 3.94137 14.3981 3.40192 14.6133 2.76109C14.0569 3.07605 13.4428 3.30475 12.7884 3.42873C12.2644 2.89346 11.519 2.56006 10.6923 2.56006C9.1062 2.56006 7.82018 3.79143 7.82018 5.30929C7.82018 5.52457 7.84555 5.73482 7.89455 5.93588C5.50801 5.8211 3.39178 4.72628 1.97543 3.06265C1.72784 3.46809 1.58699 3.94051 1.58699 4.44481C1.58699 5.39892 2.0944 6.24078 2.86427 6.73332C2.3936 6.71825 1.95093 6.59427 1.56339 6.3882V6.42254C1.56339 7.75444 2.55371 8.86602 3.86682 9.11901C3.62624 9.181 3.37255 9.21534 3.11009 9.21534C2.92464 9.21534 2.74529 9.19775 2.56944 9.16424C2.93511 10.2574 3.99543 11.0523 5.25171 11.0741C4.26926 11.8113 3.03048 12.2494 1.68499 12.2494C1.45317 12.2494 1.22482 12.236 1 12.2117C2.27114 12.9933 3.78024 13.4489 5.40218 13.4489C10.6853 13.4489 13.5732 9.25891 13.5732 5.62511L13.5635 5.2691C14.1278 4.88374 14.6159 4.39958 15 3.84839Z" />
    </svg>
  );
}
