/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react';
import { Dialog, Transition, Disclosure } from '@headlessui/react';
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  XIcon,
} from '@heroicons/react/outline';
import {
  ChevronRightIcon,
  LightBulbIcon,
  LightningBoltIcon,
  SearchIcon,
} from '@heroicons/react/solid';

import { ChevronDownIcon } from '@heroicons/react/outline';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const tabs = [
  {
    name: 'Start Guide',
    navigation: [
      { name: 'Team', href: '#', icon: LightningBoltIcon, current: false },
      { name: 'Method', href: '#', icon: LightBulbIcon, current: false },
    ],
  },
  // More questions...
];

export default function AcademyLayout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <div className=" antialiased">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-40 flex md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                      alt="Workflow"
                    />
                  </div>

                  <nav className="mt-5 space-y-1 px-2">
                    {tabs.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                          'group flex items-center rounded-md px-2 py-2 text-base font-medium',
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current
                              ? 'text-gray-500'
                              : 'text-gray-400 group-hover:text-gray-500',
                            'mr-4 h-6 w-6 flex-shrink-0',
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    ))}
                  </nav>
                </div>
                <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                  <a href="#" className="group block flex-shrink-0">
                    <div className="flex items-center">
                      <div>
                        <img
                          className="inline-block h-10 w-10 rounded-full"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                          Tom Cook
                        </p>
                        <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                          View profile
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-72 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col border-r border-gray-100 bg-white">
            <div className="flex flex-1 flex-col overflow-y-auto p-8">
              <div className="flex flex-shrink-0 flex-row items-center space-x-2">
                <img
                  className="h-6 w-auto"
                  src="/typeshare-text-logo.svg"
                  alt="Workflow"
                />

                <div className="h-full w-0.5 bg-gray-100" />
                <p className="font-mono text-sm font-medium uppercase text-gray-500">
                  Academy
                </p>
              </div>

              <button className="group mt-4 flex flex-row items-center justify-between rounded-lg border border-gray-100 bg-gray-50 py-1.5 pl-3 pr-1.5 transition duration-200 hover:border-gray-200">
                <div className="flex flex-row items-center justify-start space-x-1 text-xs text-gray-500">
                  <SearchIcon className="h-4 w-4 text-gray-400 transition duration-200 group-hover:text-gray-700" />
                  <p>Search docs</p>
                </div>
                <div className="flex flex-row items-center space-x-1">
                  <div className="flex h-5 w-5 items-center justify-center rounded-md bg-gray-200 text-center text-xs">
                    ⌘
                  </div>
                  <div className="flex h-5 w-5 items-center justify-center rounded-md bg-gray-200 text-center text-xs">
                    K
                  </div>
                </div>
              </button>

              {tabs.map((tab) => (
                <Disclosure as="div" key={tab.name} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-sm">
                        <Disclosure.Button className="group flex w-full items-center justify-start space-x-2 text-left text-gray-500 duration-200 hover:text-gray-700">
                          <span className="ml-0 flex h-4 items-center">
                            <ChevronRightIcon
                              className={classNames(
                                open ? 'rotate-90' : 'rotate-0',
                                'h-3 w-3 transform text-gray-400 group-hover:text-gray-700',
                              )}
                              aria-hidden="true"
                            />
                          </span>
                          <span className="font-medium">{tab.name}</span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="pl-4">
                        <nav className="mt-3 flex-1 space-y-1 bg-white px-2">
                          {tab.navigation.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? 'text-gray-900'
                                  : 'text-gray-500 duration-200 hover:text-gray-700',
                                'group flex items-center rounded-md text-sm font-medium',
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  item.current
                                    ? 'text-gray-500'
                                    : 'text-gray-400 duration-200 group-hover:text-gray-700',
                                  'mr-3 h-4 w-4 flex-shrink-0',
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </a>
                          ))}
                        </nav>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </div>
            <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
              <a href="#" className="group block w-full flex-shrink-0">
                <div className="flex items-center">
                  <div>
                    <img
                      className="inline-block h-9 w-9 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                      Tom Cook
                    </p>
                    <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                      View profile
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col md:pl-64">
          <div className="sticky top-0 z-10 bg-white pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="flex flex-1 flex-row">{children}</main>
        </div>
      </div>
    </>
  );
}
