/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon, TemplateIcon } from '@heroicons/react/outline';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import { PhotographIcon } from '@heroicons/react/outline';
import SocialBlogIcon from '../Icons/blog';
import TemplatesIcon from '../Icons/templates';
import { useRouter } from 'next/router';

import SpinnerIcon from '../Icons/spinner';
import Button from '../Button';
import TwitterBirdIcon from '../Icons/twitter';
import { GiftIcon } from '@heroicons/react/solid';
import { cio } from '../../utils/customer-io';
import { useAnalytics } from '../../utils/analytics.client';

export default function LogInModal({
  isOpen,
  setIsOpen,
  userTrying,
  closable = false,
  setOpen = null,
  offer = false,
  redirect = true,
  passProps = false,
}) {
  const analytics = useAnalytics();

  const [errorMsg, setErrorMsg] = useState('');

  const router = useRouter();

  const [twitterLoginLoading, setTwitterLoginLoading] = useState(false);
  const [googleLoginLoading, setGoogleLoginLoading] = useState(false);

  var db = firebase.database();

  function writeUserData(
    userId,
    name,
    email,
    imageUrl,
    token = null,
    secret = null,
    twitterUsername = null,
    isNewUser,
  ) {
    if (token === null) {
      // Add a new document with a generated id.
      db.ref('users/' + userId + '/meta')
        .update({
          username: name,
          email: email,
          profile_picture: imageUrl,
        })
        .then(() => {
          createGallery(userId, name, imageUrl, null, isNewUser, email);
          createDefaultTheme(userId, name);
          addToConvertKit(email, name, userId, isNewUser);
        })
        .catch((error) => {});
    } else {
      // Add a new document with a generated id.
      //is a twitter sign in
      //use twitter provider imageUrl

      db.ref('users/' + userId + '/meta')
        .update({
          username: name,
          email: email,
          profile_picture: imageUrl,
          twitter: {
            token: token,
            secret: secret,
            newTwitterApp: true,
            photo: imageUrl,
            displayName: name,
            handle: twitterUsername,
          },
        })
        .then(() => {
          createGallery(
            userId,
            name,
            imageUrl,
            twitterUsername,
            isNewUser,
            email,
          );
          createDefaultTheme(userId, name);
          addToConvertKit(email, name, userId, isNewUser);
        })
        .catch((error) => {});
    }
  }

  const createDefaultTheme = (uid, displayName) => {
    //check if theme exiists first before creating one
    const dbRef = firebase.database().ref('users').child(uid).child('theme');
    dbRef.once('value').then((snapshot) => {
      if (snapshot.exists()) {
        //do nothing
      } else {
        dbRef.update({
          content: {
            author: displayName,
            footer: 'Made with Typeshare',
            footerURL: displayName,
            sidebarText: displayName,
          },
          style: {
            author: true,
            background: false,
            colorID: 1,
            fontID: 2,
            footer: true,
            footerID: 1,
            layoutID: 2,
            title: true,
          },
        });
      }
    });
  };

  async function addToConvertKit(email, name, uid, isNewUser) {
    const firstName = name.substr(0, name.indexOf(' '));

    return fetch('https://api.convertkit.com/v3/forms/2605946/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        api_key: 'RDof1mhbPJEfOe1cTkPQJA',
        email,
        first_name: firstName,
      }),
    }).then(async function (result) {
      //get returned ID from ConvertKit and store it in the database

      const response = await result.json();
      const subscriptionID = response.subscription
        ? response.subscription.subscriber.id
        : null;
      const dbRef = firebase
        .database()
        .ref('users')
        .child(uid)
        .child('convertKit');

      dbRef.update({
        email: email,
        id: subscriptionID,
      });

      if (isNewUser) {
        const change = await changeStatus('free', subscriptionID);

        if (change) {
          //do nothing
        } else {
          //do nothing
        }
      } else {
        //do nothing
      }

      return response;
    });
  }

  async function changeStatus(status, id) {
    return fetch(`https://api.convertkit.com/v3/subscribers/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify({
        api_secret: '53Fr-MW3AlrdV_vkQ7vynIoRSNME0r5lSbtV_6fD43Y',
        fields: { status: status },
      }),
    })
      .then(async function (result) {
        const data = await result.json();
        if (data) {
          return true;
        } else {
          return false;
        }
      })
      .catch(function (err) {
        console.log(err);
        return false;
      });
  }

  function makeid(length) {
    var result = '';
    var characters = '0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const createGallery = (
    userId,
    name,
    imageUrl,
    twitterUrl = null,
    isNewUser,
    email,
  ) => {
    //Check if user has gallery. If they do, DONT create one
    var ref = db.ref('users/' + userId);
    ref.once(
      'value',
      function (snapshot) {
        if (snapshot.hasChild('gallery')) {
          var username = snapshot.child('gallery/username')
            ? snapshot.child('gallery/username').val()
            : null;
          //do nothing
          if (twitterUrl && username) {
            //add twitter handle to gallery
            var metaRef = db
              .ref('gallery')
              .child(username)
              .child(userId)
              .child('meta');
            //check if meta exists

            metaRef.once('value', function (snap) {
              if (snap.exists()) {
                //check if meta/displayName exists
                if (snap.hasChild('displayName')) {
                  //meta is formatted properly
                  db.ref('gallery')
                    .child(username)
                    .child(userId)
                    .child('meta')
                    .update({
                      twitterURL: twitterUrl,
                    });
                } else {
                  //has meta, but no displayName - incorrect format - will be updated in <EditProfile />
                }
              }
            });
          }
        } else {
          //generate an automatic url and check if it's taken
          var generatedUsername = name
            .replace(/[^0-9a-z]/gi, '')
            .toLocaleLowerCase();

          var galleryRef = db.ref('gallery').child(generatedUsername);

          var finalUsername = generatedUsername;

          galleryRef
            .once('value', async function (snapshot2) {
              if (snapshot2.exists()) {
                var generatedUsernameTwo = `${
                  generatedUsername + makeid(4)
                }`.toLocaleLowerCase();
                //username is taken, regenerate
                db.ref('gallery')
                  .child(generatedUsernameTwo)
                  .child(userId)
                  .update({
                    enabled: true,
                    meta: {
                      displayName: name,
                      image: imageUrl,
                      twitterURL: twitterUrl ? twitterUrl : '',
                      bio: 'Welcome to my Social Blog',
                      slug: generatedUsernameTwo,
                    },
                  })
                  .then(() => {
                    db.ref('users/' + userId + '/gallery').set({
                      enabled: true,
                      username: generatedUsernameTwo,
                    });
                  });
                finalUsername = generatedUsernameTwo;
              } else {
                //does not exist, use first generated username
                db.ref('gallery')
                  .child(generatedUsername)
                  .child(userId)
                  .update({
                    enabled: true,
                    meta: {
                      displayName: name,
                      image: imageUrl,
                      twitterURL: twitterUrl ? twitterUrl : '',
                      bio: 'Welcome to my Social Blog',
                      slug: generatedUsername,
                    },
                  })
                  .then(() => {
                    db.ref('users/' + userId + '/gallery').set({
                      enabled: true,
                      username: generatedUsername,
                    });
                  });
              }
            })
            .then(() => {
              var email2 = email;

              if (!email2) {
                email2 = firebase?.auth()?.currentUser?.email;
              }

              if (isNewUser) {
                cio.updateCustomer(userId, {
                  email: email2,
                  name: name,
                  username: finalUsername,
                  url: 'https://typeshare.co/' + finalUsername,
                  twitterHandle: twitterUrl ? twitterUrl : undefined,
                  plan: 'free',
                  created_at: Math.floor(Date.now() / 1000),
                });

                const userProfile = {
                  email: email2,
                  name: name,
                  avatar: imageUrl,
                  slug: finalUsername,
                  twitterHandle: twitterUrl ? twitterUrl : undefined,
                  plan: 'free',
                  status: 'free',
                  signedUpDate: new Date().toISOString(),
                  connectedPlatforms: {
                    twitter: twitterUrl ? true : false,
                  },
                };
                createUserProfile(userProfile, userId);
              }

              //if offer param is true, send offer to user
              if (redirect) {
                var offerParam = router.query.offer ? router.query.offer : null;
                offerParam
                  ? router.push({
                      pathname: '/library',
                      query: { offer: offerParam },
                    })
                  : isNewUser
                  ? router.push({
                      pathname: '/library',
                      query: { tour: 'onboarding' },
                    })
                  : router.push({ pathname: '/library' });
              } else {
                //return some data to the modal component
                passProps && passProps(name, imageUrl, email2);
              }
            });
        }
      },
      function (errorObject) {
        setGoogleLoginLoading(false);
        setTwitterLoginLoading(false);
      },
    );
  };

  async function createUserProfile(userProfile, uid) {
    analytics.setUserAttributesOnce({
      $email: userProfile.email,
      $name: userProfile.name,
      $created: userProfile.signedUpDate,
      $avatar: userProfile.avatar,
      Username: userProfile.slug,
      'Typeshare URL': 'https://typeshare.co/' + userProfile.slug,
      'Twitter Handle': userProfile.twitterUrl,
      Plan: userProfile.plan ? userProfile.plan : 'free',
      Status: userProfile.status,
    });

    userProfile.connectedPlatforms.twitter &&
      analytics.unionUserAttribute({
        'Connected platforms': ['Twitter'],
      });

    analytics.trackEvent('Signed Up');

    //update firebase mixpanel connection
    await firebase
      .database()
      .ref('users/' + uid)
      .child('meta')
      .child('mixpanelConnected')
      .set(true);

    console.log('Added profile to Mixpanel - from login');
  }

  const logInWithGoogle = () => {
    setGoogleLoginLoading(true);

    var provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        var credential = result.credential;

        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = credential.accessToken;
        // The signed-in user info.
        var user = result.user;

        user &&
          analytics.trackEvent('Logged In', {
            Provider: 'Google',
          });

        user
          ? writeUserData(
              user.uid,
              user.displayName,
              user.email,
              user.photoURL,
              null,
              null,
              null,
              result.additionalUserInfo.isNewUser,
            )
          : setGoogleLoginLoading(false);
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        setErrorMsg(errorMessage);
        setGoogleLoginLoading(false);
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
  };

  const blockedHandles = [
    //make these lowercase
    'its_sush_fan',
    'khushi4justice',
    'sushabelieber',
    'foreversushh',
    'sushassrian',
    'sushantmunjal',
  ];

  const logInWithTwitter = () => {
    setTwitterLoginLoading(true);
    var provider = new firebase.auth.TwitterAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        //check if handle is blocked convert to lowercase
        if (
          blockedHandles.includes(
            result.additionalUserInfo.username.toLowerCase(),
          )
        ) {
          setTwitterLoginLoading(false);
          alert('Access Denied');
        } else {
          var credential = result.credential;

          // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
          // You can use these server side with your app's credentials to access the Twitter API.
          var token = credential.accessToken;
          var secret = credential.secret;

          // The signed-in user info.
          var user = result.user;

          var image =
            result?.additionalUserInfo?.profile?.profile_image_url_https.replace(
              '_normal',
              '',
            );
          var displayName = result?.additionalUserInfo?.profile?.name;

          user &&
            analytics.trackEvent('Logged In', {
              Provider: 'Twitter',
            });

          user
            ? writeUserData(
                user.uid,
                displayName ? displayName : user.displayName,
                user.email,
                image ? image : user.photoURL,
                token,
                secret,
                result.additionalUserInfo.username,
                result.additionalUserInfo.isNewUser,
              )
            : setTwitterLoginLoading(false);
        }
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        setErrorMsg(errorMessage);
        setTwitterLoginLoading(false);
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
      });
  };

  const tryItOut = () => {
    userTrying();
    setErrorMsg('');
  };

  const cancelButtonRef = useRef();

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        initialFocus={cancelButtonRef}
        className="fixed inset-0 z-50"
        onClose={() => (closable ? setOpen(false) : null)}
      >
        <div className="flex min-h-screen items-end justify-center pb-10 text-center antialiased sm:block sm:p-0 sm:px-4 sm:pt-4 sm:pb-20 md:items-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm backdrop-filter transition-all" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            appear={true}
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-24 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-24 sm:scale-95"
          >
            <div className=" relative inline-block transform text-center align-bottom antialiased transition-all sm:w-full sm:max-w-md sm:align-middle">
              {offer ? (
                <div className="my-4 flex w-full flex-row items-center justify-center space-x-2 rounded-2xl border-2 border-white bg-gradient-to-br from-orange-500 to-yellow-500 p-3 font-sans font-medium text-white ">
                  <div className="max-w-min rounded-full bg-white p-2">
                    <GiftIcon className="h-4 w-4 text-orange-500" />
                  </div>
                  <p>Login to claim your offer!</p>
                </div>
              ) : null}
              <div className=" relative inline-block transform rounded-3xl bg-white p-8 text-center align-bottom antialiased shadow-xl transition-all sm:w-full sm:max-w-md sm:align-middle">
                <div className={'flex flex-col items-center justify-center'}>
                  <Dialog.Title
                    as="h3"
                    className="font-display text-3xl font-medium tracking-tight text-gray-900"
                  >
                    Typeshare
                  </Dialog.Title>
                  <p className="tex-sm text-gray-500">
                    Everything you need to start writing online.
                  </p>

                  <div className={'mt-4 flex w-full flex-col space-y-3'}>
                    <p className={'mb-2 text-center text-xs text-red-500'}>
                      {errorMsg}
                    </p>
                    <div className="flex w-full flex-col space-y-3">
                      <Button
                        ref={cancelButtonRef}
                        click={logInWithTwitter}
                        loading={twitterLoginLoading}
                        disabled={twitterLoginLoading || googleLoginLoading}
                        color={'bg-[#1DA1F2]'}
                        prefix={<TwitterBirdIcon className={'h-6 w-6'} />}
                        width={'full'}
                      >
                        Continue with Twitter
                      </Button>
                      <Button
                        click={logInWithGoogle}
                        loading={googleLoginLoading}
                        disabled={twitterLoginLoading || googleLoginLoading}
                        variant={'outline'}
                        prefix={
                          <svg
                            className={' ml-2 flex h-5 w-5 fill-current'}
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4.30766 10.0001C4.30766 9.35054 4.41547 8.72772 4.60813 8.14366L1.23781 5.57007C0.580938 6.90366 0.210938 8.40647 0.210938 10.0001C0.210938 11.5924 0.580625 13.0941 1.23641 14.4269L4.60484 11.8483C4.41406 11.2669 4.30766 10.6465 4.30766 10.0001Z"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.2295 4.09094C11.6406 4.09094 12.9152 4.59094 13.9166 5.40906L16.8297 2.5C15.0545 0.954531 12.7786 0 10.2295 0C6.27203 0 2.87078 2.26312 1.24219 5.57L4.61234 8.14359C5.38891 5.78641 7.6025 4.09094 10.2295 4.09094Z"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.2295 15.9091C7.60266 15.9091 5.38906 14.2136 4.6125 11.8564L1.24219 14.4296C2.87078 17.7369 6.27203 20 10.2295 20C12.672 20 15.0041 19.1327 16.7542 17.5077L13.5552 15.0346C12.6525 15.6032 11.5158 15.9091 10.2295 15.9091Z"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M19.7855 10C19.7855 9.40907 19.6944 8.77267 19.5578 8.18188H10.2266V12.0455H15.5978C15.3292 13.3628 14.5983 14.3755 13.5522 15.0345L16.7512 17.5077C18.5897 15.8014 19.7855 13.2595 19.7855 10Z"
                            />
                          </svg>
                        }
                        width={'full'}
                      >
                        Continue with Google
                      </Button>
                    </div>
                  </div>

                  <p className={'mt-4 px-10 text-sm text-gray-500'}>
                    By continuing, you agree to our{' '}
                    <a
                      className={
                        'cursor-pointer font-semibold text-gray-700 hover:text-gray-900 focus:outline-none'
                      }
                      target="_blank"
                      rel="noreferrer noopener"
                      href={
                        'https://www.notion.so/typeshare/Typeshare-Privacy-Policy-8ec00b35ad9c404b839b61bda926d5db'
                      }
                    >
                      Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a
                      className={
                        'cursor-pointer font-semibold text-gray-700 hover:text-gray-900 focus:outline-none'
                      }
                      target="_blank"
                      rel="noreferrer noopener"
                      href={
                        'https://www.notion.so/typeshare/Typeshare-Terms-of-Use-e72e0b8e9f4147709cdf8e3987681a7f'
                      }
                    >
                      Terms of Use
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
