import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

import { client } from './http';

//use like: getActivePlan(userID, function (plan) { setProductName(plan.name) });

export async function getUserMeta(userID, callback) {
  var meta = {
    name: '',
    email: '',
    username: '',
    image: '',
  };

  const usernameRef = await firebase
    .database()
    .ref('users/' + userID + '/gallery/')
    .child('username')
    .once('value');

  var emailRef = await firebase
    .database()
    .ref('users/' + userID)
    .child('meta')
    .child('email')
    .once('value');

  if (emailRef.val() != null) {
    meta.email = emailRef.val();
  }

  if (usernameRef.exists()) {
    const username = usernameRef.val();
    meta.username = username;

    const blogMetaRef = await firebase
      .database()
      .ref('gallery')
      .child(username)
      .child(userID)
      .child('meta')
      .once('value');

    if (blogMetaRef.hasChild('displayName')) {
      meta.name = blogMetaRef.val().displayName;
      meta.image = blogMetaRef.val().image;

      callback(meta);
    } else {
      const blogMetaRef2 = await firebase
        .database()
        .ref('gallery')
        .child(username)
        .child(userID)
        .once('value');

      meta.name = blogMetaRef2.val().displayName;
      meta.image = blogMetaRef2.val().image;

      callback(meta);
    }
  } else {
    //no gallery
    callback(meta);
  }
}

export async function getActivePlan(userID, callback) {
  var plan = {
    name: 'Free Plan',
    customerID: null,
    subscriptionID: null,
    priceID: null,
    status: null,
  };

  var ref = firebase.database().ref('users/' + userID + '/plan');
  ref.on('value', async function (snapshot) {
    if (snapshot.hasChild('status')) {
      plan.status = snapshot.val().status;
    }

    if (snapshot.hasChild('stripe/customerID')) {
      plan.customerID = snapshot.val().stripe.customerID;
    }

    if (snapshot.hasChild('stripe/subscriptionID')) {
      plan.subscriptionID = snapshot.val().stripe.subscriptionID;
    }

    if (snapshot.hasChild('priceID')) {
      plan.priceID = snapshot.val().priceID;
    }

    if (snapshot.hasChild('productName')) {
      if (
        snapshot.val().productName.includes('Pro') ||
        snapshot.val().productName.includes('pro') ||
        snapshot.val().productName.includes('Expert')
      ) {
        //check the period
        if (snapshot.val().productName === 'Expert Lifetime') {
          plan.name = 'Premier';
          callback(plan);
        } else {
          if (snapshot.hasChild('stripe/subscriptionID')) {
            const period = await getBillingPeriod(
              snapshot.val().stripe.subscriptionID,
            );
            if (period !== false) {
              plan.name = period;
            }
            callback(plan);
          } else {
            plan.name = snapshot.val().productName;
            callback(plan);
          }
        }
      } else {
        plan.name = snapshot.val().productName;
        callback(plan);
      }
    } else {
      plan.name = 'Free Plan';
      callback(plan);
    }
  });
}

export async function getActiveProductStatus(userID) {
  var plan = {
    name: 'Free Plan',
    status: null,
  };

  var ref = await firebase
    .database()
    .ref('users/' + userID + '/plan')
    .once('value');
  if (ref.hasChild('status')) {
    plan.status = ref.val().status;
  }

  if (ref.hasChild('productName')) {
    if (
      ref.val().productName.includes('Pro') ||
      ref.val().productName.includes('pro') ||
      ref.val().productName.includes('Expert')
    ) {
      //check the period
      if (ref.val().productName === 'Expert Lifetime') {
        plan.name = 'Premier';
      } else {
        if (ref.hasChild('stripe/subscriptionID')) {
          const period = await getBillingPeriod(
            ref.val().stripe.subscriptionID,
          );
          plan.name = period;
        } else {
          plan.name = ref.val().productName;
        }
      }
    } else {
      plan.name = ref.val().productName;
    }
  } else {
    plan.name = 'Free Plan';
  }

  return plan;
}

export async function getBillingPeriod(subID) {
  try {
    const { data } = await client.post('/api/stripe/read/billing-period', {
      subscriptionID: subID,
    });
    if (!data.success) {
      throw new Error('failed to fetch billing period');
    }
    if (data.interval === 'month') {
      return 'Pro';
    }
    if (data.interval === 'year') {
      return 'Premier';
    }
  } catch (err) {
    console.error(err);
  }
  return false;
}

export async function getUserProfile(uid) {
  var slugRef = await firebase
    .database()
    .ref('users/' + uid + '/gallery/')
    .child('username')
    .once('value');
  const slug = slugRef?.val();

  const ref = await firebase
    .database()
    .ref('gallery/' + slug.toLocaleLowerCase() + '/' + uid)
    .child('meta')
    .once('value');

  if (ref.exists() && ref.hasChild('displayName')) {
    const integrationsRef = await firebase
      .database()
      .ref('users/' + uid)
      .child('integrations')
      .once('value');

    const hasMedium = integrationsRef.hasChild('medium') ? true : '';
    const hasLinkedin = integrationsRef.hasChild('linkedin') ? true : '';

    const emailRef = await firebase
      .database()
      .ref('users/' + uid + '/meta/')
      .child('email')
      .once('value');
    var email = emailRef?.val();

    if (!email) {
      email = firebase?.auth()?.currentUser?.email;
    }

    const name = ref.val()?.displayName;
    const twitterUrl = ref.val()?.twitterURL;
    const profileImage = ref.val()?.image;

    const plan = await getActiveProductStatus(uid);

    return {
      email: email,
      name: name,
      slug: slug,
      avatar: profileImage,
      twitterHandle: twitterUrl,
      plan: plan.name,
      status: plan.status,
      connectedPlatforms: {
        medium: hasMedium,
        linkedin: hasLinkedin,
        twitter: twitterUrl ? true : false,
      },
    };
  } else {
    const ref2 = await firebase
      .database()
      .ref('gallery/' + slug.toLocaleLowerCase() + '/' + uid)
      .once('value');
    if (ref2.exists()) {
      const emailRef = await firebase
        .database()
        .ref('users/' + uid + '/meta/')
        .child('email')
        .once('value');

      const integrationsRef = await firebase
        .database()
        .ref('users/' + uid)
        .child('integrations')
        .once('value');

      const hasMedium = integrationsRef.hasChild('medium') ? true : '';
      const hasLinkedin = integrationsRef.hasChild('linkedin') ? true : '';

      var email = emailRef?.val();

      if (!email) {
        email = firebase?.auth()?.currentUser?.email;
      }

      const name = ref2.val()?.displayName;
      const twitterUrl = ref2.val()?.twitterURL;
      const profileImage = ref.val()?.image;

      const plan = await getActiveProductStatus(uid);

      return {
        email: email,
        name: name,
        slug: slug,
        avatar: profileImage,
        twitterHandle: twitterUrl,
        plan: plan.name,
        status: plan.status,
        connectedPlatforms: {
          medium: hasMedium,
          linkedin: hasLinkedin,
          twitter: twitterUrl ? true : false,
        },
      };
    } else {
      console.log('no customer meta exists');
      return null;
    }
  }
}
