import { Dialog, Transition } from '@headlessui/react';
import {
  CashIcon,
  ChartSquareBarIcon,
  CheckCircleIcon,
  CollectionIcon,
  DocumentTextIcon,
  GlobeAltIcon,
  LockOpenIcon,
  PlusIcon,
  XIcon,
} from '@heroicons/react/solid';
import { ChartBarIcon, LightningBoltIcon } from '@heroicons/react/solid';
import { Fragment, useEffect, useState } from 'react';
import Button from '../Button';

import 'firebase/auth';
import firebase from 'firebase/app';
import 'firebase/database';

import router, { useRouter } from 'next/router';
import { PricingPlans } from '../../pages/plan';
import TSSimple from '../Icons/ts-simple';
import Checkout from '../Checkout';
import TemplatePacks from '../TemplatesPage/TemplatePacks';
import UpgradePlanModal from '../Plans/UpgradePlanModal';

import toast, { Toaster } from 'react-hot-toast';
import { getActivePlan, getBillingPeriod } from '../../utils/helpers';
import { client } from '../../utils/http';

export default function TemplatePackSelector({
  upgradeModalOpen,
  setUpgradeModalOpen,
  STRIPE_PK,
  isModalOpen,
  setModalOpen,
}) {
  const [checkoutModal, setCheckoutModal] = useState(false);
  const [editions, setEditions] = useState(null);
  const [productName, setProductName] = useState(null);
  const [userID, setUserID] = useState(null);
  const [selectedPack, setSelectedPack] = useState(null);
  const [selectedPackIcon, setSelectedPackIcon] = useState(null);
  const [selectedPackName, setSelectedPackName] = useState(null);
  const [confirming, setConfirming] = useState(false);
  const [existingPacks, setExistingPacks] = useState(null);

  const [periodEnd, setPeriodEnd] = useState(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (userInfo) {
      if (userInfo) {
        setUserID(userInfo.uid);
      } else {
      }
    });
  }, []);

  useEffect(() => {
    userID && getActiveProduct(userID);
  }, [userID]);

  const [unlockedPacks, setUnlockedPacks] = useState(null);

  const [hasSchedule, setHasSchedule] = useState(true);

  useEffect(() => {
    if (!isModalOpen) {
      //if you close modal without selecting a pack, set the pack to the default pack - only for not free plans
      if (
        userID &&
        !hasSchedule &&
        unlockedPacks &&
        productName !== 'Free Plan' &&
        productName !== 'Hobby'
      ) {
        const scheduleRef = firebase
          .database()
          .ref('users')
          .child(userID)
          .child('templatePackSchedule')
          .child('edition-1');
        scheduleRef.update(unlockedPacks);
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    getEditions();
  }, []);

  useEffect(() => {
    userID &&
      !hasSchedule &&
      periodEnd &&
      productName &&
      existingPacks !== null &&
      generatePackSchedule(
        'edition-1',
        periodEnd,
        selectedPack,
        productName,
        existingPacks,
      );
  }, [
    selectedPack,
    userID,
    hasSchedule,
    periodEnd,
    productName,
    existingPacks,
  ]);

  useEffect(() => {
    userID && getSchedule(userID);
  }, [userID]);

  const getSchedule = async (userID) => {
    var ref = firebase
      .database()
      .ref('users/' + userID)
      .child('templatePackSchedule')
      .child('edition-1');
    ref.once('value', async function (snapshot) {
      if (snapshot.val()) {
        setUnlockedPacks(snapshot.val());

        const hasSchedule2 = snapshot.exists();
        //check if each child has a date value asyncronously
        var hasDates = false;
        var existingPacks2 = [];
        //check if each child has a date value asyncronously
        if (hasSchedule2) {
          const datePromise = await Promise.all(
            Object.keys(snapshot.val()).map(async (key) => {
              const date = snapshot.child(key).child('date').exists();
              return date;
            }),
          );

          const alreadyUnlockedPacks = await Promise.all(
            Object.keys(snapshot.val()).map(async (key) => {
              const unlocked = snapshot.child(key).child('unlocked').val();

              if (unlocked) {
                return key;
              } else {
                return null;
              }
            }),
          );

          existingPacks2 = alreadyUnlockedPacks.filter(Boolean);
          hasDates = datePromise.every(Boolean);
        }

        if (hasSchedule && hasDates) {
          setHasSchedule(true);
        } else {
          //there is a schedule but not all dates
          setHasSchedule(false);
          setExistingPacks(existingPacks2);
        }
      } else {
        setHasSchedule(false);
        setExistingPacks([]);
      }
    });
  };

  async function getActiveProduct(userID) {
    console.log('getting active product');
    var ref = firebase.database().ref('users/' + userID + '/plan');
    ref.on(
      'value',
      async function (snapshot) {
        if (snapshot.hasChild('stripe/subscriptionID')) {
          getPeriodEnd(snapshot.val().stripe.subscriptionID);
        } else {
          //set period end to one month from now in unix time
          //these estimates may be off by a day but when using the above (Stripe) periodEnd it should be accurate
          var periodEnd2 = new Date(
            new Date().getTime() + 30 * 24 * 60 * 60 * 1000,
          );
          periodEnd2 = periodEnd2.getTime();
          setPeriodEnd(periodEnd2);
        }

        if (snapshot.hasChild('productName')) {
          console.log('has product name', snapshot.val().productName);
          var c = snapshot.hasChild('confirmed');

          if (
            snapshot.val().productName.includes('Pro') ||
            snapshot.val().productName.includes('pro') ||
            snapshot.val().productName.includes('Expert')
          ) {
            //check the period
            if (snapshot.val().productName === 'Expert Lifetime') {
              setProductName('Premier');
            } else {
              if (snapshot.hasChild('stripe/subscriptionID')) {
                const name = await getBillingPeriod(
                  snapshot.val().stripe.subscriptionID,
                );
                setProductName(name);
              } else {
                setProductName(snapshot.val().productName);
              }
            }
          } else {
            setProductName(snapshot.val().productName);
          }
        } else {
          setProductName('Free Plan');
        }
      },
      function (errorObject) {},
    );
  }

  async function getPeriodEnd(subscriptionID) {
    try {
      const { data } = await client.post('/api/stripe/read/period-end', {
        subscriptionID,
      });
      if (data.success) {
        setPeriodEnd(data.periodEnd);
        return true;
      }
      return false;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async function generatePackSchedule(
    edition,
    periodEnd,
    firstPack,
    productName,
    existingPacks,
  ) {
    //get the packs in edition from firebase
    const editionRef = await firebase
      .database()
      .ref('templates2')
      .child('packs')
      .child('editions')
      .child(edition)
      .child('packs')
      .once('value');
    var packs = editionRef.val();

    //remove any packs from the packs object that exist in the existing packs array
    if (existingPacks) {
      existingPacks.forEach(function (pack) {
        delete packs[pack];
      });
    }

    //create an array of the pack slugs - ordered by their values
    var packSlugs = Object.keys(packs).sort(function (a, b) {
      return packs[a] - packs[b];
    });

    var packSchedule = {};

    //remove firstPack from the packs
    packSlugs.splice(packSlugs.indexOf(firstPack), 1);

    const schedule = getNext12Months(periodEnd);

    packSchedule = {
      [firstPack]: {
        date: new Date().toLocaleDateString('en-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
        unlocked: true,
      },
    };

    var nextMonth = null;
    //async for of loop to get the packs in the edition with index
    for await (const [index, pack] of packSlugs.entries()) {
      var unlockDate = null;

      nextMonth = schedule[index];

      unlockDate = nextMonth.date;

      var format = {
        order: index,
        date: unlockDate,
        unlocked: productName === 'Premier' ? true : false,
      };

      //add the pack to the pack schedule object
      packSchedule[pack] = format;
    }

    if (productName === 'Premier' && packSchedule) {
      const scheduleRef = firebase
        .database()
        .ref('users')
        .child(userID)
        .child('templatePackSchedule')
        .child('edition-1');
      scheduleRef.update(packSchedule);
    }

    setUnlockedPacks(packSchedule);

    //order packSchedule by order
    //packSchedule = Object.values(packSchedule).sort((a, b) => a.order - b.order);
  }

  //a function that takes in a unix timestamp and returns an array of the next 12 months where the day is the anchor day
  function getNext12Months(unix) {
    var anchorDay = new Date(unix).getDate();
    var next12Months = [];
    var currentMonth = new Date(unix).getMonth();
    var currentYear = new Date(unix).getFullYear();

    for (var i = 0; i < 12; i++) {
      var month = currentMonth + i;
      var year = currentYear;

      if (month > 11) {
        month = month - 12;
        year = year + 1;
      }

      var lastDay = new Date(year, month + 1, 0).getDate();
      var firstDay = new Date(year, month, 1).getDate();
      var monthName = new Date(year, month, 1).toLocaleString('default', {
        month: 'long',
      });

      var renewalDay = anchorDay;

      if (anchorDay > lastDay) {
        renewalDay = lastDay;
      }

      var monthObject = {
        month: monthName,
        year: year,
        firstDay: firstDay,
        lastDay: lastDay,
        renewalDay: renewalDay,
        date: new Date(year, month, renewalDay).toLocaleDateString('en-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        }),
      };

      next12Months.push(monthObject);
    }

    return next12Months;
  }

  const getEditions = async () => {
    const baseRef = firebase.database().ref('templates2');
    const packsRef = await baseRef.child('packs').child('packs').once('value');

    const editionsRef = await baseRef
      .child('packs')
      .child('editions')
      .once('value');

    const editionIDs = Object.keys(editionsRef.val());

    //for each edition, get the packIDs listed in edition.packs and get the pack data
    const editions = await Promise.all(
      editionIDs.map(async (editionID) => {
        const packIDs = Object.keys(editionsRef.val()[editionID].packs);

        var packs = await Promise.all(
          packIDs.map(async (packID) => {
            const packRef = await baseRef
              .child('packs')
              .child('packs')
              .child(packID)
              .once('value');
            return packRef.val();
          }),
        );

        //get creator's name and photo from firebase and update templates
        const creators = await Promise.all(
          packs.map(async (pack) => {
            const ref = baseRef.child('creators').child(pack.meta.creator);
            const snapshot = await ref.once('value');
            return snapshot.val();
          }),
        );

        packs.forEach((pack, index) => {
          pack.meta.creator_name = creators[index].name;
          pack.meta.creator_photo = creators[index].photo;
        });

        //order packs by meta.packNumber
        packs.sort((a, b) => {
          return a.meta.packNumber - b.meta.packNumber;
        });

        //return the packs and the edition metadata
        return {
          packs,
          edition: editionsRef.val()[editionID].meta,
        };
      }),
    );

    //order editions by meta.order
    editions.sort((a, b) => {
      return a.edition.order - b.edition.order;
    });

    setEditions(editions);
  };

  useEffect(() => {
    //updateSelectedPack with the first pack in "the first edition"
    if (editions && existingPacks !== null) {
      const edition = editions.find(
        (edition) => edition.edition.slug === 'edition-1',
      );
      var updatedPacks = edition.packs.filter(
        (pack) => !existingPacks.includes(pack.meta.slug),
      );
      updateSelectedPack(
        updatedPacks[0].meta.slug,
        updatedPacks[0].meta.icon,
        updatedPacks[0].meta.title,
      );
    }
  }, [editions, existingPacks]);

  const updateSelectedPack = (slug, icon, title) => {
    setSelectedPack(slug);
    setSelectedPackIcon(icon);
    setSelectedPackName(title);
  };

  const successToast = (text) =>
    toast.success(text, {
      id: 'success',
      /* icon: '🪄', */
      iconTheme: {
        primary: '#fff',
        secondary: '#10B981',
      },
      style: {
        borderRadius: '10px',
        background: '#10B981',
        color: '#fff',
      },
    });

  const problemToast = (text) =>
    toast.error(text, {
      id: 'error',
      /* icon: '🪄', */
      iconTheme: {
        primary: '#fff',
        secondary: '#ef4444',
      },
      style: {
        borderRadius: '10px',
        background: '#ef4444',
        color: '#fff',
      },
    });

  const confirmSelection = () => {
    if (productName === 'Free Plan' || productName === 'Hobby') {
      setUpgradeModalOpen(true);
    } else {
      if (unlockedPacks) {
        setConfirming(true);
        //add schedule to user's firebase database
        const scheduleRef = firebase
          .database()
          .ref('users')
          .child(userID)
          .child('templatePackSchedule')
          .child('edition-1');
        scheduleRef.update(unlockedPacks).then(() => {
          setTimeout(() => {
            //remove ?upgrade= from the url using router
            setConfirming(false);
            setHasSchedule(true);
            successToast('Pack Unlocked!');
          }, 2000);
        });
      } else {
        problemToast('Please select a pack to unlock');
      }
    }
  };

  useEffect(() => {
    console.log('selectedPack', selectedPack);
    console.log('editions', editions);
    console.log('hasSchedule', hasSchedule);
    console.log('productName', productName);
  }, [selectedPack, editions, hasSchedule, productName]);

  return (
    <>
      <div className="flex flex-col p-8 sm:p-12">
        <div className="mb-4 flex flex-col items-start space-y-1">
          <h1 className="text-3xl font-semibold tracking-tight">
            Introducing Template Packs
          </h1>

          <p className="tracking-tightish text-gray-600 ">
            Template packs are collections of templates centered around a
            specific topic. Each pack includes four templates. One of each:
            Standalone Tweet, Twitter Thread, LinkedIn Post, Atomic Essay.
          </p>
        </div>
        {productName !== 'Premier' && (
          <div className="mb-8 w-full rounded-2xl border border-gray-200 border-opacity-50 bg-gray-100 p-6 pt-8">
            <div>
              <div aria-hidden="true">
                <div className="grid h-4 w-full grid-cols-2 items-center justify-center rounded-full bg-gradient-to-r from-blue-500 md:grid-cols-5">
                  <div className="relative z-10 ml-1 h-2 w-2 rounded-full bg-white" />
                  <div className="relative z-10 ml-1 hidden h-2 w-2 rounded-full bg-white md:flex" />
                  <div className="relative z-10 ml-1 h-2 w-2 rounded-full bg-white" />

                  {/*  <div className="h-4 bg-indigo-600 rounded-full absolute" style={{ width: '56.25%' }} /> */}
                </div>
                <div className="font-regular mt-4 grid grid-cols-2 gap-4 text-sm text-gray-600 md:grid-cols-5">
                  <div className="flex flex-col space-y-0 text-gray-900">
                    <p className="text-lg font-semibold tracking-tight">
                      Today
                    </p>
                    <p className="text-sm text-gray-500">Choose starter pack</p>
                  </div>
                  <div className="flex flex-col space-y-0 text-gray-900">
                    <p className="text-lg font-semibold tracking-tight">
                      {
                        // convert periodEnd unix timestamp and to date in the format Month Day
                        periodEnd &&
                          new Date(periodEnd).toLocaleDateString('en-US', {
                            month: 'long',
                            day: 'numeric',
                          })
                      }
                    </p>

                    <p className="text-sm text-gray-500">Next pack unlocks</p>
                  </div>
                  <div className="hidden flex-col space-y-0 text-gray-900 md:flex">
                    <p className="text-lg font-semibold tracking-tight">
                      Each month
                    </p>
                    <p className="text-sm text-gray-500">New pack unlocks</p>
                  </div>
                  <div className="col-span-2 flex items-center justify-center md:justify-end">
                    <Button
                      click={() => setUpgradeModalOpen(true)}
                      size={'small'}
                      variant={'primary'}
                      prefix={<LockOpenIcon className="-mr-1 h-4 w-4" />}
                    >
                      Want all the packs now?
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {editions &&
          existingPacks !== null &&
          editions.map((edition, index) => {
            var packs = edition.packs;
            //remove existing packs from the list of packs
            packs = packs.filter(
              (pack) => !existingPacks.includes(pack.meta.slug),
            );

            return (
              index !== 0 &&
              index !== 1 && (
                <TemplatePacks
                  setSelectorModal={isModalOpen && setModalOpen}
                  showOnFree={false}
                  setUpgradeModalOpen={setUpgradeModalOpen}
                  unlockedPacks={
                    productName === 'Free Plan' || productName === 'Hobby'
                      ? null
                      : unlockedPacks
                  }
                  select={
                    hasSchedule ||
                    productName === 'Premier' ||
                    productName === 'Free Plan' ||
                    productName === 'Hobby'
                      ? false
                      : selectedPack
                  }
                  setSelected={(slug, icon, title) =>
                    updateSelectedPack(slug, icon, title)
                  }
                  modal={true}
                  packs={packs}
                  plan={productName}
                />
              )
            );
          })}

        <div className="mx-auto mt-8 flex flex-row items-center justify-center space-x-3 text-gray-300 duration-1000 hover:text-blue-500">
          <TSSimple className={'h-6 w-6 '} />
        </div>
      </div>

      {selectedPack && editions && !hasSchedule && productName === 'Pro' && (
        <div className="sticky bottom-0 z-20 mt-3 flex w-full items-center justify-between border-t border-gray-200 bg-white p-4 px-8">
          <div className="flex flex-col items-start justify-start space-y-0.5">
            <p className="text-xs font-medium uppercase text-gray-500 sm:text-sm">
              Selected Stater Pack:
            </p>
            <div className="flex flex-row items-center justify-center space-x-2 text-sm font-medium">
              <p className="text-xl">{selectedPackIcon}</p>
              <p>{selectedPackName}</p>
            </div>
          </div>

          <div className="hidden sm:flex">
            <Button
              loading={confirming}
              disabled={confirming}
              click={() => confirmSelection()}
              size={'small'}
              variant={'primary'}
            >
              {'Confirm & Continue'}
            </Button>
          </div>

          <div className="flex sm:hidden">
            <Button
              loading={confirming}
              disabled={confirming}
              click={() => confirmSelection()}
              size={'small'}
              variant={'primary'}
            >
              {'Confirm'}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
